import React, { useEffect, useRef, useState } from "react";
import {
    Col,
    FormFeedback,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Form,
    Input,
    ModalFooter,
    Button,
    Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { EMPLOYEE_UPDATE_EMPLOYEE, UPDATE_EMPLOYEE } from "../../../helpers/url_helper";
import { APIClient } from "../../../helpers/api_helper";
import Toast from "../../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
import { FlatDatepickr } from "../../../Components/Common/Flatpicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";

const api = new APIClient();

const UpdateUser = ({
    employeeId,
    mobileNumber,
    countryCodes,
    username,
    email,
    loginType,
    updatedPermission,
    modal_grid,
    GetCreateData,
    setmodal_grid,
    dateOfJoining,
    designationRole,
}) => {
    const isEmailDisabled = true;
    let selectedDate = moment(dateOfJoining).format("YYYY-MM-DD");
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState("");
    const [mobileNumbers, setMobileNumbers] = useState("");




    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userName: username || "",
            email: email || "",
            countryCode: countryCodes,
            mobileNumber: mobileNumber || "",
            dateOfJoining: selectedDate || "",
            designation: designationRole || "",
        },
        validationSchema: Yup.object({
            userName: Yup.string().required("Please Enter Employee Name"),
            mobileNumber: Yup.string().required("Please Enter Your Mobile Number"),
            dateOfJoining: Yup.string().required(" Please Enter Your Date Of Joining"),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                let data = {
                    _id: employeeId,
                    countryCode: countryCode
                };
                if (values.email !== email) {
                    data.email = values.email;
                }
                if (values.userName !== username) {
                    data.fullName = values.userName;
                }
                if (values.mobileNumber !== mobileNumber) {
                    data.mobileNumber = mobileNumbers;
                }
                if (values.dateOfJoining !== selectedDate) {
                    data.dateOfJoining = values.dateOfJoining;
                }
                if (values.designation !== designationRole) {
                    data.jobTitle = values.designation;
                }
                // if (values.countrycode !== code) {
                //     data.countryCode = values.countrycode;
                // }
                let response;
                if (loginType === "employee" && updatedPermission) {
                    response = await api.patch(`${EMPLOYEE_UPDATE_EMPLOYEE}`, data);
                } else if (loginType === "organization") {
                    response = await api.patch(`${UPDATE_EMPLOYEE}`, data);
                }
                if (response?.success === true) {
                    Toast(response?.message, "success");
                    setTimeout(() => {
                        GetCreateData();
                        // formik.resetForm();
                        setmodal_grid(false);
                    }, 2000);
                }
            } catch (error) {
                HandleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        },
    });


    const toggle = () => {
        if (modal_grid) {
            formik.resetForm();
        }
        setmodal_grid(!modal_grid);
    };


    const handlePhoneChange = (value, data) => {
        let cleanedNumber = value;
        if (!value.startsWith("+")) {
            cleanedNumber = `+${value}`;
        }
        const dialCode = data.dialCode;
        const dialCountryCode = `+${data.dialCode}`;
        const numberWithoutDialCode = cleanedNumber.replace(`+${dialCode}`, "").trim();
        setCountryCode(dialCountryCode);
        setMobileNumbers(numberWithoutDialCode);

        // Update Formik values
        formik.setFieldValue("countryCode", dialCountryCode);
        formik.setFieldValue("mobileNumber", numberWithoutDialCode);

        // Optionally clear errors on valid input
        if (formik.errors.mobileNumber) {
            formik.setFieldError("mobileNumber", "");
        }
    };

  
    


    return (
        <Row>
            <ToastContainer />
            <Modal
                isOpen={modal_grid}
                toggle={() => {
                    toggle();
                }}
            >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={toggle}>
                    Update Employee{" "}
                </ModalHeader>
                <Form onSubmit={formik.handleSubmit} className="needs-validation">
                    <ModalBody>
                        <Row>
                            <Col sm={6}>
                                <Label htmlFor="username" className="form-label">
                                    Name<span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="userName"
                                    type="text"
                                    placeholder="Enter Employee Name"
                                    onChange={(e) => {
                                        const alphabeticValue = e.target.value.replace(/[^a-zA-Z -]/g, "");
                                        formik.setFieldValue("userName", alphabeticValue);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.userName}
                                    invalid={formik.touched.userName && !!formik.errors.userName}
                                />
                                {formik.touched.userName && formik.errors.userName ? (
                                    <FormFeedback type="invalid">
                                        <div>{formik.errors.userName}</div>
                                    </FormFeedback>
                                ) : null}
                            </Col>

                            <Col sm={6}>
                                <Label htmlFor="useremail" className="form-label">
                                    Email
                                </Label>
                                <Input
                                    name="email"
                                    type="email"
                                    placeholder="Enter Email "
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    invalid={formik.touched.email && formik.errors.email ? true : false}
                                    disabled={isEmailDisabled}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <FormFeedback type="invalid">
                                        <div>{formik.errors.email}</div>
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col sm={6}>
                                <div className="sn-country-code-flag-de">
                                    <Label htmlFor="mobileNumber" className="form-label">
                                        Contact Number<span className="text-danger">*</span>
                                    </Label>
                                    <PhoneInput
                                        name="mobileNumber"
                                        country="in"
                                        enableSearch={true}
                                        value={`${formik.values.countryCode}${formik.values.mobileNumber}`} // Use Formik values for countryCode and mobileNumber
                                        onChange={(value, data) => handlePhoneChange(value, data)}
                                        onBlur={formik.handleBlur}
                                        className={
                                            formik.touched.mobileNumber && formik.errors.mobileNumber
                                                ? "is-invalid"
                                                : ""
                                        }
                                    />
                                    {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                                        <div className="invalid-feedback">{formik.errors.mobileNumber}</div>
                                    )}

                                </div>
                            </Col>

                            <Col sm={6}>
                                <Label htmlFor="dateOfJoining" className="form-label">
                                    Joining Date<span className="text-danger">*</span>
                                </Label>
                                <FlatDatepickr
                                    name="dateOfJoining"
                                    value={formik.values.dateOfJoining}
                                    onChange={formik.setFieldValue}
                                    error={formik.errors.dateOfJoining}
                                    touched={formik.touched.dateOfJoining}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col sm={6}>
                                <Label htmlFor="designation" className="form-label">
                                    Designation
                                </Label>
                                <Input
                                    name="designation"
                                    type="text"
                                    placeholder="Enter designation"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.designation}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Remove non-letter and non-space characters
                                    }}
                                    invalid={formik.touched.designation && formik.errors.designation ? true : false}
                                />
                                {formik.touched.designation && formik.errors.designation ? (
                                    <FormFeedback type="invalid">
                                        <div>{formik.errors.designation}</div>
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <Button
                                d="add-btn"
                                disabled={loading ? true : false}
                                className="btn btn-danger w-100"
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner size="sm" className="me-2">
                                        {" "}
                                        Loading...{" "}
                                    </Spinner>
                                ) : null}
                                Update
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </Row>
    );
};

export default UpdateUser;
