import React, { useEffect } from 'react';
import './assets/scss/themes.scss';
import Route from './Routes';
import '../src/App.css'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { logoutUser } from './store/actions';

function App() {
  const dispatch = useDispatch();


  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'token' && event.newValue === null) {
        dispatch(logoutUser());
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
