import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, FormGroup, Label, Row} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {CLIENT_REPORT} from "../../helpers/url_helper";
import {DatePicker, DatePickerWithoutTime, DatePickerWithoutTimes} from "../../Components/Common/Flatpicker";
import SearchField from "../../Components/Common/SearchField";
import {formatDate} from "../../Components/Common/FormDateTime";
import {APIClient, exportReport} from "../../helpers/api_helper";
import {getPreviousDate, setCurrentDate} from "../../Components/Common/DefaultDateSet";
import {HandleErrorMessage} from "../../Components/Common/HandleErrorMessage";
import moment from "moment";
import SubExpired from "../../Components/Common/subExpired";
import {useProfileData} from "../../Components/Hooks/UserHooks";
import {useNavigate} from "react-router-dom";
import CommanDataTable from "../../Components/Common/DataTable";
import {formatToSystemTimezone} from "../../Components/Common/ToCamelCase";
import {addMonths} from "../../Components/Common/FlatePicker";
import {handleExport} from "../../Components/Common/validationSchema";

const api = new APIClient();

const ClientReport = () => {
    document.title = "Client Report";
    const navigate = useNavigate();
    const previewsDate = getPreviousDate();
    const currentDate = setCurrentDate();
    const {profileData} = useProfileData();
    const [isLoading, setIsLoading] = useState(true);
    const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);
    // date picker state
    const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
    const [selectedToDate, setSelectedToDate] = useState(currentDate);

    // search field state
    const [searchValue, setSearchValue] = useState("");

    // Pagination state
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [clientReport, setClientReport] = useState([]);
    //search field state
    const [query, setQuery] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState("");

    const [remainingDays, setRemainingDays] = useState(null);

    // // flatPicker Date
    const formattedStartDate = formatDate(selectedFromDate);
    const formattedEndDate = formatDate(selectedToDate);

       const isWithinRange = (date) => {
         const today = new Date();
         return date >= selectedFromDate && date <= today;
       };
      
       const handleFromDateChange = (dates) => {
         const newFromDate = dates[0];
         setSelectedFromDate(newFromDate);
         const today = new Date();
         const threeMonthsAgo = addMonths(today, -3);
         if (newFromDate >= threeMonthsAgo && newFromDate <= today) {
           setSelectedToDate(today);
         } else {
           const newToDate = addMonths(newFromDate, 3);
           setSelectedToDate(newToDate);
         }
       };
      
       const handleToDateChange = (dates) => {
         const newToDate = dates[0];
         if (isWithinRange(newToDate)) {
           setSelectedToDate(newToDate);
         } else {
           console.warn("Selected date is out of range.");
         }
       };
 

    // search field function
    const handleSearchField = (e) => {
        setSearchValue(e.target.value);
    };

    const column = [
        {
            name: <span className="font-weight-bold fs-13"> SN.</span>,
            width: "5%",
            selector: (row, index) => index + 1,
            sortable: true,
        },

        {
            name: (
                <span className="font-weight-bold fs-13 text-center" style={{textTransform: "capitalize"}}>
                    Employee
                </span>
            ),
            selector: (row) => row?.fullName,
            cell: (row) => (
                <div className="mt-2 mb-2">
                    <h5 className="font-weight-bold fs-13" style={{textTransform: "capitalize"}}>
                        {row?.fullName}
                    </h5>
                    <h6 className="text-center- font-weight-bold fs-13">
                        {row?.countryCode + `-` + row?.mobileNumber}
                    </h6>
                </div>
            ),
            sortable: true,
        },
        {
            name: <span>Client Name</span>,
            selector: (row) => row?.name,
            cell: (row) => (
                <div className="mt-2 mb-2">
                    <h5 className="font-weight-bold fs-13" style={{textTransform: "capitalize"}}>
                        {row?.name}
                    </h5>
                    <h6 className="text-center- font-weight-bold fs-13">{row?.phoneNumber}</h6>
                </div>
            ),
            sortable: true,
        },
        {
            name: <span className="font-weight-bold fs-13">Date & Time</span>,
            selector: (row) => new Date(`${row?.date} ${row?.time}`).getTime(), // Timestamp for sorting
            cell: (row) => (
                <div className="mt-2 mb-2">
                    <h5 className="font-weight-bold fs-13" style={{textTransform: "capitalize"}}>
                        {row?.date}
                    </h5>
                    <h6 className="text-center font-weight-bold fs-13">{row?.time}</h6>
                </div>
            ),
            sortable: true,
        },
        {
            name: <span className="font-weight-bold fs-13">Call Recording</span>,
            width: "37%",
            selector: (row) => (
                <>
                    {row?.recording ? (
                        <div
                            style={{
                                display: "inline-grid",
                                marginBottom: "20px",
                                marginTop: "10px",
                            }}
                        >
                            <span style={{height: "10px"}}>{row?.duration}</span>
                            <audio style={{height: "40px", marginTop: "15px"}} src={row?.recording} controls />
                        </div>
                    ) : row?.duration && row.duration !== "00:00:00" ? (
                        <div
                            style={{
                                display: "inline-grid",
                                marginBottom: "20px",
                                marginTop: "10px",
                            }}
                        >
                            {["OUTGOING", "INCOMING", "REJECTED", "MISSED"].includes(row.type) ? (
                                <>
                                    <span style={{height: "10px"}}>{row?.duration}</span>
                                    <span style={{marginTop: "10px", color: "warning"}}>
                                        Recording sync in progress...
                                    </span>
                                </>
                            ) : (
                                "Call Not Connected"
                            )}
                        </div>
                    ) : (
                        "Call Not Connected"
                    )}
                </>
            ),
        },

        {
            name: <span className="font-weight-bold fs-13">Call Type</span>,
            selector: (cell) => {
                switch (cell.type) {
                    case "OUTGOING":
                        return (
                            <>
                                <i className="mdi mdi-phone-outgoing sn-out-going-text" />{" "}
                                <span className="badge badge-soft-info sn-out-going-call"> {cell.type}</span>
                            </>
                        );
                    case "REJECTED":
                        return (
                            <>
                                <i className="mdi mdi-phone-cancel sn-reject-call-icontext" />
                                <span
                                    className="badge badge-soft-warning"
                                    style={{color: "#eca8f7", background: "rgb(238 172 247 / 22%)"}}
                                >
                                    {" "}
                                    {cell.type}{" "}
                                </span>
                            </>
                        );
                    case "MISSED":
                        return (
                            <>
                                <i className="mdi mdi-phone-missed sn-out-missed-text" />{" "}
                                <span className="ml-4 badge badge-soft-danger sn-out-missed-call"> {cell.type} </span>
                            </>
                        );
                    case "INCOMING":
                        return (
                            <>
                                <i className="mdi mdi-phone-incoming sn-out-incoming-text" />
                                <span className="ml-4 badge badge-soft-success sn-out-incoming-call">
                                    {" "}
                                    {cell.type}{" "}
                                </span>
                            </>
                        );
                    default:
                        return <span className="badge badge-soft-success"> {cell.type} </span>;
                }
            },
        },

        {
            name: <span className="font-weight-bold fs-13">Notes</span>,
            selector: (row) => row.notes,
            // sortable: true
        },
    ];

    useEffect(() => {
        const calculateRemainingDays = () => {
            if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
                const endDate = new Date(profileData?.subscriptionEndDate);
                const startDate = new Date(profileData?.subscriptionStartDate);
                const currentDate = new Date();

                const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
                const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
                setRemainingDays(remainingDays > 0 ? remainingDays : 0);
                const interval = setInterval(() => {
                    const newCurrentDate = new Date();
                    const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
                    setRemainingDays(newRemainingDays);
                }, 1000 * 60 * 60 * 24);

                return () => clearInterval(interval);
            } else {
                setRemainingDays(null);
            }
        };

        calculateRemainingDays();
    }, [profileData]);

    // Debounce search query
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
            setCurrentPage(1);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    // Fetch data when debounced query, currentPage, or perPage changes
    useEffect(() => {
        const fetchData = async () => {
            if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
                await clientgetReport(currentPage, perPage, debouncedQuery);
            }
        };

        fetchData();
    }, [debouncedQuery, currentPage, perPage]);

    // Search field handler
    const handleSearch = (e) => {
        setQuery(e.target.value);
    };

    // table data
    const handleSubmit = () => {
        clientgetReport(currentPage, 10); //perpage
        setCurrentPage(1);
        setPerPage(10);
    };

    // Pagination handler
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const clientgetReport = async (page, size = perPage, searchTerm = "") => {
        setIsLoading(true);
        const extractedNumber = searchValue.replace(/^\+91-/, "");
        try {
            let data = {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                clientPhoneNumberOrName: `${extractedNumber}`,
            };

            const response = await api.create(`${CLIENT_REPORT}?pg=${page}&lm=${size}&query=${searchTerm}`, data);
            if (response?.success) {
                setIsLoading(false);
                const apiResponse = response.data;
                const clientReportType = apiResponse?.paginatedResults.map((report) => ({
                    date: report.dateTime ? moment(report.dateTime).format("DD MMM YYYY") : "-",
                    time: report.dateTime ? moment(report.dateTime).format("hh:mm A") : "-",
                    name: report.name || "Unknown",
                    phoneNumber: report.phoneNumber || "-",
                    type: report.type || "Unknown",
                    notes: report.note || "-",
                    fullName: report.employeeDescription?.[0]?.fullName || "Unknown",
                    mobileNumber: report.employeeDescription?.[0]?.mobileNumber || "-",
                    duration: new Date(report.duration * 1000).toISOString().slice(11, 19),
                    recording: report.callRecording || null,
                    countryCode: report?.employeeDescription[0].countryCode,
                }));

                setClientReport(clientReportType);
                setTotalRows(apiResponse.totalCount);
            }
        } catch (error) {
            HandleErrorMessage(error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleClose = () => {
        setIsExpiredModalOpen(false);
        navigate("/planPackage");
    };

    //export in excel
    const handleExcelExport = async () => {
        const extractedNumber = searchValue.replace(/^\+91-/, "");
        await handleExport({
            type: "excel",
            apiUrl: `${CLIENT_REPORT}`,
            startDate: selectedFromDate,
            endDate: selectedToDate,
            clientPhoneNumberOrName: `${extractedNumber}`,
            fileName: "ClientReport.xlsx",
        });
    };

    //export in pdf
    const handlePdfExport = async () => {
        const extractedNumber = searchValue.replace(/^\+91-/, "");
        await handleExport({
            type: "pdf",
            apiUrl: `${CLIENT_REPORT}`,
            startDate: selectedFromDate,
            endDate: selectedToDate,
            clientPhoneNumberOrName: `${extractedNumber}`,
            fileName: "ClientReport.pdf",
        });
    };

    return (
        <React.Fragment>
            {remainingDays === 0 && isExpiredModalOpen && (
                <SubExpired
                    onClose={handleClose}
                    profileData={profileData?.selectedPlanDetails?.planName}
                    remainingDays={remainingDays}
                />
            )}
            <div className="page-content sn-client-report-main-page">
                <Container fluid>
                    <BreadCrumb title="Client Report" pageTitle="Client Report" />
                    <Row>
                        <Col lg={12}>
                            <Card className="h-100">
                                {/* <CardHeader>
                                    <h4 className="card-title mb-0">Client Report</h4>
                                </CardHeader> */}
                                <CardBody>
                                    <Row className="sn-header-select-employess-home sn-top-inputs-heading-">
                                        <div className="col-md-3 col-sm-12">
                                            <DatePicker
                                             labelContent="From Date"
                                             getDate={selectedFromDate}
                                             options={{
                                               disable: [
                                                 {
                                                   from: new Date(),
                                                 },
                                               ],
                                               dateFormat: "d M, Y",
                                               maxDate: new Date(
                                                 new Date().setDate(new Date().getDate())
                                               ),
                                             }}
                                             onChange={handleFromDateChange}
                                           />
                                        </div>

                                        <div className="col-md-4 col-sm-12 sn-header-select-employess-home">
                                            <DatePicker
                                              labelContent="To Date"
                                              getDate={selectedToDate}
                                              options={{
                                                dateFormat: "d M, Y",
                                                minDate: selectedFromDate,
                                                // maxDate: 'today',
                                                enable: [
                                                  {
                                                    from: selectedFromDate,
                                                    to: Math.min(
                                                      addMonths(selectedFromDate, 3),
                                                      new Date()
                                                    ),
                                                  },
                                                ],
                                                onOpen: () => {
                                                  const currentDate = new Date();
                                                  const threeMonthsFromStart = addMonths(
                                                    selectedFromDate,
                                                    3
                                                  );
                                                  if (threeMonthsFromStart > currentDate) {
                                                    return {
                                                      enable: [
                                                        {
                                                          from: selectedFromDate,
                                                          to: currentDate,
                                                        },
                                                      ],
                                                    };
                                                  }
                                                },
                                              }}
                                              onChange={handleToDateChange}
                                            />
                                        </div>

                                        <div className="col-md-3 col-sm-12 sn-header-select-employess-home">
                                            <SearchField
                                                onChange={handleSearchField}
                                                labelContent="Search by Client details"
                                            />
                                        </div>
                                        <div className="col-md-2 col-sm-12 mt-2">
                                            <FormGroup>
                                                <Label for="exampleSelect"></Label>
                                                <div id="exampleSelect" className="sn-call-apply-btn-reset">
                                                    <Button
                                                        type="submit"
                                                        color="danger"
                                                        className=""
                                                        onClick={handleSubmit}
                                                        // disabled={isLoading}
                                                    >
                                                        Get Report
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Row>

                                    <Row className="sn-user-tble-manin-manage">
                                        <Col lg={12}>
                                            <div className="text-end">
                                                {clientReport?.length > 0 ? (
                                                    <div
                                                        className="sales-ninja-tble-export-btn call-logs-report-export-exe"
                                                        style={{position: "relative"}}
                                                    >
                                                        {" "}
                                                        <Button
                                                            onClick={handleExcelExport}
                                                            className="custom-button tble-export1 sn-report-export-btn-new"
                                                        >
                                                            <i className="ri-file-excel-line"></i>
                                                        </Button>{" "}
                                                        &nbsp;
                                                        <Button
                                                            onClick={handlePdfExport}
                                                            className="custom-button ml-2 tble-export1 tble-export2 sn-report-export-btn-new button-file-export-pdf"
                                                        >
                                                            <i className="las la-file-pdf"></i>
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="sn-client-report-table-main">
                                                <CommanDataTable
                                                    className="mt-0 pt-0"
                                                    column={column}
                                                    setCurrentPage={setCurrentPage}
                                                    perPage={perPage}
                                                    handleSearch={handleSearch}
                                                    tableData={clientReport}
                                                    handlePerRowsChange={handlePerRowsChange}
                                                    totalRows={totalRows}
                                                    currentPage={currentPage}
                                                    isLoading={isLoading}
                                                    handlePageChange={handlePageChange}
                                                    noData="There are no records to display"
                                                    inputClass={
                                                        (clientReport && clientReport.length > 0) || query
                                                            ? "d-block"
                                                            : "d-none"
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClientReport;
