import React, { useEffect, useState } from "react";
import { Row, Card } from "react-bootstrap";
import StorageManagement from "./StorageManagement";
import StorageManagmentChart from "./StorageManagmentChart";
import StorageManagementMonthly from "./StorageManagementMonthly";
import {
  GET_STORAGE_API,
  GET_STORAGE_MINUTE_API,
} from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import {
  CurrentDate,
  getPreviousDate,
  PreviousDate,
  setCurrentDate,
} from "../../Components/Common/DefaultDateSet";
import { addMonths } from "../../Components/Common/FlatePicker";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import { Button, CardBody, FormGroup, Label } from "reactstrap";
import { formatDate } from "../../Components/Common/FormDateTime";
import { DatePicker } from "../../Components/Common/Flatpicker";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../../Components/Common/Loader";
const api = new APIClient();
 
const Storage = () => {
  const pageTitle = "Storage Management";
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const viewReportPermission = permissionType?.includes("VIEW_REPORT");
  const loginType = JSON.parse(localStorage.getItem("logintype"));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [teamIndu, setTeamIndu] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  const [EmployeeData, setEmployeeData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const previewsDate = getPreviousDate();
  const currentDate = setCurrentDate();
  const [flag, setFlag] = useState("");
  const [EmployeeDefaultWithoutTeam, setEmployeeDefaultWithoutTeam] = useState(
    []
  );
  const [totalRows, setTotalRows] = useState(0);
  const [selectedFromDate, setSelectedFromDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  
  const [selectedToDate, setSelectedToDate] = useState(currentDate);
  const formattedStartDate = formatDate(selectedFromDate);
  const formattedEndDate = formatDate(selectedToDate);
  const [filterData, setFilterData] = useState([]);
  const [error, setError] = useState("");

  const clearData = () => {
    setStorageData([]);
    setFilterData([]);
    setTotalRows(0);
  };

 useEffect(()=>{
  setSelectedEmployeeOptions(EmployeeDefaultWithoutTeam)
 },[EmployeeDefaultWithoutTeam])
 
  const fetchStorageData = async (updatedIds = [], defaultId = []) => {
    setIsLoading(true);
    try {
      const employeeIds = defaultId.length > 0 ? defaultId : updatedIds;
 
      if (employeeIds.length === 0) {
        clearData();
        setIsLoading(false);
        return;
      }
 
      const data = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        employeeIds: employeeIds,
      };
 
      const response = await api.create(
        `${GET_STORAGE_MINUTE_API}?pg=${currentPage}&lm=${perPage}`,
        data
      );
 
      if (response?.success) {
        setStorageData(response?.data);
      } else {
        toast.error("Failed to fetch storage data!");
        clearData();
      }
    } catch (error) {
      // console.error("Error fetching storage data:", error);
      clearData();
    } finally {
      setIsLoading(false);
    }
  };
 
  const fetchNewStorageData = async (updatedIds = [], defaultId = []) => {
    setIsLoading(true);
    try {
      const employeeIds = defaultId.length > 0 ? defaultId : updatedIds;
 
      if (employeeIds.length === 0) {
        clearData();
        setIsLoading(false);
        return;
      }
 
      const data = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        employeeIds: employeeIds,
      };
 
      const response = await api.create(
        `${GET_STORAGE_API}?pg=${currentPage}&lm=${perPage}`,
        data
      );
        
      if (response?.success && response?.data) {
        const { monthlyData, totalRecords } = response.data;
 
        const formattedData = monthlyData.map((item) => {
          const employee = item.employeeDetails[0] || {};
          return {
            id: item?.employeeId,
            employeeName: employee.fullName || "Unknown",
            profileImageUrl: null,
            teamName: employee.team?.join(", ") || "-",
            usageSpace: item.employeeStorage || 0,
            lastRecordSync: employee.lastCallRecordingSync
              ? new Date(employee.lastCallRecordingSync).toLocaleString()
              : "-",
          };
        });
 
        setFilterData(formattedData);
        setTotalRows(totalRecords);
      } else {
        clearData();
      }
    } catch (error) {
      console.error("Error fetching storage data:", error);
      clearData();
    } finally {
      setIsLoading(false);
    }
  };
 
  useEffect(() => {
    const defaultId =
      EmployeeDefaultWithoutTeam?.map((option) => option?.id) || [];
    if (EmployeeData?.length > 0) {
      fetchStorageData([], defaultId);
      fetchNewStorageData([], defaultId);
    } else {
      clearData();
    }
  }, [EmployeeData]);
 
  // Watch for changes in selectedEmployeeOptions
  // useEffect(() => {
  //   if (selectedEmployeeOptions.length === 0) {
  //     clearData();
  //   }
  // }, []);
 
  const handleFromDateChange = (dates) => {
    const newFromDate = dates[0];
    setSelectedFromDate(newFromDate);
    const today = new Date();
    const threeMonthsAgo = addMonths(today, -3);
    if (newFromDate >= threeMonthsAgo && newFromDate <= today) {
      setSelectedToDate(today);
    } else {
      const newToDate = addMonths(newFromDate, 3);
      setSelectedToDate(newToDate);
    }
  };
 
  const handleToDateChange = (dates) => {
    setSelectedToDate(dates[0]);
  };
 
  const handleSubmit = () => {
    const updatedIds = selectedEmployeeOptions?.map((userid) => userid?.id);
    if (selectedEmployeeOptions?.length === 0) {
      setError("You must select at least one");
    }
    else
    {
    if (updatedIds.length === 0) {
      clearData();
    } else {
      fetchStorageData(updatedIds, "");
      fetchNewStorageData(updatedIds, "");
    }
    setFlag(true);
    setCurrentPage(1);
    setPerPage(10);
    setError("");
  }
  };
 
  const resetInputHistory = () => {
    setSelectedToDate("");
    setSelectedFromDate("");
    setSelectedEmployeeOptions([])

    clearData();
  };
 
  return (
    <div className="page-content sn-employee-report-page-main">
      <div className="container-fluid">
        <BreadCrumb title={pageTitle} />
        <Row className="sn-header-select-employess-home sn-team-managment-tble sn-top-inputs-heading">
          <div className="row">
            {((viewReportPermission && loginType === "employee") ||
              loginType === "organization") && (
              <div className="col-md-3 col-sm-12 sn-select-employees-box">
                <EmployeeSelect
                  setTeamIndu={setTeamIndu}
                  teamIndu={teamIndu}
                  setEmployeeDefaultWithoutTeam={setEmployeeDefaultWithoutTeam}
                  EmployeeDefaultWithoutTeam={EmployeeDefaultWithoutTeam}
                  setEmployeeData={setEmployeeData}
                  EmployeeDefault={
                    teamIndu.length === 0
                      ? EmployeeDefaultWithoutTeam
                      : EmployeeData
                  }
                  setEmployeeDefault={setEmployeeData}
                  selectedEmployeeOptions={selectedEmployeeOptions}
                  setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                  labelContent="Select Employee"
                  error={error}
                  setError={setError}
                />
              </div>
            )}
 
            <div className="col-md-3 col-sm-12">
              <DatePicker
                labelContent="From Date"
                getDate={selectedFromDate}
                options={{
                  disable: [
                    {
                      from: selectedFromDate,
                    },
                  ],
                  dateFormat: "d M, Y",
                  maxDate: new Date(),
                }}
                onChange={handleFromDateChange}
              />
            </div>
 
            <div className="col-md-3 col-sm-12">
              <DatePicker
                labelContent="To Date"
                getDate={selectedToDate}
                options={{
                  enable: selectedFromDate
                    ? [
                        {
                          from: selectedFromDate,
                          to: addMonths(selectedFromDate, 3),
                        },
                      ]
                    : [
                        {
                          from: new Date(),
                        },
                      ],
                  dateFormat: "d M, Y",
                  maxDate:
                    selectedFromDate === new Date()
                      ? new Date()
                      : selectedToDate,
                }}
                onChange={handleToDateChange}
              />
            </div>
 
            <div className="col-md-3 col-sm-12">
              <FormGroup className="mt-2 mb-4">
                <Label for="exampleSelect"></Label>
                <div id="exampleSelect" className="sn-call-apply-btn-reset">
                  <Button
                    id="exampleSelect"
                    type="submit"
                    color="danger"
                    className=""
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    Apply
                  </Button>
                  <Button
                    className=""
                    style={{ marginLeft: "10px" }}
                    id="exampleSelect"
                    outline
                    color="danger"
                    onClick={resetInputHistory}
                    disabled={isLoading}
                  >
                    Reset
                  </Button>
                </div>
              </FormGroup>
            </div>
          </div>
        </Row>
 
        <Row>
          <div className="col-md-4 col-sm-12 mb-4">
            <Card className="sn-storage-managment-card-chart shadow-lg p-3 rounded">
              <StorageManagmentChart data={storageData} isLoading={isLoading} />
            </Card>
          </div>
          <div className="col-md-8 col-sm-12 mb-4">
            <Card className="sn-storage-managment-card-chart shadow-lg p-3 rounded">
              <StorageManagementMonthly
                data={storageData}
                isLoading={isLoading}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                currentPage={currentPage}
                perPage={perPage}
                selectedEmployeeOptions={selectedEmployeeOptions}
              />
            </Card>
          </div>
        </Row>
        { isLoading?
          (<div className="loader-overlay">
            <Loader />
          </div>): ""
        }
        <StorageManagement
          filterData={filterData}
          isLoading={isLoading}
          setCurrentPage={setCurrentPage}
          setPerPage={setPerPage}
          currentPage={currentPage}
          perPage={perPage}
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
        />
      </div>
    </div>
  );
};
 
export default Storage;
 