import React from "react";
import Chart from "react-apexcharts";
 
const StorageManagmentChart = ({ data, isLoading }) => {
 
  const convertToGB = (value) => {
    if (!value) return 0;
 
    const regex = /(\d+(\.\d+)?)\s*(B|KB|MB|GB)/i;
    const match = value.match(regex);
    if (!match) return 0;
 
    const numValue = parseFloat(match[1]);
    const unit = match[3].toUpperCase();
 
    switch (unit) {
      case "B":
        return numValue / (1024 * 1024 * 1024);
      case "KB":
        return numValue / (1024 * 1024);
      case "MB":
        return numValue / 1024;
      case "GB":
        return numValue;
      default:
        return 0;
    }
  };
 
  const { totalStorage, usedStorage, remainStorage } = data || {};
  const total = convertToGB(totalStorage);
  const used = convertToGB(usedStorage);
  const remaining = convertToGB(remainStorage);
 
  const chartData = {
    series: [used, remaining],
    labels: ["Used Storage", "Remaining Storage"],
  };
 
  const chartOptions = {
    chart: {
      type: "donut",
    },
    labels: chartData.labels,
    colors: ["#f17171", "#66CDAA"],
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(2)} %`,
    },
    tooltip: {
      theme: "light",
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const label = chartData.labels[seriesIndex];
        const storageValue = series[seriesIndex].toFixed(2);
        const freeStorage =
          seriesIndex === 0
            ? remaining.toFixed(2)
            : used.toFixed(2);
        return `
          <div style="color: white;" class="apexcharts-tooltip-title mx-auto">${label}</div>
          <div style="color: white;" class="apexcharts-tooltip-text mx-auto">
            ${storageValue} GB<br />
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: true,
              formatter: function (w) {
                return `${w.globals.seriesTotals
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)} GB`;
              },
            },
          },
        },
      },
    },
    states: {
      hover: {
        filter: "none",
        overlay: {
          opacity: 0.2,
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
 
  return (
    <div>
      <h4 className="card-title ">Storage Usage Chart</h4>
     <div style={{textAlign:'center', margin:'auto', }}>
     <Chart
        options={chartOptions}
        series={chartData.series}
        type="donut"
        width="400"
      />
     </div>
      <div className="mt-3  text-center">
        <p className="text-dark fw-semibold">
          Total Storage: {total.toFixed(2)} GB
        </p>
        <p className="text-dark">Used Storage: {used.toFixed(2)} GB</p>
        <p className="text-dark">
          Remaining Storage: {remaining.toFixed(2)} GB
        </p>
      </div>
    </div>
  );
};
 
export default StorageManagmentChart;
 