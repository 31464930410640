import React, { useState } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row, Form, Input, ModalFooter, FormFeedback, Button, Spinner } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import { CREATE_EMPLOYEE, EMPLOYEE_CREATE_EMPLOYEE } from "../../../helpers/url_helper";
import { APIClient } from "../../../helpers/api_helper";
import Toast from "../../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
import { FlatDatepickr } from "../../../Components/Common/Flatpicker";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
const api = new APIClient();


const CreateUser = ({ createmodal_grid, GetCreateData, setCreatemodal_grid, createPermission, loginType }) => {
    const [loading, setLoading] = useState(false);
    const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [countryCode, setCountryCode] = useState("+91");
    const [mobileNumbers, setMobileNumbers] = useState("");

    const formiks = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: "",
            email: "",
            address: "",
            jobTitle: "",
            role: "",
            countryCode: "",
            mobileNumber: "",
            employeeId: "",
            department: "",
            dateOfJoining: "",
            designation: ""
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .matches(/^[A-Za-z\s]*$/, 'Only letters and spaces are allowed')
                .required('Please enter your Name')
                .max(16, 'Name must not be more than 16 characters'),
            email: Yup.string().email("Please enter a valid email address").matches(emailRules, { message: "  Invalid email" }).required("Please enter your email"),
            mobileNumber: Yup.string()
                .required("Mobile number is required")
                .test(
                    "is-valid-mobile",
                    "Enter a Mobile number",
                    (value) => value && value.length > 3
                ),
            dateOfJoining: Yup.string().required(" Please enter your Registered date"),
        }),
        onSubmit: async (value) => {
            try {
                setLoading(true);
                let data = {
                    fullName: value.username,
                    employeeId: value.employeeId,
                    jobTitle: value.designation,
                    Department: value.department,
                    email: value.email,
                    mobileNumber: mobileNumbers,
                    address: value.address,
                    countryCode: countryCode,
                    team: value.assignTeam,
                    status: "active",
                    dateOfJoining: moment(value.dateOfJoining).format("YYYY-MM-DD"),
                };

                let response;
                if (loginType === "employee" && createPermission) {
                    response = await api.create(`${EMPLOYEE_CREATE_EMPLOYEE}`, data);
                } else if (loginType === "organization") {
                    response = await api.create(`${CREATE_EMPLOYEE}`, data);
                }
                if (response.success === true) {
                    Toast(response?.message, "success");
                    setTimeout(() => {
                        GetCreateData()
                        setCreatemodal_grid(false)
                        formiks.resetForm();
                    }, 2000)
                }
            } catch (error) {
                HandleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        }
    });


    const createtog_grid = () => {
        setCreatemodal_grid(!createmodal_grid);
    }

    const handlePhoneChange = (value, data) => {
        let cleanedNumber = value;
        if (!value.startsWith("+")) {
            cleanedNumber = `+${value}`;
        }
        const dialCode = data.dialCode;
        const dialCountryCode = `+${data.dialCode}`;
        const numberWithoutDialCode = cleanedNumber.replace(`+${dialCode}`, "").trim();
        setCountryCode(dialCountryCode);
        setMobileNumbers(numberWithoutDialCode);
        formiks.setFieldValue("mobileNumber", value);
        if (formiks.errors.mobileNumber) {
            formiks.setFieldError("mobileNumber", "");
        }
    };


    return (
        <Row>
            <ToastContainer />
            <Modal
                isOpen={createmodal_grid}
                toggle={() => {
                    createtog_grid();
                }}>
                <ModalHeader className="p-3 bg-soft-success" id="exampleModalLabel" toggle={() => { createtog_grid(); }}> Add Employee </ModalHeader>
                <Form
                    onSubmit={formiks.handleSubmit}
                    className="needs-validation">
                    <ModalBody>
                        <div className="mb-0">
                            <Row>
                                <Col sm={6}>
                                    <Label htmlFor="username" className="form-label">Name<span className="text-danger">*</span></Label>
                                    <Input
                                        name="username"
                                        type="text"
                                        placeholder="Enter Name"
                                        onChange={formiks.handleChange}
                                        onBlur={formiks.handleBlur}
                                        value={formiks.values.username}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove non-letter and non-space characters
                                        }}
                                        invalid={
                                            formiks.touched.username && formiks.errors.username ? true : false
                                        }
                                    />
                                    {formiks.touched.username && formiks.errors.username ? (
                                        <FormFeedback type="invalid"><div>{formiks.errors.username}</div></FormFeedback>
                                    ) : null}
                                </Col>
                                <Col sm={6}>
                                    <Label htmlFor="useremail" className="form-label">Email <span className="text-danger">*</span></Label>
                                    <Input
                                        name="email"
                                        type="email"
                                        placeholder="Enter Email "
                                        onChange={formiks.handleChange}
                                        onBlur={formiks.handleBlur}
                                        value={formiks.values.email}
                                        invalid={
                                            formiks.touched.email && formiks.errors.email ? true : false
                                        }
                                    />
                                    {formiks.touched.email && formiks.errors.email ? (
                                        <FormFeedback type="invalid"><div>{formiks.errors.email}</div></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>

                        <div className="mt-2">
                            <Row>
                                <Col sm={6} className="">
                                    <Label htmlFor="mobileNumber" className="form-label">
                                        Contact Number<span className="text-danger">*</span>
                                    </Label>
                                    <div className="sn-country-code-flag-de">
                                        <PhoneInput
                                            name="mobileNumber"
                                            country="in"
                                            enableSearch={true}
                                            value={formiks.values.mobileNumber}
                                            onChange={handlePhoneChange}  // Custom onChange handler to handle validation
                                            onBlur={formiks.handleBlur}
                                            className={formiks.touched.mobileNumber && formiks.errors.mobileNumber ? "is-invalid" : ""}
                                        />

                                        {/* Error message display */}
                                        {formiks.touched.mobileNumber && formiks.errors.mobileNumber && (
                                            <div className="invalid-feedback">
                                                {formiks.errors.mobileNumber}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <Label htmlFor="dateOfJoining" className="form-label">Joining Date<span className="text-danger">*</span></Label>
                                    <FlatDatepickr
                                        name="dateOfJoining"
                                        value={formiks.values.dateOfJoining}
                                        onChange={formiks.setFieldValue}
                                        error={formiks.errors.dateOfJoining}
                                        touched={formiks.touched.dateOfJoining}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="mt-2">
                            <Row>
                                <Col sm={6}>
                                    <Label htmlFor="designation" className="form-label">Designation</Label>
                                    <Input
                                        name="designation"
                                        type="text"
                                        placeholder="Enter designation"
                                        onChange={formiks.handleChange}
                                        onBlur={formiks.handleBlur}
                                        value={formiks.values.designation}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove non-letter and non-space characters
                                        }}
                                        invalid={
                                            formiks.touched.designation && formiks.errors.designation ? true : false
                                        }
                                    />
                                    {formiks.touched.designation && formiks.errors.designation ? (
                                        <FormFeedback type="invalid"><div>{formiks.errors.designation}</div></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <Button d="add-btn" color="primary" disabled={loading ? true : false} className="btn btn-success w-100" type="submit">
                                {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                Create
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </Row>
    )
}

export default CreateUser