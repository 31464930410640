import React, { useContext, useEffect, useState } from "react";
import { Table, Container, Row, Col, ToastContainer } from "react-bootstrap";

import { Card } from "reactstrap";

// Components
import BreadCrumb from "../../Components/Common/BreadCrumb";

// Image
import Profile from "../../images/agent/profile.png";

// Pagination
import Pagination from "https://cdn.skypack.dev/rc-pagination@3.1.15";

// Redux
import { useSelector } from "react-redux";

const ViewLeaderboard = () => {
  const [topProducts, setTopProducts] = useState([]);
  const [remainingProducts, setRemainingProducts] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  const Tablethead = [
    { title: "Rank" },
    { title: "User" },
    { title: "Points" },
    // { title: "Level" },
  ];

  const selectdata = [
    {
      "name": "Anjali",
      "score": 1601
    },
    {
      "name": "Ajay kesharwani",
      "score": 1020
    },
    {
      "name": "Abhishek khedekar",
      "score": 1013
    },
    {
      "name": "Aamir Ansari",
      "score": 836.5
    },
    {
      "name": "Apoorv dubey",
      "score": 798
    },
    {
      "name": "Aadarsh Soni",
      "score": 554
    },
    {
      "name": "Anmol Tripathi ",
      "score": 491
    },
    {
      "name": "Arun yadav",
      "score": 119
    }
  ]
  const selectorssss = useSelector((state) => {
   
    return Array.isArray(state.teamReducer?.PDATA) ? state.teamReducer.PDATA : [];
  });


  useEffect(() => {
    // Sort the products by rank (highest first)
    const sortedProducts = [...selectdata].sort((a, b) => b.score - a.score);

    // Take the top 3 highest-ranked products
    const topThree = sortedProducts.slice(0, 3);

    // The remaining products
    const remaining = sortedProducts.slice(3);

   
    
    // Set the products into two categories
    setTopProducts(topThree);
    setRemainingProducts(remaining);
  }, []);

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(remainingProducts.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const getData = (current, pageSize) => {
    // Normally you should get the data from the server
    return remainingProducts.slice(
      (current - 1) * pageSize,
      current * pageSize
    );
  };

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <div className="page-content sn-employees-managment">
          <Container fluid>
            <BreadCrumb title="View Leaderboard" pageTitle="" />
            <Row>
              <Col lg={12}>
                <Card className="h-100 d-flex flex-column justify-content-center">
                  <Container>
                    <div className="row g-5">
                      {topProducts.map((key, index) => (
                        <>
                          <div className="col-md-4 col-sm-12" key={index}>
                            <div
                              className={`leader-card position-relative ${key.class} pt-5 d-flex flex-column text-center my-5`}
                            >
                              <div className="position-absolute top-0 start-50 translate-middle">
                                <img
                                  src={Profile}
                                  className="card-img jump h-50 w-50 img-fluid rounded-circle"
                                  alt="Loading"
                                />
                              </div>

                              <div className="card-body">
                                <div className="card-title fw-bold text-white fs-4 fw-bold">
                                  {key.name}
                                </div>
                                <p className="card-text fw-bold text-secondary">
                                  {key.score.toFixed(0)}
                                </p>
                                <p className="fw-bold">{index + 1}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>

                    <div className="table-responsive">
                      <Table responsive borderless striped hover>
                        <thead className="table-text">
                          <tr className="align-middle">
                            {Tablethead.map((item, i) => (
                              <th scope="col" key={i}>
                                {item.title}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {getData(current, size).map((data, index) => (
                            <tr key={index} className="align-middle">
                              <th scope="row" className="py-3">
                                {(current - 1) * size + (index + 4)}
                              </th>
                              <td className="py-3">
                                <div>
                                  <img
                                    src={Profile}
                                    className="leader-img me-2"
                                    alt="Loading"
                                  />
                                  {data.name}
                                </div>
                              </td>
                              <td className="py-3">
                                <span
                                  className="px-2 py-1 rounded-pill"
                                  style={{
                                    backgroundColor: "rgb(212, 244, 255)",
                                    color: "rgb(24, 142, 182)",
                                  }}
                                >
                                  {data.score.toFixed()}
                                </span>
                              </td>
                              {/* <td className="py-3">
                                <span
                                  className="px-2 py-1 rounded-pill"
                                  style={{
                                    backgroundColor: "rgb(255, 218, 224)",
                                    color: "rgb(193, 42, 67)",
                                  }}
                                >
                                  {data.level}
                                </span>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className="table-filter-info">
                      <Pagination
                        className="pagination-data"
                        showTotal={(total, range) =>
                          `Showing ${range[0]}-${range[1]} of ${total}`
                        }
                        onChange={PaginationChange}
                        total={remainingProducts.length}
                        current={current}
                        pageSize={size}
                        showSizeChanger={false}
                        itemRender={PrevNextArrow}
                        onShowSizeChange={PerPageChange}
                      />
                    </div>
                  </Container>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default ViewLeaderboard;
