import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import starwin1 from "../../assets/images/trophy.png";
import starwin2 from "../../assets/images/trophy4.png";
import starwin3 from "../../assets/images/trophy333.png";
import starwin4 from "../../assets/images/trophy-star.png";

import bgAuth from "../../assets/images/multi-user.png";
import { useNavigate } from "react-router-dom";
import TopPerformerCard from "./TopPerformerCard";
import { formatTime } from "./FlatePicker";
 
const TopPerformance = ({
  employeeData,
  topPerformer,
  topEmployeeData,
}) => {
  const [tooltiptopCaller, setTooltipAgent] = useState(false);
  const toggleAgent = () => setTooltipAgent(!tooltiptopCaller);
 
  const [tooltiptopDialer, setTooltipDialer] = useState(false);
  const toggleDialer = () => setTooltipDialer(!tooltiptopDialer);
 
  const [tooltiptopDuration, setTooltipDuration] = useState(false);
  const toggleDuration = () => setTooltipDuration(!tooltiptopDuration);
 
  const [tooltipTopAnswered, setTooltipTopAnswered] = useState(false);
  const toggleTopAnswered = () => setTooltipTopAnswered(!tooltipTopAnswered);
 
  const navigate = useNavigate();
 
  const handleNavigationToTopCallers = () => {
    navigate("/topCallers-report");
  };
 
  const handleNavigationToTopDialers = () => {
    navigate("/topDialers-report");
  };
  const handleNavigationTopDuration = () => {
    navigate("/topCallDuration-report");
  };
  const handleNavigationTotalConnected = () => {
    navigate("/topConnected-report");
  };
  const loginType = JSON.parse(localStorage.getItem("logintype"));
  const permissionType = JSON.parse(localStorage.getItem("permission"));
 
  return (
    <div className="row">
     
      <div className="col-md-3 col-sm-3 col-xs-12">
        <TopPerformerCard
          title="Top Caller"
          tooltipId="DisableTopCaller"
          tooltipText="Employee with the highest total calls (incoming and outgoing)"
          tooltipState={tooltiptopCaller}
          toggleTooltip={toggleAgent}
          displayName={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.fullName || "-"
              :  employeeData?.length > 0
              ? topPerformer?.TopCallerFullName || "-"
              : "-"
          }
          count={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.callCount || "0"
              :   employeeData?.length > 0
              ? topPerformer?.TopCallerCallCount || "0"
              : "0"
          }
          profileImage={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.profileImageUrl || bgAuth
              :  employeeData?.length > 0
              ? topPerformer?.TopCallerProfileImageUrl || bgAuth
              : bgAuth
          }
          rankDetails={
            <div className="sn-performance-team-count">
                  {/* <div>
                    <img
                      src={starwin1}
                      alt="starwin1"
                      className="sn-star-empolyee-awardimg-star"
                    />
                  </div> */}
                 

            
                 <h5>
              <i className="ri-trophy-line ms-3" /> Org Rank: {topEmployeeData?.overall_rank || "-"}
              </h5>
              <h5>
              <i className="ri-trophy-line ms-4" /> Team Rank:
                {topEmployeeData?.team_ranks?.length > 0 ? (
                  <span className="sn-strategy-txt-main">
                    {topEmployeeData.team_ranks[0]?.team_rank} 
                  </span>
                ) : (
                  "-"
                )}
              </h5>
            

            </div>
          }
          actionLink={
            loginType === "organization" ||
            (loginType === "employee" && permissionType.length > 0)
          }
          actionHandler={handleNavigationToTopCallers}
        />
      </div>
 
      <div className="col-md-3 col-sm-3 col-xs-12">
        <TopPerformerCard
          title="Top Dialer"
          tooltipId="DisableTopDialer"
          tooltipText="Employee with the most outgoing calls made."
          tooltipState={tooltiptopDialer}
          toggleTooltip={toggleDialer}
          displayName={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.fullName || "-"
              :  employeeData?.length > 0
              ? topPerformer?.TopDialerFullName || "-"
              : "-"
          }
          count={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.totalOutgoing || "0"
              :  employeeData?.length > 0
              ? topPerformer?.TopDialerCallCount || "0"
              : "0"
          }
          profileImage={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.profileImageUrl || bgAuth
              :  employeeData?.length > 0
              ? topPerformer?.TopDialerProfileImageUrl || bgAuth
              : bgAuth
          }
          rankDetails={
            <div className="sn-performance-team-count">
            {/* <div>
              <img
                src={starwin1}
                alt="starwin1"
                className="sn-star-empolyee-awardimg-star"
              />
            </div> */}
           

      
           <h5>
              <i className="ri-trophy-line ms-3" /> Org Rank: {topEmployeeData?.overall_rank || "-"}
              </h5>
              <h5>
              <i className="ri-trophy-line ms-4" /> Team Rank:
                {topEmployeeData?.team_ranks?.length > 0 ? (
                  <span className="sn-strategy-txt-main">
                    {topEmployeeData.team_ranks[0]?.team_rank} 
                  </span>
                ) : (
                  "-"
                )}
              </h5>

      </div>
          }
          actionLink={
            loginType === "organization" ||
            (loginType === "employee" && permissionType.length > 0)
          }
          actionHandler={handleNavigationToTopDialers}
         
         
        />
      </div>
 
      <div className="col-md-3 col-sm-3 col-xs-12">
        <TopPerformerCard
          title="Highest Call Duration"
          tooltipId="DisableHighestDuration"
          tooltipText="Employee with the highest total call duration."
          tooltipState={tooltiptopDuration} // New state for this card
          toggleTooltip={toggleDuration} // New toggle function for this card
          displayName={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.fullName || "-"
              : employeeData?.length > 0
              ? topPerformer?.HighestDurationFullName || "-"
              : "-"
          }
          count={
            loginType === "employee" && permissionType.length === 0
              ? formatTime(topEmployeeData?.totalDuration || 0)  // Convert to h:mm:ss
              :  employeeData?.length > 0
              ? formatTime(topPerformer?.HighestDurationDuration || 0)  // Convert to h:mm:ss
              : "0 mins"
          }
          profileImage={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.profileImageUrl || bgAuth
              :  employeeData?.length > 0
              ? topPerformer?.HighestDurationProfileImageUrl || bgAuth
              : bgAuth
          }
         
          rankDetails={
            <div className="sn-performance-team-count">
            {/* <div>
              <img
                src={starwin1}
                alt="starwin1"
                className="sn-star-empolyee-awardimg-star"
              />
            </div> */}
           

      
           <h5>
              <i className="ri-trophy-line ms-3" /> Org Rank: {topEmployeeData?.overall_rank || "-"}
              </h5>
              <h5>
              <i className="ri-trophy-line ms-4" /> Team Rank:
                {topEmployeeData?.team_ranks?.length > 0 ? (
                  <span className="sn-strategy-txt-main">
                    {topEmployeeData.team_ranks[0]?.team_rank} 
                  </span>
                ) : (
                  "-"
                )}
              </h5>

      </div>
          }
          actionLink={
            loginType === "organization" ||
            (loginType === "employee" && permissionType.length > 0)
          }
          actionHandler={handleNavigationTopDuration}
        />
      </div>
 
      <div className="col-md-3 col-sm-3 col-xs-12">
        <TopPerformerCard
          title="Top Answered"
          tooltipId="DisableTopAnswered"
          tooltipText="Employee with the most answered calls."
          tooltipState={tooltipTopAnswered} // New state for this card
          toggleTooltip={toggleTopAnswered} // New toggle function for this card
          displayName={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.fullName || "-"
              :  employeeData?.length > 0
              ? topPerformer?.TopAnsweredFullName || "-"
              : "-"
          }
          count={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.totalAnswered || "0"
              :  employeeData?.length > 0
              ? topPerformer?.TopAnsweredAnswerCount || "0"
              : "0"
          }
          profileImage={
            loginType === "employee" && permissionType.length === 0
              ? topEmployeeData?.profileImageUrl || bgAuth
              :  employeeData?.length > 0
              ? topPerformer?.TopAnsweredProfileImageUrl || bgAuth
              : bgAuth
          }
          rankDetails={
            <div className="sn-performance-team-count">
                  {/* <div>
                    <img
                      src={starwin1}
                      alt="starwin1"
                      className="sn-star-empolyee-awardimg-star"
                    />
                  </div> */}
                 

            
                 <h5>
              <i className="ri-trophy-line ms-3" /> Org Rank: {topEmployeeData?.overall_rank || "-"}
              </h5>
              <h5>
              <i className="ri-trophy-line ms-4" /> Team Rank:
                {topEmployeeData?.team_ranks?.length > 0 ? (
                  <span className="sn-strategy-txt-main">
                    {topEmployeeData.team_ranks[0]?.team_rank} 
                  </span>
                ) : (
                  "-"
                )}
              </h5>
            

            </div>
          }
          actionLink={
            loginType === "organization" ||
            (loginType === "employee" && permissionType.length > 0)
          }
          actionHandler={handleNavigationTotalConnected}
        />
      </div>
 
    </div>
  );
};
 
export default TopPerformance