import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import {
  ADMIN_ACTIVE_INACTIVE_EMPLOYEE,
  SUPER_ADMIN_BY_PASSS_API,
  SUPER_ADMIN_EMPLOYEE_DATATABLE,
} from "../../helpers/url_helper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { toCamelCase } from "../../Components/Common/ToCamelCase";
import moment from "moment";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import Toast from "../../Components/Common/Toast";
import { toast, ToastContainer } from "react-toastify";
import UpdateAdmin from "./UpdatedAdmin";
import CommanDataTable from "../../Components/Common/DataTable";
import { Link } from "react-router-dom";
const api = new APIClient();

const DashboardCallCrm = () => {
  const [employeeData, setEmployeeTableData] = useState([]);
  const [modal_grid, setmodal_grid] = useState(false);
  const [orgDetail, setOrgDetails] = useState({});
  // Pagination state
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");


// Handle the click event when the Super Admin is being logged in
let isReloading = false; // Flag to track page reload state

const handleClick = async (data) => {
  try {
    const response = await api.create(`${SUPER_ADMIN_BY_PASSS_API}`, data);
   

    if (response?.message === "success") {
      const organizationToken = response?.data?.token;
      const newTab = window.open("/dashboard", "_blank");

      if (newTab) {
        localStorage.setItem("authUser", JSON.stringify(organizationToken));
        localStorage.setItem("logintype", JSON.stringify("organization"));
        localStorage.removeItem("SuperAdminlogintype");
        newTab.focus();
      } else {
        console.warn(
          "Failed to open new tab. Please check your browser's pop-up settings."
        );
        localStorage.setItem("SuperAdminlogintype", JSON.stringify("SuperAdmin"));
        localStorage.removeItem("logintype");
      }
    } else {
      console.error("response", response);
      Toast(response.message, "error");
    }
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};
useEffect(() => {
  const handleBeforeUnload = (event) => {
    if (!isReloading) {
      // Prevent changes to localStorage on reload
      event.preventDefault();
    }
    isReloading = false; // Reset the reloading flag after event
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);
useEffect(() => {
  const path = window.location.pathname;

  if (path === "/admin-dashboard") {
    localStorage.setItem("SuperAdminlogintype", JSON.stringify("SuperAdmin"));
    localStorage.removeItem('logintype');
  } else if (path === "/dashboard") {
    localStorage.setItem("logintype", JSON.stringify("organization"));
    localStorage.removeItem('SuperAdminlogintype');
  }

  return () => {
  };
}, [window.location.pathname]); 

useEffect(() => {
  const handleBeforeUnload = () => {
    isReloading = true; 
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    isReloading = false; 
  };
}, []);

useEffect(() => {
  const handleVisibilityChange = () => {
    if (isReloading) return; 

    if (document.visibilityState === 'visible') {
      localStorage.setItem("SuperAdminlogintype", JSON.stringify("SuperAdmin"));
      localStorage.removeItem('logintype');
    } else {
      localStorage.setItem("logintype", JSON.stringify("organization"));
      localStorage.removeItem('SuperAdminlogintype');
    }
  };

  window.addEventListener('visibilitychange', handleVisibilityChange);

  return () => {
    window.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, []);

  const columns = [
    {
      name: <span> Sr.No</span>,
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Organization Name</span>,
      selector: (row) => toCamelCase(row?.organizationName) || "-",
      cell: (row) => (
        <Link
          onClick={(e) => {
            e.preventDefault(); 
            handleClick(row);
          }}
          className="d-block mt-2 mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {`${row?.organizationName || "-"}`}
        </Link>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      cell: (row) =>
        <span className="sn-managment-team-scroll-tble">{row.email}</span> ||
        "-",
      sortable: true,
    },

    {
      name: <span>Mobile Number</span>,
      selector: (row) => row.mobileNumber || "-",
      cell: (row) =>
        (
          <span className="sn-managment-team-scroll-tble">
            {row.mobileNumber}
          </span>
        ) || "-",
      sortable: true,
    },
    {
      name: <span>Postal Code</span>,
      selector: (row) => row.pincode || "-",
      sortable: true,
    },

    {
      name: <span>Country</span>,
      selector: (row) => row.country || "-",
      cell: (row) => (
        <span
          className="d-block mt-2 mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {`${row?.country || "-"}`}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>State</span>,
      selector: (row) => row.state || "-",
      cell: (row) => (
        <span
          className="d-block mt-2 mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {`${row?.state || "-"}`}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>City</span>,
      selector: (row) => row.city || "-",
      cell: (row) => (
        <span
          className="d-block mt-2 mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {`${row?.city || "-"}`}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>Address</span>,
      selector: (row) => row.address || "-",
      cell: (row) => (
        <span
          className="d-block mt-2 mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {`${row?.address || "-"}`}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>Industry Type</span>,
      selector: (row) => row.industryType || "-",
      cell: (row) => (
        <span
          className="d-block mt-2 mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {`${row?.industryType || "-"}`}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>Selected Plan</span>,
      selector: (row) => row.selectedPlan || "-",
      cell: (row) => (
        <span
          className="d-block mt-2 mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {`${row?.selectedPlan || "-"}`}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>Organization Strength</span>,
      selector: (row) => row.organizationStrength || "-",
      sortable: true,
    },
    {
      name: <span>Organization ID</span>,
      selector: (row) => row.organizationId || "-",
      cell: (row) =>
        (
          <span className="sn-managment-team-scroll-tble">
            {row.organizationId}
          </span>
        ) || "-",
      sortable: true,
    },

    {
      name: <span>Storage Per GB</span>,
      selector: (row) => row.storagePerGB || "-",
      sortable: true,
    },

    {
      name: <span>Subscription Start Date</span>,
      selector: (row) =>
        moment(row.subscriptionStartDate).format("DD MMM YYYY") || "-",
      cell: (row) =>
        (
          <span className="sn-managment-team-scroll-tble">
            {moment(row.subscriptionStartDate).format("DD MMM YYYY")}
          </span>
        ) || "-",
      sortable: true,
    },
    {
      name: <span>Subscription End Date</span>,
      selector: (row) =>
        moment(row.subscriptionEndDate).format("DD MMM YYYY") || "-",
      cell: (row) =>
        (
          <span className="sn-managment-team-scroll-tble">
            {moment(row.subscriptionEndDate).format("DD MMM YYYY")}
          </span>
        ) || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      selector: (row) => row.status,
      cell: (row) => (
        <div className="form-check form-switch form-switch-success mb-3 ms-3 mt-3">
          <Input
            checked={row.status === "Active" ? "Active" : ""}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`SwitchCheck_${row._id}`}
            onChange={() => handleCheckboxChange(row)}
          />
          <Label className="form-check-label" for={`SwitchCheck_${row._id}`}>
            {row.status === "Active" ? "Active" : "Inactive"}
          </Label>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      cell: (row) => (
        <>
          <div className="hstack gap-2 sn-button-eye-edit-user">
            <button
              className="btn btn-sm btn-soft-success edit-list"
              onClick={() => showModal(row)}
            >
              <i className="ri-pencil-fill align-bottom" />
            </button>
          </div>
        </>
      ),
    },
  ];

  const showModal = (row) => {
    setmodal_grid(true);
    setOrgDetails({
      id: row._id,
      orgid: row.organizationId,
      orgname: row.organizationName,
      email: row.email,
      city: row.city,
      country: row.country,
      address: row.address,
      mobileNumber: row.mobileNumber,
      state: row.state,
      orgStrength: row.organizationStrength,
      industryType: row.industryType,
      pincode: row.pincode,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
        await EmployeeDataApi(currentPage, perPage, debouncedQuery);
      }
    };

    fetchData();
  }, [debouncedQuery, currentPage, perPage]);

  // Search field handler
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  // Debounce search query
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const EmployeeDataApi = useCallback(
    async (page, size = perPage, searchTerm = "") => {
      try {
        const response = await api.get(
          `${SUPER_ADMIN_EMPLOYEE_DATATABLE}?pg=${page}&lm=${size}&query=${searchTerm}`
        );
        if (response && response.success === true) {
          const apiResponse = response.data;
          setEmployeeTableData(apiResponse?.organization);
          setTotalRows(apiResponse?.totalCount);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    },
    []
  );

  const handleCheckboxChange = async (row) => {
    const newStatus = row.status === "Active" ? "Inactive" : "Active";
    try {
      const response = await api.patch(`${ADMIN_ACTIVE_INACTIVE_EMPLOYEE}`, {
        status: newStatus,
        organization_id: row._id,
      });
      Toast(response?.message, "success");
      setEmployeeTableData((prevRows) =>
        prevRows.map((item) =>
          item._id === row._id ? { ...item, status: newStatus } : item
        )
      );
    } catch (error) {
      HandleErrorMessage(error);
    }
  };

  // Pagination handler
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content sn-team-managment-main-page">
        <Container fluid>
          <BreadCrumb title="DASHBOARD" pageTitle="Tables" />
          <Row>
            <Col lg={12}>
              <Card className="h-100">
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Organization Management
                  </h4>
                </CardHeader>
                <CardBody>
                  <CommanDataTable
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                    column={columns}
                    tableData={employeeData}
                    handleSearch={handleSearch}
                    searchText={query}
                    handlePerRowsChange={handlePerRowsChange}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    // isLoading={isLoading}
                    noData="There are no records to display"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <UpdateAdmin
          modal_grid={modal_grid}
          EmployeeDataApi={EmployeeDataApi}
          setmodal_grid={setmodal_grid}
          orgDetail={orgDetail}
        />
      </div>
    </React.Fragment>
  );
};

export default DashboardCallCrm;
