import moment from 'moment';
import React from 'react'
import { Button, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';

const ModalNeverAttended = ({ modal_animationFlip, setmodal_animationFlip, outgoingCall }) => {
    const tog_animationFlip = () => {
        setmodal_animationFlip(!modal_animationFlip);
    }
   
    
    return (
        <>
            {/* Flip Animation */}
            <Modal id="flipModal" isOpen={modal_animationFlip} toggle={() => { tog_animationFlip(); }} modalClassName="flip" centered >
                <ModalHeader className="bg-light p-3" id="flipModalLabel" toggle={() => { tog_animationFlip(); }}>
                    Call Details
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        <Table className="table-bordered align-middle table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ textAlign: 'center', fontSize: 'medium' }}>Registered Mobile</th>
                                    <th scope="col" style={{ textAlign: 'center', fontSize: 'medium' }}>Call Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{outgoingCall?.fullName} ({outgoingCall?.mobileNumber})</td>
                                    <td>
                                        {outgoingCall?.type?.map((callDate, index) => {                         
                                            const momentObj = moment(callDate?.date).format("hh:mm A");
                                            return (
                                                <React.Fragment key={index}>                                             
                                                    {callDate.type === "OUTGOING" && <i style={{ color: "#FDA403" }} className="mdi mdi-arrow-up-left"></i>}&nbsp;
                                                    {callDate.type === "OUTGOING" && <span>{moment(callDate?.date).format("DD MMM YYYY")} , {momentObj}</span>}                                            
                                                        {index !== outgoingCall?.date?.length - 1 && <br />}                                            
                                                </React.Fragment>
                                            )
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
            </Modal>

        </>
    )
}

export default ModalNeverAttended
