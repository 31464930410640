import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CommanDataTable from "../../Components/Common/DataTable";
import { APIClient } from "../../helpers/api_helper";
import { DELETE_STORAGE_API } from "../../helpers/url_helper";
import { Card } from "reactstrap";
import DeleteModal from "../../Components/Common/DeleteModal"; // import your DeleteModal component
 
const api = new APIClient();
 
const StorageManagementMonthly = ({
  data,
  isLoading,
  setCurrentPage,
  setPerPage,
  currentPage,
  perPage,
  selectedEmployeeOptions,
}) => {
  const [monthlyData, setMonthlyData] = useState(data?.monthlyData || []);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for showing modal
  const [deleteItem, setDeleteItem] = useState(null); // State to store item to delete
 
  useEffect(() => {
    setMonthlyData(data?.monthlyData || []);
  }, [data]);
 
  const monthNames = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];
 
  const handleDelete = async () => {
    if (!deleteItem) return; // If no item is selected for deletion, return early
 
    const { month, year } = deleteItem;
    const startDate = `${year}-${month}-01`;
    const endDate = new Date(year, month, 0).toISOString().split("T")[0];
    const updatedIds = selectedEmployeeOptions?.map((userid) => userid?.id);
    const requestData = {
      startDate,
      endDate,
      employeeIds: updatedIds,
    };
 
    try {
      const response = await api.delete(DELETE_STORAGE_API, { data: requestData });
      if (response.success) {
        toast.success("Monthly data deleted successfully!");
        setMonthlyData((prevData) =>
          prevData.filter((item) => !(item.month === month && item.year === year))
        );
      } else {
        toast.error(response.message || "Failed to delete monthly data.");
      }
    } catch (error) {
      console.error("Delete error:", error);
      toast.error("An error occurred while deleting monthly data.");
    }
    setShowDeleteModal(false); // Close the modal after the deletion is confirmed
  };
 
  const filterDataMonthly = monthlyData.map((item, index) => {
    if (!item || !item.month || !item.year) {
      console.log(`Invalid data at index ${index}:`, item);
      return null;
    }
    return {
      month: monthNames[item.month - 1] || "-",
      year: item.year,
      usageSpace: item.monthlyStorage,
      DeleterowStore: (
        <a
          href="#0"
          className="font-weight-bold text-danger"
          onClick={() => {
            setDeleteItem(item); // Set the item to be deleted
            setShowDeleteModal(true); // Show the modal
          }}
        >
          <i className="ri-delete-bin-5-fill align-bottom" />
        </a>
      ),
    };
  }).filter((item) => item !== null);
 
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
 
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };
 
  const columnsStorageDataMonthly = [
    {
      name: <span className="font-weight-bold fs-13">S No.</span>,
      selector: (row, index) => index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: <span className="font-weight-bold fs-13">Month</span>,
      selector: (row) => row.month || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Year</span>,
      selector: (row) => row.year || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Usage Space</span>,
      selector: (row) => row.usageSpace || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      selector: (row) => row.DeleterowStore || "-",
      sortable: true,
    },
  ];
 
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 mb-4">
        <Card>
          <div className="sn-storage-tble-top sn-storage-month-tble-first">
            <h4 className="card-title mb-0">Monthly Recording Storage</h4>
            <CommanDataTable
              column={columnsStorageDataMonthly}
              tableData={filterDataMonthly}
              totalRows={filterDataMonthly?.length}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              perPage={perPage}
              currentPage={currentPage}
            />
          </div>
        </Card>
      </div>
 
      {/* Delete Modal */}
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete} // Call the delete function on confirm
        onCloseClick={() => setShowDeleteModal(false)} // Close the modal without deleting
      />
    </div>
  );
};
 
export default StorageManagementMonthly;
 