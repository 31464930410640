import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";
import { postFakeRegister } from "../../../helpers/fakebackend_helper";


function* registerUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeRegister, {
        organizationName: user.username,
        phoneNumber: user.phoneNo,
        countryCode: user.countrycode,
        pincode: user.postalcode,
        city: user.city,
        stateCode: user.stateCode,
        state: user.state,
        country: user.country,
        mobileNumber: user.mobileNumber,
        industryType: user.industryType,
        website: user.website,
        address: user.address,
        email: user.email,
      });
      
      if (response.message === "success") {
        yield put(registerUserSuccessful(response));
      } else {
        yield put(registerUserFailed(response));
      }
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
