import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import moment from "moment";
import DataTable from "react-data-table-component";
import ActionButton from "../../Components/Common/ActionButton";
import CommonModal from "../../Components/Common/CommonModal";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import { CommanDataTables } from "../../Components/Common/DashboardDataTbale";
import { APIClient } from "../../helpers/api_helper";
import {
  Delete_Exlude_Number,
  EXCLUDE_NUMBER_API,
  Get_Exclude_Number,
  Import_Exclude_Number,
  Update_Exlude_Number,
} from "../../helpers/url_helper";
import Toast from "../../Components/Common/Toast";
import * as Yup from "yup";
import { Col, Label } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumber } from "libphonenumber-js";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";

const api = new APIClient();

const ExcludeNumber = () => {
  const [todosList, setTodosList] = useState([]);

  const [selectedTodo, setSelectedTodo] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  // Get all excluded numbers
  const [excludedNumbers, setExcludedNumbers] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedExcludeNumber, setSelectedExcludeNumber] = useState();

  const [isAddExcludeNumberOpen, setIsAddExcludeNumberOpen] = useState(false);
  const [isAddBulkImportOpen, setIsAddBulkImportOpen] = useState(false);
  const [isEditExcludeNumberOpen, setIsEditExcludeNumberOpen] = useState(false);
  //state for country code input
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumbers, setMobileNumbers] = useState("");
  //search filed use in
  const [searchText, setSearchText] = useState("");

  // filter data
  const [filterData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [query, setQuery] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const loginType = JSON.parse(localStorage.getItem("logintype"));
  
  const CommonModalCounterShow = ({ isOpen, toggle, message, title }) => (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={toggle}>
          <span>&times;</span>
        </button>
      </div>
      <ModalBody>
        <pre>{message}</pre>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-danger" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );

  //columns
  const column = [
    {
      name: <span className="font-weight-bold fs-13"> SN.</span>,
      width: "55px",
      selector: (row, index) => index + 1,
    },
    {
      name: <span className="font-weight-bold fs-13">Mobile Number</span>,
      selector: (row) => row.phone,
      cell: (row) => <span>{row.phone || "N/A"}</span>,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13"> Uploaded By</span>,
      selector: (row) => row.name,
      cell: (row) => (
        <span style={{ textTransform: "capitalize" }}>{row.name}</span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Date Added</span>,
      selector: (row) => moment(row.createdAt).format("DD MMM YYYY"),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      selector: (row) => (
        <>
          <span className="sn-role-managment-responsive-btn">
            {loginType !== "employee" && (
              <button
                className="btn btn-sm btn-soft-danger remove-list"
                onClick={() => onClickTodoDelete(row)}
              >
                <i className="ri-delete-bin-5-fill align-bottom" />
              </button>)
            }
            &nbsp;&nbsp;
            <button
              className="btn btn-sm btn-soft-success edit-list"
              onClick={() => handleExcludeClick(row)}
            >
              <i className="ri-pencil-fill align-bottom" />
            </button>
            {/* &nbsp;&nbsp;
                        <button className="btn btn-sm btn-soft-info edit-list">
                            <i className="ri-user-follow-line align-bottom" />
                        </button> */}
          </span>
        </>
      ),
    },
  ];

  const AddtoggleBulkImport = () => {
    setIsAddBulkImportOpen(!isAddBulkImportOpen);
  };

  const AddtoggleExcludeNumber = () => {
    setIsAddExcludeNumberOpen(!isAddExcludeNumberOpen);
  };

  useEffect(() => {
    console.log(loginType,"loginType");

    getEmployeeData();
  }, []);

  const getRoleData = excludedNumbers.map((number, index) => ({
    phone: number.phoneNumber,
    _id: number._id,
    name: number.employeeId ? "Employee" : "Organization" || "Unknown",
    createdAt: number.createdAt,
  }));

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      countryCode: "",
      _id: "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (isAddExcludeNumberOpen) {
          const response = await api.create(EXCLUDE_NUMBER_API, {
            phoneNumber: `${values.phoneNumber}`,
            countryCode: values.countryCode,
          });

          if (response.success) {
            Toast("Exclude number added successfully", "success");
            getEmployeeData();
            setSelectedExcludeNumber(response?.data);
            setIsAddExcludeNumberOpen(false);
            formik.resetForm();
          } else {
            Toast("Failed to add exclude number", "error");
          }
        } else if (isAddBulkImportOpen) {
          const formData = new FormData();
          formData.append("file", selectedFile);

          const response = await api.create(Import_Exclude_Number, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          
          if (response?.success) {
            getEmployeeData();

            setIsAddBulkImportOpen(false);

            // Reset file
            setSelectedFile(null);

            if (response?.success) {
              // Extract counts and the breakdown from the response
              const {
                validCount = 0,
                invalidCount = 0,
                duplicateCount = 0,
                totalProcessed = 0,
                invalidBreakdown = {},
              } = response.data.summary;

              const {
                missingPhoneNumber,
                missingCountryCode,
                invalidCountryCode,
                invalidPhoneFormat,
                invalidLength,
              } = invalidBreakdown;

              // Construct the message to be displayed in the modal
              let message = `Import Summary:\n\n`;

              if (validCount > 0) {
                message += `. Successfully imported ${validCount} new exclude numbers.\n`;
              }

              if (duplicateCount > 0) {
                message += `. ${duplicateCount} records were skipped due to duplicate entries.\n`;
              }

              const missingFieldsCount =
                missingPhoneNumber + missingCountryCode;
              if (missingFieldsCount > 0) {
                message += `. ${missingFieldsCount} records have missing required fields.\n`;
              }

              if (missingPhoneNumber > 0) {
                message += `. ${missingPhoneNumber} records had missing exclude numbers.\n`;
              }

              if (invalidCountryCode > 0) {
                message += `. ${invalidCountryCode} records had invalid country code.\n`;
              }

              const invalidPhoneNumbers =
                (invalidPhoneFormat || 0) + (invalidLength || 0);
              if (invalidPhoneNumbers > 0) {
                message += `. ${invalidPhoneNumbers} records had invalid data format.\n`;
              }

              // Set modal title and message, then open it
              setModalTitle("Bulk Import Summary");
              setModalMessage(message);
              setModalOpen(true);

              // Reset states after import
              setIsAddBulkImportOpen(false);
              setSelectedFile(null);
              formik.resetForm();
            } else {
              Toast(response?.data?.message || "Import Failed", "error");
            }
          } else {
            Toast(response?.data?.message || "Import Failed", "error");
          }
        } else if (isEditExcludeNumberOpen) {
          if (!values._id) {
            Toast("Invalid record to update", "error");
            return;
          }

          const data = {
            phoneNumber: values.phoneNumber,
            countryCode: values.countryCode,
            _id: values._id,
          };

          const response = await api.put(Update_Exlude_Number, data);

          if (response.success) {
            await getEmployeeData();
            setIsEditExcludeNumberOpen(false);
            formik.resetForm();
            Toast("Exclude number updated successfully", "success");
          } else {
            Toast(
              response?.data?.message || "Failed to update exclude number",
              "error"
            );
          }
        }
      } catch (error) {
        Toast(error.response?.data.message, "error");
      }
    },
  });

  // Get all excluded numbers
  const getEmployeeData = async () => {
    try {
      const response = await api.get(`${Get_Exclude_Number}`);
      if (response.success === true) {
        const excludedContacts = response?.data?.excludedContacts || [];
        setExcludedNumbers(excludedContacts);

        const formatPhoneWithCountry = (phoneNumber) => {
          try {
            const parsedNumber = parsePhoneNumber(phoneNumber);
            if (parsedNumber) {
              // Get the full international format
              const fullNumber = parsedNumber.formatInternational();

              // Check if number starts with +1 and followed by 3 digits
              if (
                fullNumber.startsWith("+1") &&
                /^\+1\s[0-9]{3}/.test(fullNumber)
              ) {
                const countryCode =
                  fullNumber.substring(1, 2) + fullNumber.substring(3, 6);
                const remainingNumber = fullNumber
                  .substring(6)
                  .replace(/\s/g, "");
                return `(+${countryCode}) ${remainingNumber}`;
              }

              // Regular handling for other numbers
              const matches = fullNumber.match(/^\+(\d{1,3})\s+(.+)$/);
              if (matches) {
                const [_, fullCountryCode, remainingNumber] = matches;
                const cleanNumber = remainingNumber.replace(/\s/g, "");
                return `(+${fullCountryCode}) ${cleanNumber}`;
              }
              return phoneNumber;
            }
            return phoneNumber;
          } catch (error) {
            console.log(`Error formatting phone number: ${phoneNumber}`, error);
            return phoneNumber;
          }
        };

        // Update the DataTable data
        const updatedRoleData = excludedContacts.map((number, index) => ({
          phone: formatPhoneWithCountry(number.phoneNumber),
          _id: number._id,
          name: number.employeeId ? "Employee" : "Organization" || "Unknown",
          createdAt: number.createdAt,
        }));

        setTodosList(updatedRoleData);
      } else {
        Toast("Failed to fetch excluded numbers", "error");
      }
    } catch (error) {
      // Toast(error.response?.data?.message || "Error fetching data", "error");
    }
  };

  const onDeleteClick = useCallback(async (todo) => {
    if (todo) {
      try {
        // First make the API call
        const response = await api.delete(`${Delete_Exlude_Number}`, {
          data: { _id: [todo._id] },
        });

        if (response.success) {
          setDeleteModal(false);

          setExcludedNumbers((prev) =>
            prev.filter((item) => item._id !== todo._id)
          );
          setTodosList((prev) => prev.filter((item) => item._id !== todo._id));

          Toast("Exclude number deleted successfully", "success");
        } else {
          Toast(
            response?.message || "Failed to delete exclude number",
            "error"
          );
        }
      } catch (error) {
        console.error("Error occurred while deleting:", error);
        Toast("An error occurred while deleting the exclude number", "error");
      }
    }
  }, []);

  const handlePhoneChange = (value, data) => {
    let cleanedNumber = value;

    if (!value.startsWith("+")) {
      cleanedNumber = `+${value}`;
    }

    const dialCode = data.dialCode;
    const dialCountryCode = `+${data.dialCode}`;
    const numberWithoutDialCode = cleanedNumber.replace(`+${dialCode}`, "");

    // Update the state with the country code and phone number
    setCountryCode(dialCountryCode);
    setMobileNumbers(numberWithoutDialCode);

    // Update formik field values (both phoneNumber and countryCode)
    formik.setFieldValue("phoneNumber", cleanedNumber);
    formik.setFieldValue("countryCode", dialCountryCode); // Set the country code in Formik

    // Clear any previous error for the phone number
    if (formik.errors.phoneNumber) {
      formik.setFieldError("phoneNumber", "");
    }
  };

  // Function to handle adding a new number
  const handleAddNumber = useCallback(() => {
    formik.resetForm();
    formik.setFieldValue("phoneNumber", "");
    setIsAddExcludeNumberOpen(true);
  }, [formik]);

  // Handle bulk import button click
  const handleBulkImport = useCallback(() => {
    formik.resetForm();
    formik.setFieldValue("phoneNumber", "");
    setIsAddBulkImportOpen(true);
  }, [formik]);

  const onClickTodoDelete = (row) => {
    setSelectedTodo(row);
    setDeleteModal(true);
  };

  // Edit exclude number
  const handleExcludeClick = (arg) => {
    const selectedNumber = {
      exculdeId: arg?._id,
      phone: arg?.phone,
    };

    // Set the selected number state
    setSelectedExcludeNumber(selectedNumber);
    formik.setFieldValue("phoneNumber", arg.phone);
    formik.setFieldValue("_id", arg._id);
    setIsEditExcludeNumberOpen(true);
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (query.length >= 3) {
        setDebouncedSearchText(query);
      } else {
        setDebouncedSearchText("");
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    const filtered = (todosList || []).filter((item) =>
      Object.values(item).some((value) =>
        value
          ?.toString()
          ?.toLowerCase()
          ?.includes(debouncedSearchText.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [todosList, debouncedSearchText]);

  //download sample excel file for exclude number
  const downloadSampleExcelFile = () => {
    try {
      const link = document.createElement("a");
      //static excel file path
      link.href = "/Excl_Numbers.xlsx";

      // Set download attribute
      link.download = "Sample_Exclude_Number.xlsx";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      Toast("Sample Exclude Number file downloaded successfully", "success");
    } catch (error) {
      Toast("Error downloading sample file", "error");
    }
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen); // Toggle the modal's open state
  };

  useEffect(() => {
    if (modalOpen) {
      const timer = setTimeout(() => {
        setModalOpen(false);
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [modalOpen]);

  return (
    <>
      <ToastContainer />

      <div className="page-content sn-role-managment-page n-tble-home-page">
        <BreadCrumb title="Exclude Number" pageTitle="Exclude Number" />

        <div className="row">
          <div className="col-lg-12">
            <div className="h-100 card">
              <div className="card-body">
                <div className="sn-include-file-number sn-include-file-nymber-fi">
                  <div className="row mb-4">
                    <div className="sn-number-file--filter mb-4">
                      <div className="">
                        <ActionButton
                          label={"Add Number"}
                          onClick={handleAddNumber}
                          color={"danger"}
                        />
                      </div>
                      <div className="">
                        <ActionButton
                          label={"Bulk Import"}
                          onClick={handleBulkImport}
                          color={"danger"}
                        />
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-icon text-muted btn-sm fs-18"
                          title="Sample import"
                          onClick={downloadSampleExcelFile}
                        >
                          <i className="ri-download-2-line"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="sn-include-number-tble salenninja-dashboard-agent-tblemani">
                          {/* <DataTable
                          key={getRoleData.length}
                            columns={column}
                            data={getRoleData}
                            pagination
                            fixedHeaderScrollHeights="480px"
                            className="mt-4"
                          /> */}

                          <CommanDataTables
                            key={getRoleData.length}
                            //  columns={column}
                            data={getRoleData}
                            fixedHeaderScrollHeights="480px"
                            className="mt-4"
                            isLoading={isLoading}
                            handleSearch={handleSearch}
                            column={column}
                            tableData={filterData}
                            query={query}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CommonModal
        isOpen={isAddExcludeNumberOpen}
        toggle={AddtoggleExcludeNumber}
        formik={formik}
        modalTitle={"Add Number"}
        buttonText={"Submit"}
        className={"btn-success"}
        name={"phoneNumber"}
        onInput={(e) => {
          // Remove non-letter and non-space characters
        }}
      >
        {/* <Col sm={6} className=""> */}
        <Label htmlFor="phoneNumber" className="form-label">
          Contact Number<span className="text-danger">*</span>
        </Label>
        <div className="sn-country-code-flag-de">
          <PhoneInput
            name="phoneNumber"
            country="in"
            enableSearch={true}
            value={formik.values.phoneNumber}
            onChange={handlePhoneChange} // Custom onChange handler to handle validation
            onBlur={formik.handleBlur}
            className={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? "is-invalid"
                : ""
            }
          />

          {/* Error message display */}
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className="invalid-feedback">{formik.errors.phoneNumber}</div>
          )}
        </div>
        {/* </Col> */}
      </CommonModal>

      <CommonModal
        isOpen={isEditExcludeNumberOpen}
        toggle={() => setIsEditExcludeNumberOpen(false)}
        formik={formik}
        modalTitle={"Edit Number"}
        buttonText={"Update"}
        className={"btn-success"}
        name={"phoneNumber"}
        label={"Edit Mobile Number"}
        type={"text"}
        // onInput={selectedNumber}
        placeholder={"Enter Mobile Number"}
      >
        <Label htmlFor="phoneNumber" className="form-label">
          Contact Number<span className="text-danger">*</span>
        </Label>
        <div className="sn-country-code-flag-de">
          <PhoneInput
            name="phoneNumber"
            country="in"
            enableSearch={true}
            value={formik.values.phoneNumber}
            onChange={handlePhoneChange} // Custom onChange handler to handle validation
            onBlur={formik.handleBlur}
            className={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? "is-invalid"
                : ""
            }
          />

          {/* Error message display */}
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className="invalid-feedback">{formik.errors.phoneNumber}</div>
          )}
        </div>
        {/* </Col> */}
      </CommonModal>

      <CommonModal
        isOpen={isAddBulkImportOpen}
        toggle={AddtoggleBulkImport}
        formik={formik}
        modalTitle={"Bulk Import"}
        buttonText={"Submit"}
        className={"btn-success"}
        name={"phoneNumber"}
        label={"Bulk Import"}
        type={"file"}
        placeholder={"Enter Mobile Number"}
        onInput={(e) => { }}
        handleFileInput={handleFileInput}
      />
      <CommonModalCounterShow
        isOpen={modalOpen}
        toggle={handleModalToggle}
        message={modalMessage}
        title={modalTitle}
      />


      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => onDeleteClick(selectedTodo)}
        onCloseClick={() => setDeleteModal(false)}
        Role="Team"
      />


    </>
  );
};

export default ExcludeNumber;
