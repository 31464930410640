import React, { useEffect, useState } from "react";
import { Tree } from "antd";
import axios from "axios";
import { ADD_PERMISSION, DELETE_PERMISSION, GET_PERMISSIONS } from "../../../helpers/url_helper";
import Toast from "../../../Components/Common/Toast";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
import { Button } from "reactstrap";


const PermissionsTree = ({ rolewiseData, setIsRight, getRoleManagementData }) => {
  const [treeData, setTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [previouslyCheckedKeys, setPreviouslyCheckedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  // Keep a mapping of composite keys to original permission types
  const [keyToPermissionMap, setKeyToPermissionMap] = useState({});

  useEffect(() => {
    const getPermission = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(GET_PERMISSIONS);
        const { formattedData, mapping } = formatTreeData(response.data, rolewiseData.permissions);
        setTreeData(formattedData);
        setKeyToPermissionMap(mapping);
        const initialCheckedKeys = getInitialCheckedKeys(formattedData, rolewiseData.permissions);
        setCheckedKeys(initialCheckedKeys);
        setPreviouslyCheckedKeys(initialCheckedKeys);
      } catch (error) {
        console.error('Error fetching permissions', error);
      } finally {
        setIsLoading(false);
      }
    };
    getPermission();
  }, []);

  // Format the API data into a tree format
  const formatTreeData = (data, defaultPermissions) => {
    let keyCounter = 0;
    const generateKey = () => keyCounter++;
    const mapping = {};

    const setDefaultChecked = (node, permissions) => {
      if (permissions.includes(node.originalKey)) {
        node.checked = true;
      }
      if (node.children) {
        node.children.forEach((child) => setDefaultChecked(child, permissions));
      }
    };

    const transformData = (apiData) => {
      return Object.entries(apiData).map(([groupName, permissions]) => {
        const children = permissions.map((permission) => {
          // Create a composite key using group name and permission type
          const compositeKey = `${groupName}-${permission.type}`;
          // Store the mapping of composite key to original permission type
          mapping[compositeKey] = permission.type;
          
          return {
            title: permission.permissionName,
            key: compositeKey,
            originalKey: permission.type,
            isLeaf: true,
          };
        });

        const node = {
          title: groupName,
          key: generateKey().toString(),
          children,
        };
        setDefaultChecked(node, defaultPermissions);
        return node;
      });
    };

    return {
      formattedData: transformData(data),
      mapping
    };
  };

  const getInitialCheckedKeys = (data) => {
    const extractKeys = (node) => {
      let keys = [];
      if (node.checked) {
        keys.push(node.key);
      }
      if (node.children) {
        node.children.forEach((child) => {
          keys = keys.concat(extractKeys(child));
        });
      }
      return keys;
    };

    return data.reduce((acc, node) => acc.concat(extractKeys(node)), []);
  };

  const handleCheck = (checkedKeys) => {
    const filteredCheckedKeys = checkedKeys.filter(key => 
      typeof key === 'string' && key.includes('-')
    );
    setCheckedKeys(filteredCheckedKeys);
  };

  const handleSaveButtonClick = async () => {
    try {
      // Convert composite keys back to original permission types
      const originalPermissionTypes = checkedKeys.map(key => keyToPermissionMap[key]);
      
      let data = {
        hierarchyName: rolewiseData.name,
        permissionTypes: originalPermissionTypes,
      };
      
      const response = await axios.patch(ADD_PERMISSION, data);
      if (response.success) {
        Toast(response.message, "success");
        setTimeout(() => {
          setIsRight(false);
          getRoleManagementData()
        }, [2000])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteButtonClick = async () => {
    try {
      const removedCompositeKeys = previouslyCheckedKeys.filter(key => !checkedKeys.includes(key));
      // Convert composite keys back to original permission types
      const removedPermissions = removedCompositeKeys.map(key => keyToPermissionMap[key]);
      
      let data = {
        hierarchyName: rolewiseData.name,
        permissionTypes: removedPermissions,
      };
      
      let config = {
        method: "delete",
        url: DELETE_PERMISSION,
        data,
      };
      
      const response = await axios(config);
      if (response.success) {
        Toast(response.message, "success");
        setTimeout(() => {
          setIsRight(false);
          getRoleManagementData()
        }, [2000])
      }
    } catch (error) {
      HandleErrorMessage(error);
    }
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onSelect = (selectedKeys) => {
    setSelectedKeys(selectedKeys);
  };

  return (
    <>
      <div className="sn-select-sidebr">
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={handleCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          treeData={treeData}
        />
      </div>
      <div className="d-flex justify-content-center sn-sidebar-btn-next">
        <Button
          color="success"
          className="mt-2 mx-1"
          onClick={handleSaveButtonClick}
        >
          Save
        </Button>
        <Button
          color="danger"
          className="mt-2 mx-1"
          onClick={handleDeleteButtonClick}
        >
          Delete
        </Button>
      </div>
    </>
  );
};

// export default PermissionsTree;
export default PermissionsTree;
