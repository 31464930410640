import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { DELETE_ARCHIVES, EMPLOYEE_GET_ARCHIVES, GET_ARCHIVES } from "../../helpers/url_helper";
import axios from "axios";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import CommanDataTable from "../../Components/Common/DataTable";
import { DatePicker, DatePickerWithoutTime, DatePickerWithoutTimes } from "../../Components/Common/Flatpicker";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import { formatDate } from "../../Components/Common/FormDateTime";
import { APIClient } from "../../helpers/api_helper";
import { getObjectWiseDefaultEmployeeIds, getPreviousDate, setCurrentDate } from "../../Components/Common/DefaultDateSet";
import Toast from "../../Components/Common/Toast";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import { toCamelCase } from "../../Components/Common/ToCamelCase";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";
import SubExpired from "../../Components/Common/subExpired";
import { addMonths } from "../../Components/Common/FlatePicker";

const api = new APIClient();

const CallArchivesReport = () => {
    document.title = "Archives Recordings";
    const [isLoading, setIsLoading] = useState(false);
    const { profileData } = useProfileData()
    const navigate = useNavigate();
    const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);
    const permissionType = JSON.parse(localStorage.getItem("permission"));
    const loginType = JSON.parse(localStorage.getItem("logintype"))
    const GetArchivePermission = permissionType?.includes("GET_ARCHIVE");

    const [EmployeeData, setEmployeeData] = useState([])
    const previewsDate = getPreviousDate()
    const currentDate = setCurrentDate()

    //search filed use in
    const [searchText, setSearchText] = useState('');

    //date picker state
    const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
    const [selectedFromDate_Test, setSelectedFromDate_Test] = useState(previewsDate);
    const [selectedToDate, setSelectedToDate] = useState(currentDate);
    const [selectedToDate_Test, setSelectedToDate_Test] = useState(currentDate);

    const [selectedFromDateTwo, setSelectedFromDateTwo] = useState(selectedFromDate_Test);
    const [selectedToDateTwo, setSelectedToDateTwo] = useState(selectedToDate_Test);
    // employee select
    const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
    const [selectedEmployeeOptions2, setSelectedEmployeeOptions2] = useState([]);
    const [debouncedSearchText, setDebouncedSearchText] = useState('');
    const [filterData, setFilteredData] = useState([])
    const [prevEmployeesIds_Test, setPrevEmployeesIds_Test] = useState([]);
    const [remainingDays, setRemainingDays] = useState(null);
    const [error, setError] = useState("")
    const [isFirstEffectRun, setIsFirstEffectRun] = useState(false);
    //select team state
    const [teamIndu, setTeamIndu] = useState([])
    const [EmployeeDefaultWithoutTeam, setEmployeeDefaultWithoutTeam] = useState([]);
    // Pagination settings
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    const [archivesData, setArchivesData] = useState()

    // const [employeesId, setEmployeeId] = useState();
    const [handleSubmits, setHandleSubmit] = useState(false);
    // flatPicker Date
    // let formattedStartDate;
    // let formattedEndDate;
    // if (!handleSubmits) {
    //     formattedStartDate = formatDate(selectedFromDateTwo);
    //     formattedEndDate = formatDate(selectedToDateTwo);
    // } else {
    //     formattedStartDate = formatDate(selectedFromDate);
    //     formattedEndDate = formatDate(selectedToDate);
    // }

    const isWithinRange = (date) => {
        const today = new Date();
        return date >= selectedFromDate && date <= today;
      };
     
      const handleFromDateChange = (dates) => {
        const newFromDate = dates[0];
        setSelectedFromDate(newFromDate);
        const today = new Date();
        const threeMonthsAgo = addMonths(today, -3);
        if (newFromDate >= threeMonthsAgo && newFromDate <= today) {
          setSelectedToDate(today);
        } else {
          const newToDate = addMonths(newFromDate, 3);
          setSelectedToDate(newToDate);
        }
      };
     
      const handleToDateChange = (dates) => {
        const newToDate = dates[0];
        if (isWithinRange(newToDate)) {
          setSelectedToDate(newToDate);
        } else {
          console.warn("Selected date is out of range.");
        }
      };

    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchText.length >= 3) {
                setDebouncedSearchText(searchText);
            } else {
                setDebouncedSearchText('');
            }
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    useEffect(() => {
        const filtered = (archivesData || []).filter((item) =>
            Object.values(item).some((value) =>
                value?.toString()?.toLowerCase()?.includes(debouncedSearchText.toLowerCase())
            )
        );
        setFilteredData(filtered);
        if (debouncedSearchText) setCurrentPage(1);
    }, [archivesData, debouncedSearchText]);


    // search field
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    // table column
    const column = [
        {
            name: <span className='font-weight-bold fs-13'>Employee</span>,
            selector: row => toCamelCase(row.employeeName),
            cell: row => <span style={{ textTransform: 'capitalize' }}>{row.employeeName}</span>,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Created At</span>,
            selector: row => moment(row.createdAt).format('DD MMM YYYY hh:mm A'),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Archive Size</span>,
            selector: row => row.archiveSize,
        },
        {
            name: <span className='font-weight-bold fs-13'>Archive Status</span>,
            selector: (cell) => {
                switch (cell.archiveStatus) {
                    case "DELETED":
                        return <span className="badge sn-delete-text-tble"> {cell.archiveStatus} </span>;
                    case "PENDING":
                        return <span className="badge sn-pending-text-tble"> {cell.archiveStatus} </span>;
                    case "ARCHIVED":
                        return <span className="badge sn-archived-text-tble"> {cell.archiveStatus} </span>;
                    default:
                        return <span>-</span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            selector: row => (
                <>
                    {row.archiveStatus === "ARCHIVED" ? <button className="btn btn-sm button-grey-main edit-list">
                        <a href={row.zipLink} download="Example-PDF-document" target="_blank" rel="noreferrer">
                            <i className="ri-download-2-line" />
                        </a>
                    </button> : ''}
                    {row.archiveStatus === "ARCHIVED" ? <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => deleteArchives(row?.id)}>
                        <i className="ri-delete-bin-5-fill align-bottom" />
                    </button> : ''}
                </>
            )
        },
    ];

    useEffect(() => {
        const calculateRemainingDays = () => {
            if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
                const endDate = new Date(profileData?.subscriptionEndDate);
                const startDate = new Date(profileData?.subscriptionStartDate);
                const currentDate = new Date();

                const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
                const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
                setRemainingDays(remainingDays > 0 ? remainingDays : 0);
                const interval = setInterval(() => {
                    const newCurrentDate = new Date();
                    const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
                    setRemainingDays(newRemainingDays);
                }, 1000 * 60 * 60 * 24);

                return () => clearInterval(interval);
            } else {
                setRemainingDays(null);
            }
        };

        calculateRemainingDays();
    }, [profileData]);


    // useEffect(() => {
    //     const updatedIds = selectedEmployeeOptions?.map(userid => userid?.id);
    //     setEmployeeId(updatedIds);
    // }, [selectedEmployeeOptions])

    // delete Archives
    const deleteArchives = async (getId) => {
        try {
            const data = {
                archiveId: getId
            };
            const config = {
                method: 'delete',
                url: `${DELETE_ARCHIVES}`,
                data
            };
            const response = await axios(config);
            if (response && response.success === true) {
                Toast(response?.message, "success");
                getArchives();
            }
        } catch (error) {
            if (!remainingDays === 0) {
                HandleErrorMessage(error);
            }
        }

    }

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    useEffect(() => {
        if (!isFirstEffectRun && selectedEmployeeOptions?.length > 0) {
            let updatedIds = prevEmployeesIds_Test.length > 0 ? prevEmployeesIds_Test : selectedEmployeeOptions2?.map((option) => option?.id) || [];
            if (updatedIds.length > 0) {
                let dateObj = {
                    startDate: formatDate(selectedFromDateTwo),
                    endDate: formatDate(selectedToDateTwo)
                }
               

                getArchives(currentPage, 10, "", updatedIds, dateObj);
            }
            setIsFirstEffectRun(true); // Mark as executed
            setPrevEmployeesIds_Test(updatedIds)
        }
    }, [selectedEmployeeOptions]);

    // table data
    const handleSubmit = async () => {
        if (selectedEmployeeOptions.length === 0) {
            setError("You must select at least one");
            return;
        }
        let updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
        setPrevEmployeesIds_Test(updatedIds);
        setSelectedFromDateTwo(selectedFromDate);
        setSelectedToDateTwo(selectedToDate);
        let dateObj = {
            startDate: formatDate(selectedFromDate),
            endDate: formatDate(selectedToDate)
        }
        setSelectedEmployeeOptions2(selectedEmployeeOptions);
        setCurrentPage(1);
        setPerPage(10);
        setError("");
        setHandleSubmit(true);
        await getArchives(1, 10, updatedIds, dateObj);
    };


    useEffect(() => {
        if (isFirstEffectRun === true) {
            let updatedIds = prevEmployeesIds_Test.length > 0 ? prevEmployeesIds_Test : selectedEmployeeOptions2?.map((option) => option?.id) || [];
            // const fetchData = async () => {
            //     await getArchives(currentPage, perPage, updatedIds);
            // };

            const fetchData = async () => {
                let dateObj = {
                    startDate: formatDate(selectedFromDateTwo),
                    endDate: formatDate(selectedToDateTwo)
                }
                await getArchives(currentPage, perPage, updatedIds, dateObj);
            };

            fetchData();
        }
    }, [currentPage, perPage]);
    const getArchives = async (page, size = perPage, updatedIds, dateObj) => {
        setIsLoading(true);
        try {
            // const updatedIds = EmployeeData?.map(option => option?.id) || [];
            if (!updatedIds) {
                updatedIds = selectedEmployeeOptions2.map((userid) => userid?.id);
            }
            let data = {
                startDate: dateObj?.startDate ? dateObj?.startDate :formatDate(selectedFromDate),
                endDate: dateObj?.endDate ? dateObj?.endDate : formatDate(selectedToDate),
                employeeIds: updatedIds
            };
            const response = await api.create(`${GET_ARCHIVES}?pg=${page}&lm=${size}`, data);
            if (response && response.success === true) {
                const apiResponse = response.data
                setIsLoading(false)
                let tableData = (apiResponse?.resData || [])?.map(archive => ({
                    id: archive?._id,
                    employeeName: archive?.employeeName || 'unknown',
                    createdAt: archive?.createdAt || '-',
                    archiveSize: archive?.archiveSize || '-',
                    archiveStatus: archive?.archiveStatus || '-',
                    zipLink: archive?.zipLink
                }));
                setArchivesData(tableData);
                setTotalRows(apiResponse.totoalArchiveCount);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }


    // reset button clear
    const handleReset = () => {
        setSelectedToDate([])
        setSelectedFromDate([])
        setSelectedEmployeeOptions([]);
        setTeamIndu([]);
    }

    const handleClose = () => {
        setIsExpiredModalOpen(false);
        navigate("/planPackage");
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {(remainingDays === 0 && isExpiredModalOpen) && <SubExpired onClose={handleClose} profileData={profileData?.selectedPlanDetails?.planName} remainingDays={remainingDays} />}
            <div className="page-content sn-recording-archives-page">
                <Container fluid>
                    <BreadCrumb title='RECORDING ARCHIVES' pageTitle="Archives Recordings" />
                    <Row>
                        <Col lg={12}>
                            <Card className='h-100'>
                                <CardBody>
                                    <Row className="sn-header-select-employess-home sn-top-inputs-heading mb-0 pt-0">
                                        <div className="col-md-2 col-sm-12">
                                         <DatePicker
                                            labelContent="From Date"
                                            getDate={selectedFromDate}
                                            options={{
                                              disable: [
                                                {
                                                  from: new Date(),
                                                },
                                              ],
                                              dateFormat: "d M, Y",
                                              maxDate: new Date(
                                                new Date().setDate(new Date().getDate())
                                              ),
                                            }}
                                           onChange={handleFromDateChange}
                                         />
                                        </div>
                                        <div className="col-md-2 col-sm-12">
                                        <DatePicker
                                         labelContent="To Date"
                                         getDate={selectedToDate}
                                         options={{
                                           dateFormat: "d M, Y",
                                           minDate: selectedFromDate,
                                           // maxDate: 'today',
                                           enable: [
                                             {
                                               from: selectedFromDate,
                                               to: Math.min(
                                                 addMonths(selectedFromDate, 3),
                                                 new Date()
                                               ),
                                             },
                                           ],
                                           onOpen: () => {
                                             const currentDate = new Date();
                                             const threeMonthsFromStart = addMonths(
                                               selectedFromDate,
                                               3
                                             );
                                             if (threeMonthsFromStart > currentDate) {
                                               return {
                                                 enable: [
                                                   {
                                                     from: selectedFromDate,
                                                     to: currentDate,
                                                   },
                                                 ],
                                               };
                                             }
                                           },
                                         }}
                                         onChange={handleToDateChange}
                                       />
                                        </div>

                                        <div className="col-md-3 col-sm-12">
                                            <TeamSelect
                                                setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                                                teamIndu={teamIndu} setTeamIndu={setTeamIndu} labelContent='Select Team' />
                                        </div>

                                        <div className="col-md-2 col-sm-12">
                                            <EmployeeSelect
                                                setEmployeeDefaultWithoutTeam={setEmployeeDefaultWithoutTeam}
                                                EmployeeDefaultWithoutTeam={EmployeeDefaultWithoutTeam}
                                                error={error}
                                                setError={setError} setSelectedEmployeeOptions2={setSelectedEmployeeOptions2} setEmployeeDefault={setEmployeeData}  EmployeeDefault={teamIndu.length === 0 ? EmployeeDefaultWithoutTeam : EmployeeData} teamIndu={teamIndu} selectedEmployeeOptions={selectedEmployeeOptions}
                                                setSelectedEmployeeOptions={setSelectedEmployeeOptions} labelContent='Select Employee' />
                                        </div>


                                        <div className="col-md-3 col-sm-12">
                                            <FormGroup className="mt-2">
                                                <Label for="exampleSelect"></Label>
                                                <div id="exampleSelect" className="sn-call-apply-btn-reset">
                                                    <Button id="exampleSelect" type="submit" color="danger" className="" onClick={handleSubmit} disabled={isLoading} >
                                                        Apply
                                                    </Button>
                                                    <Button className="" style={{ marginLeft: "10px" }} id="exampleSelect" outline color="danger" onClick={handleReset} disabled={isLoading}>
                                                        Reset
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </div>

                                    </Row>
                                    <Row className="sn-team-managment-tble mt-0 pt-0">
                                        <Col lg={12} className="mt-0 pt-0">
                                            <CommanDataTable className="mt-0 pt-0"
                                                setCurrentPage={setCurrentPage}
                                                perPage={perPage}
                                                handleSearch={handleSearch}
                                                column={column} tableData={filterData} searchText={searchText}
                                                handlePerRowsChange={handlePerRowsChange}
                                                totalRows={totalRows} currentPage={currentPage}
                                                isLoading={isLoading}
                                                handlePageChange={handlePageChange}
                                                noData='There are no records to display'
                                                inputClass={(archivesData && archivesData.length > 0) || searchText ? 'd-block' : 'd-none'}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CallArchivesReport