import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProfileData } from "../Components/Hooks/UserHooks";
import ExcludeNumber from "../pages/ExcludeNumber/ExcludeNumber";



const Navdata = () => {
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const loginType = JSON.parse(localStorage.getItem("logintype"))
  const SuperAdminlogintype = JSON.parse(localStorage.getItem("SuperAdminlogintype"));
  // const viewReportPermission = permissionType?.includes("VIEW_REPORT");
  const getRecordingPermission = permissionType?.includes("GET_RECORDINGS");
  const GETARCHIVEPermission = permissionType?.includes("GET_ARCHIVE");
  const Getpermission = permissionType?.includes("GET_TEAMS");
  const GetEmployeepermission = permissionType?.includes("GET_EMPLOYEES");
  const { profileData, error, message } = useProfileData();

  const history = useNavigate();
  const [isDashboard, setIsDashboard] = useState(false);
  const [isTeamManagement, setIsTeamManagement] = useState(false);
  const [isRoleManagement, setIsRoleManagement] = useState(false);
  const [isUserManagement, setIsUserManagement] = useState(false);
  const [isReportManagement, setIsReportManagement] = useState(false);
  const [isCallRecording, setIsCallRecording] = useState(false)
  const [isSubscription, setIsSubscription] = useState(false);
  const [isPages, setIsPages] = useState(true);
  // Pages
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [remainingDays, setRemainingDays] = useState(null);

  const [isAgentPerformance, setIsAgentPerformance] = useState(false);
  const [setIsAgentPerformancenew, setIsAgentPerformanceperformance] = useState(false);


  const isSubscriptionExpired = remainingDays <= 0



  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }


  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    // if (iscurrentState !== "teamManagement") {
    //   setIsTeamManagement(false);
    // }
    if (iscurrentState !== "management") {
      setIsUserManagement(false);
    }
    // if (iscurrentState !== "roleManagement") {
    //   setIsRoleManagement(false);
    // }
    if (iscurrentState !== "callRecordingReport") {
      setIsCallRecording(false);
    }
    if (iscurrentState !== "report") {
      setIsReportManagement(true);
    }
    if (iscurrentState !== "subscription") {
      setIsSubscription(false);
    }
    if (iscurrentState !== "agentPerformance") {
      setIsAgentPerformance(false);
    }
    if (iscurrentState !== "performancemetrics") {
      setIsAgentPerformanceperformance(false);
    }
  }, [
    history,
    isTeamManagement,
    iscurrentState,
    isCallRecording,
    isRoleManagement,
    isUserManagement,
    isDashboard,
    isReportManagement,
    isSubscription,
    isAgentPerformance,
    setIsAgentPerformancenew
  ]);


  useEffect(() => {
    const calculateRemainingDays = () => {
      if (
        profileData?.subscriptionEndDate &&
        profileData?.subscriptionStartDate
      ) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );
        const remainingDays = Math.ceil(
          (endDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil(
            (endDate - newCurrentDate) / (1000 * 60 * 60 * 24)
          );
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);


  const superAdminMenu = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Organization Management",
      icon: "ri-dashboard-2-line",
      link: "/admin-dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
  ];

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },

    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },


    ...(((loginType === 'employee') || (loginType === 'organization')) ? [{
      id: "report",
      label: "Report",
      icon: "ri-pages-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsPages(!isPages);
        setIscurrentState("Report");
        updateIconSidebar(e);
      },
      stateVariables: isPages,
      subItems: [
        {
          id: 1,
          label: "Call Logs Report",
          link: "/call-report",
          parentId: "report",
        },
        {
          id: 2,
          label: "Employee Report",
          link: "/employee-report",
          parentId: "report",
        },
        {
          id: 3,
          label: "Client Report",
          link: "/client-report",
          parentId: "report",
        },
        {
          id: 4,
          label: "Never Attended",
          link: "/neverAttended-report",
          parentId: "report",
        },
        {
          id: 5,
          label: "Not Picked Up by Client",
          link: "/notPickeped-report",
          parentId: "report",
        },

        // {
        //   id: 6,
        //   label: "Top Caller ",
        //   link: "/topCallers-report",
        //   parentId: "report",
        // },
        // {
        //   id:7,
        //   label:"Top Dialer",
        //   link:"/topDialers-report",
        //   parentId:"report",
        // },
        // {
        //   id: 8,
        //   label: "Highest Call Duration ",
        //   link: "/topCallDuration-report",
        //   parentId: "report",
        // },
        // {
        //   id: 9,
        //   label: "Top Answered",
        //   link: "/topConnected-report",
        //   parentId: "report",
        // }
      ],
    }] : []),

    ...(( (loginType === 'organization') || loginType==='employee' && permissionType.length>0) ? [
      {
        id: "performancemetrics",
        label: "Performance Metrics",
        icon: "ri-numbers-line",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsAgentPerformanceperformance(!setIsAgentPerformancenew);
          setIscurrentState("performancemetrics");
          updateIconSidebar(e);
        },
        stateVariables: setIsAgentPerformancenew,
        subItems: [
          { id: 1, label: "Top Caller", link: "/topCallers-report", parentId: "performancemetrics" },
          { id: 2, label: "Top Dialer", link: "/topDialers-report", parentId: "performancemetrics" },
          { id: 3, label: "Top Answered", link: "/topConnected-report", parentId: "performancemetrics" },
          { id: 4, label: "Highest Call Duration", link: "/topCallDuration-report", parentId: "performancemetrics" },
          // { id: 5, label: "Agent Performance Report", link: "/agent-performance", parentId: "performancemetrics" },
          // { id: 6, label: "Customize Parameters", link: "/", parentId: "performancemetrics" },
          // { id: 7, label: "View Leaderboard", link: "/view-leaderboard", parentId: "performancemetrics" },
          // { id: 3, label: "Customize Parameters", link: "/", parentId: "performancemetrics" },
          // { id: 1, label: "View Leaderboard", link: "/", parentId: "performancemetrics" },
        ],
      }] : []),

    ...(((GETARCHIVEPermission || (getRecordingPermission && loginType === 'employee')) || loginType === 'organization') ? [{
      id: "recordingReport",
      label: "Recording Report",
      icon: "ri-record-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsCallRecording(!isCallRecording);
        setIscurrentState("callRecordingReport");
        updateIconSidebar(e);
      },
      stateVariables: isCallRecording,
      subItems: [
        ...(((getRecordingPermission && loginType === 'employee') || (loginType === 'organization')) ? [{
          id: 1,
          label: "Recording Report",
          link: "/call-recordings",
          parentId: "recordingReport",
        },] : []),
        ...(((GETARCHIVEPermission && loginType === 'employee') || (loginType === 'organization')) ? [{
          id: 2,
          label: "Recording Archives",
          link: "/call-archives",
          parentId: "recordingReport",
        },] : []),
      ],
    },] : []),

    ...(((GETARCHIVEPermission || (getRecordingPermission && loginType === 'employee')) || loginType === 'organization') ? [{
      id: "management",
      label: "Management",
      icon: "ri-user-settings-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsUserManagement(!isUserManagement);
        setIscurrentState("management");
        updateIconSidebar(e);
      },
      stateVariables: isUserManagement,
      subItems: [
        ...(((Getpermission && loginType === 'employee') || (loginType === 'organization')) ? [{
          id: 1,
          label: "Team Management",
          link: "/team-Management",
          parentId: "management",
        },] : []),
        ...(((GetEmployeepermission && loginType === 'employee') || (loginType === 'organization')) ? [{
          id: 2,
          label: "Employee Management",
          link: "/employee-Management",
          parentId: "management",
        },] : []),
        ...((loginType === 'organization') ? [{
          id: 3,
          label: "Role Management",
          link: "/role-management",
          parentId: "management",
        },] : []),
      ],
    },] : []),

    ...((((  loginType === 'employee')) || loginType === 'organization') ? [{
      id: "management",
      label: "Exclude Number",
      icon: "bx bxs-calculator",
      link: "/excludeNumber-report",
      click: function (e) {
        e.preventDefault();
        setIsUserManagement(!ExcludeNumber);
        setIscurrentState("management");
        updateIconSidebar(e);
      },
    },] : []),

    // ...((loginType === 'organization') ? [
    //   {
    //     id: "storagemanagment",
    //     label: "Storage Management",
    //     icon: "ri-folder-3-fill",
    //     link: "/storage-management",
    //     // click: function (e) {
    //     //   e.preventDefault();
    //     //   setIsAgentPerformance(!isAgentPerformance);
    //     //   setIscurrentState("agentPerformance");
    //     //   updateIconSidebar(e);
    //     // },
    //     // stateVariables: isAgentPerformance,
    //     // subItems: [
    //     //   { id: 1, label: "Agent Performance Report", link: "/agent-performance", parentId: "agentPerformance" },
    //     //   { id: 2, label: "Set Performance Criteria", link: "/", parentId: "agentPerformance" },
    //     //   { id: 3, label: "View Leaderboard", link: "/view-leaderboard", parentId: "agentPerformance" },
    //     // ],
    //   }] : []),

      // ...((loginType === 'organization') ? [
      //   {
      //     id: "agentPerformance",
      //     label: "Lead Management",
      //     icon: " ri-team-fill",
      //     link: "/Lead-Managment",
         
      //   }] : []),

    ...((loginType === 'organization') ? [
      {
        id: "subscription",
        label: "Subscription",
        icon: "ri-group-fill",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsSubscription(!isSubscription);
          setIscurrentState("subscription");
          updateIconSidebar(e);
        },
        stateVariables: isSubscription,
        subItems: [
          { id: 1, label: "Subscriptions", link: "/planPackage", parentId: "subscription" },
          { id: 2, label: "Invoice", link: "/invoices", parentId: "subscription" },
        ],
      }] : []),

  ];

  const menu = SuperAdminlogintype === 'SuperAdmin' ? superAdminMenu : menuItems;
  return <React.Fragment>{menu}</React.Fragment>;
};
export default Navdata;
