/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import { CREATE_ARCHIVES, DELETE_ALL_RECORDING, DELETE_RECORDING, EMPLOYEE_CREATE_ARCHIVES, EMPLOYEE_GET_RECORDING_DATA, EMPLOYEE_GET_RECORDING_SUMMARY, GET_RECORDING_DATA, GET_RECORDING_SUMMARY } from "../../helpers/url_helper";
import moment from "moment";
import 'react-h5-audio-player/lib/styles.css';
import { ToastContainer, toast } from "react-toastify";
import CommanDataTable from "../../Components/Common/DataTable";
import { DatePicker, DatePickerWithoutTime, DatePickerWithoutTimes } from "../../Components/Common/Flatpicker";
import TeamSelect from "../../Components/Common/TeamSelect";
import SelectOne from "../../Components/Common/SelectOne";
import { formatDate } from "../../Components/Common/FormDateTime";
import { APIClient } from "../../helpers/api_helper";
import { getPreviousDate, setCurrentDate } from "../../Components/Common/DefaultDateSet";
import Toast from "../../Components/Common/Toast";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import { addMonths, formatTime } from "../../Components/Common/FlatePicker";
import { toCamelCase } from "../../Components/Common/ToCamelCase";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";
import SubExpired from "../../Components/Common/subExpired";
const api = new APIClient();



const CallRecordingReport = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { profileData } = useProfileData()
    const navigate = useNavigate();
    const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);
    document.title = "Call Recording";
    const permissionType = JSON.parse(localStorage.getItem("permission"));
    const loginType = JSON.parse(localStorage.getItem("logintype"))
    const deleteRecordingPermission = permissionType?.includes("DELETE_RECORDING");
    const CREATE_ARCHIVERecordingPermission = permissionType?.includes("CREATE_ARCHIVE");

    const previewsDate = getPreviousDate()
    const currentDate = setCurrentDate()

    //search filed use in
    const [searchText, setSearchText] = useState('');

    // filter data
    const [filterData, setFilteredData] = useState([])

    const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
    const [selectedToDate, setSelectedToDate] = useState(currentDate);

    // Pagination settings
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    // employee select 
    const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
    const [selectedEmployeeOptions2, setSelectedEmployeeOptions2] = useState([]);

    //select team state
    const [teamIndu, setTeamIndu] = useState([])

    const [remainingDays, setRemainingDays] = useState(null);
    // flatPicker Date
    const formattedStartDate = formatDate(selectedFromDate);
    const formattedEndDate = formatDate(selectedToDate);

    // summary show 
    const [show, setShow] = useState(false)

    //team state
    const [getTableData, setGetTableData] = useState([])
    const [recordingSummary, setRecordingSummary] = useState([])
    const [fromSummaryDate, setFromSummary] = useState()
    const [toSummaryDate, setToSummary] = useState()
    const [EmployeeData, setEmployeeData] = useState([])

    const [shouldFetchData, setShouldFetchData] = useState(true);
    const [dataFetched, setDataFetched] = useState(false);
    const [debouncedSearchText, setDebouncedSearchText] = useState('');


    // search field 
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };



    // subbmit all function
    const handleRecordingSubmit = () => {
        const employeeIds = selectedEmployeeOptions?.id;
        getRecordingData(currentPage, 10, employeeIds, '')    //perpage
        setFromSummary(formattedStartDate)
        setToSummary(formattedEndDate)
        getSummaryData(employeeIds, '')
        setSelectedEmployeeOptions2(selectedEmployeeOptions);
        setCurrentPage(1)
        setPerPage(10);
    }

    //  getSummary api 
    const getSummaryData = useCallback(async (employeeIds = [], defaultId = []) => {
        setIsLoading(true);
        try {
            let data = {};
            if (defaultId.length > 0) {
                data = {
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    employeeId: defaultId,
                };
            } else {
                data = {
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    employeeId: employeeIds
                };
            }
            let response;
            if (loginType === 'employee') {
                response = await api.create(`${EMPLOYEE_GET_RECORDING_SUMMARY}`, data);
            } else if (loginType === "organization") {
                response = await api.create(`${GET_RECORDING_SUMMARY}`, data);
            }
            if (response && response.success === true) {
                setIsLoading(false);
                setShow(true);
                setRecordingSummary(response?.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }

    }, [formattedStartDate, formattedEndDate, loginType]);



    // Pagination handler
    const handlePageChange = (page) => {
        const employeeIds = selectedEmployeeOptions2?.id;
        getRecordingData(page, perPage, employeeIds, '')
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        const employeeIds = selectedEmployeeOptions2?.id;
        setPerPage(newPerPage);
        setCurrentPage(page);
        getRecordingData(page, newPerPage, employeeIds, '')
    };



    // getRecording api
    const getRecordingData = useCallback(async (page, size = perPage, employeeIds = [], defaultId = []) => {
        setIsLoading(true);
        try {
            let data = {};
            if (defaultId.length > 0) {
                data = {
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    employeeId: defaultId,
                };
            } else {
                data = {
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    employeeId: employeeIds
                };
            }
            let response;
            if (loginType === 'employee') {
                response = await api.create(`${EMPLOYEE_GET_RECORDING_DATA}?pg=${page}&lm=${size}`, data);
            } else if (loginType === "organization") {
                response = await api.create(`${GET_RECORDING_DATA}?pg=${page}&lm=${size}`, data);
            }
            if (response && response.success === true) {
                setIsLoading(false);
                const apiResponse = response.data;
                setShow(true);
                setTotalRows(apiResponse.totalRecordCount);
                const tableData = (apiResponse?.data || []).map(data => {
                    const duration = formatTime(data.duration); 
                    return {
                        employee: data.employeeDesription.fullName || 'unknown',
                        employeeNumber: data.employeeDesription.mobileNumber || '-',
                        clientName: data.name || 'unknown',
                        phoneNumber: data.phoneNumber || '-',
                        dateTime: moment(data.dateTime).format('DD MMM YYYY hh:mm A') || '-',
                        duration: duration || '-',
                        type: data.type || '-',
                        recording: data.callRecording || '-',
                        deleterecording: data._id || '-',
                        callRecording: data.callRecording || '-',
                        countryCodes:data?.employeeDesription.countryCode
                    };
                });
                setGetTableData(tableData);
            }

        } catch (error) {
            if (!remainingDays === 0) {
                HandleErrorMessage(error);
            }
        }
        finally {
            setIsLoading(false);
        }
    }, [selectedEmployeeOptions, selectedEmployeeOptions2, formattedStartDate, formattedEndDate, currentPage, EmployeeData, perPage]);


      const isWithinRange = (date) => {
        const today = new Date();
        return date >= selectedFromDate && date <= today;
      };
     
      const handleFromDateChange = (dates) => {
        const newFromDate = dates[0];
        setSelectedFromDate(newFromDate);
        const today = new Date();
        const threeMonthsAgo = addMonths(today, -3);
        if (newFromDate >= threeMonthsAgo && newFromDate <= today) {
          setSelectedToDate(today);
        } else {
          const newToDate = addMonths(newFromDate, 3);
          setSelectedToDate(newToDate);
        }
      };
     
      const handleToDateChange = (dates) => {
        const newToDate = dates[0];
        if (isWithinRange(newToDate)) {
          setSelectedToDate(newToDate);
        } else {
          console.warn("Selected date is out of range.");
        }
      };


    const createArchive = async () => {
        try {
            const employeeIds = selectedEmployeeOptions?.id;
            let data = {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                employeeId: employeeIds,
            };
            let response;
            if (loginType === 'employee') {
                response = await api.create(`${EMPLOYEE_CREATE_ARCHIVES}`, data);
            } else if (loginType === "organization") {
                response = await api.create(`${CREATE_ARCHIVES}`, data);
            }

            if (response && response.success === true) {
                Toast('Your Request Under Process. please check your file in Recordings Archives', "success");
            }
        } catch (error) {
            HandleErrorMessage(error);
        }

    }

    //DELETE ALL Recording
    const deleteAllRecording = async () => {
        try {
            const employeeIds = selectedEmployeeOptions?.id;
            const data = {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                employeeId: employeeIds,
            };
            const config = {
                method: 'delete',
                url: `${DELETE_ALL_RECORDING}`,
                data
            };
            const response = await axios(config);
            if (response && response.success === true) {
                Toast(response?.message, "success");
            }
        } catch (error) {
            HandleErrorMessage(error);
        }

    }

    //DELETE RECORDING
    const deleteRecording = async (dataDelete) => {
        try {
            let data = {
                callRecordingId: dataDelete
            };
            let config = {
                method: 'delete',
                url: `${DELETE_RECORDING}`,
                data
            };
            const response = await axios(config);
            if (response && response.success === true) {
                Toast(response?.message, "success");
                setTimeout(() => {
                    getRecordingData();
                }, 2000)
            }
        } catch (error) {
            HandleErrorMessage(error);
        }
    }


    const handleDownload = (row) => {
        toast.success('Your Request Under Process. please check your file in Recordings Archives', {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT
        });

        setTimeout(() => {
            const anchor = document.createElement('a');
            anchor.href = row.callRecording;
            anchor.target = '_blank';
            anchor.download = true;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

        }, 2000);
    };

    // dataTable
    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Employee</span>,
            selector: row => (
                <div className='mt-2 mb-2'>
                    <h5 className='font-weight-bold fs-13' style={{ textTransform: 'capitalize' }}>{row?.employee}</h5>
                    <h6 className='text-center- font-weight-bold fs-13'>{row?.countryCodes +`-`+ row?.employeeNumber}</h6>
                </div>
            ),
        },
        {
            name: <span className="font-weight-bold fs-13">Client Name</span>,
            selector: row => toCamelCase(row?.clientName),
            cell: row => (
                <div className='mt-2 mb-2'>
                    <h5 className='font-weight-bold fs-13' style={{ textTransform: 'capitalize' }}>{row?.clientName}</h5>
                    <h6 className='text-center- font-weight-bold fs-13'>{row?.phoneNumber}</h6>
                </div>
            ),
            sortable: true,
        },

        {
            name: <span className='font-weight-bold fs-13'>Call Type</span>,
            selector: (cell) => {
                switch (cell.type) {
                    case "OUTGOING":
                        return <> <div> <i className="mdi mdi-phone-outgoing sn-out-going-text"></i> <span className="badge badge-soft-info sn-out-going-call"> {cell.type}</span></div></>;
                    case "REJECTED":
                        return <><div> <i className="mdi mdi-phone-remove sn-out-rejected-text"></i><span className="badge badge-soft-warning sn-out-rejected-call"> {cell.type} </span></div></>;
                    case "MISSED":
                        return <><div><i className="mdi mdi-phone-cancel-outline sn-out-missed-text"></i> <span className="badge badge-soft-danger sn-out-missed-call"> {cell.type} </span></div></>;
                    case "INCOMING":
                        return <><div> <i className="mdi mdi-phone-incoming sn-out-incoming-text" style={{ marginLeft: '0' }}></i> <span className="badge badge-soft-success sn-out-incoming-call"> {cell.type} </span></div></>;
                    default:
                        return <span className="badge badge-soft-success"> {cell.type} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Play</span>,
            width: '350px',
            selector: row => (
                <>
                    <div style={{ display: 'inline-grid', marginBottom: '20px', marginTop: '10px' }}>
                        <span style={{ height: '10px' }}>{row.duration}</span>
                        <audio style={{ height: '40px', marginTop: '15px' }} src={row.recording} controls />
                    </div>
                </>
            ),
        },
        {
            name: <span className='font-weight-bold fs-13'>Date & Time</span>,
            selector: row => row.dateTime,
            sortable: true
        },
        ...(((deleteRecordingPermission || (CREATE_ARCHIVERecordingPermission && loginType === 'employee')) || (loginType === 'organization')) ? [
            {
                name: <span className='font-weight-bold fs-13'>Actions</span>,
                selector: row => (
                    <>
                        {/* {((CREATE_ARCHIVERecordingPermission && loginType === 'employee') || (loginType === 'organization')) && <a className="btn btn-sm button-grey-main edit-list" onClick={() => handleDownload(row)} download>
                            <i className="ri-download-2-line" />
                        </a>}&nbsp;&nbsp; */}
                        {((deleteRecordingPermission && loginType === 'employee') || (loginType === 'organization')) && <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => deleteRecording(row.deleterecording)}>
                            <i className="ri-delete-bin-5-fill align-bottom" />
                        </button>}
                    </>
                ),
            }] : []),


    ]


    // clear field in button 
    const handleReset = () => {
        setSelectedEmployeeOptions([]);
        setTeamIndu([]);
    }

    useEffect(() => {
        const calculateRemainingDays = () => {
            if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
                const endDate = new Date(profileData?.subscriptionEndDate);
                const startDate = new Date(profileData?.subscriptionStartDate);
                const currentDate = new Date();

                const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
                const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
                setRemainingDays(remainingDays > 0 ? remainingDays : 0);
                const interval = setInterval(() => {
                    const newCurrentDate = new Date();
                    const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
                    setRemainingDays(newRemainingDays);
                }, 1000 * 60 * 60 * 24);

                return () => clearInterval(interval);
            } else {
                setRemainingDays(null);
            }
        };

        calculateRemainingDays();
    }, [profileData]);
  
    useEffect(() => {
        const handler = setTimeout(() => {
          if (searchText.length >= 3) {
            setDebouncedSearchText(searchText);
          } else {
            setDebouncedSearchText(''); 
          }
        }, 300); 
    
        return () => {
          clearTimeout(handler); 
        };
      }, [searchText]);

     
      useEffect(() => {
        const filtered = (getTableData || []).filter((item) =>
          Object.values(item).some((value) =>
            value?.toString()?.toLowerCase()?.includes(debouncedSearchText.toLowerCase())
          )
        );
        setFilteredData(filtered);
        if (debouncedSearchText) setCurrentPage(1);
      }, [getTableData, debouncedSearchText]);

    const handleClose = () => {
        setIsExpiredModalOpen(false);
        navigate("/planPackage");
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {(remainingDays === 0 && isExpiredModalOpen) && <SubExpired onClose={handleClose} profileData={profileData?.selectedPlanDetails?.planName} remainingDays={remainingDays} />}
            <div className="page-content sn-recording-report-page">
                <Container fluid>
                    <BreadCrumb title='RECORDING REPORT' pageTitle="Call Recording" />
                    <Row>
                        <Col lg={12}>
                            <Card className='h-100'>
                                <CardBody>
                                    <Row className="sn-header-select-employess-home sn-top-inputs-heading">
                                        <div className="col-md-2 col-sm-12">
                                            <DatePicker
                                              labelContent="From Date"
                                              getDate={selectedFromDate}
                                              options={{
                                                disable: [
                                                  {
                                                    from: new Date(),
                                                  },
                                                ],
                                                dateFormat: "d M, Y",
                                                maxDate: new Date(
                                                  new Date().setDate(new Date().getDate())
                                                ),
                                              }}
                                              onChange={handleFromDateChange}
                                            />
                                        </div>
                                        <div className="col-md-2 col-sm-12">
                                            <DatePicker
                                             labelContent="To Date"
                                             getDate={selectedToDate}
                                             options={{
                                               dateFormat: "d M, Y",
                                               minDate: selectedFromDate,
                                               // maxDate: 'today',
                                               enable: [
                                                 {
                                                   from: selectedFromDate,
                                                   to: Math.min(
                                                     addMonths(selectedFromDate, 3),
                                                     new Date()
                                                   ),
                                                 },
                                               ],
                                               onOpen: () => {
                                                 const currentDate = new Date();
                                                 const threeMonthsFromStart = addMonths(
                                                   selectedFromDate,
                                                   3
                                                 );
                                                 if (threeMonthsFromStart > currentDate) {
                                                   return {
                                                     enable: [
                                                       {
                                                         from: selectedFromDate,
                                                         to: currentDate,
                                                       },
                                                     ],
                                                   };
                                                 }
                                               },
                                             }}
                                             onChange={handleToDateChange}
                                             />
                                        </div>
                                        <div className="col-md-3 col-sm-12 sn-header-select-employess-home">
                                            <TeamSelect setSelectedEmployeeOptions={setSelectedEmployeeOptions} teamIndu={teamIndu} setTeamIndu={setTeamIndu} labelContent='Select Team' />
                                        </div>
                                        <div className="col-md-2 col-sm-12">
                                            <SelectOne setEmployeeData={setEmployeeData} EmployeeData={EmployeeData} teamIndu={teamIndu} selectedEmployeeOptions={selectedEmployeeOptions} setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                                                labelContent='Select Employee' />
                                            <small className="salen-ninja-select-lble"><span>✬</span>You can select only one employee at a time</small>
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <FormGroup className="mt-2">
                                                <Label for="exampleSelect"></Label>
                                                <div id="exampleSelect" className="sn-call-apply-btn-reset">
                                                    <Button id="exampleSelect" type="submit" color="danger" className="" onClick={handleRecordingSubmit}>
                                                        Apply
                                                    </Button>
                                                    <Button className="" style={{ marginLeft: "10px" }} id="exampleSelect" outline color="danger" onClick={handleReset}>
                                                        Reset
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Row>

                                    {show === true ?
                                        <>
                                            <Row>
                                                <Col xxl={12} className="p-0">
                                                    {recordingSummary?.employeeName?.length > 0 ? <CardBody>
                                                        <div className="table-responsive bg-light">
                                                            <table className="table table-bordered table-nowrap align-middle mt-0 mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" style={{ textAlign: 'center', width: "30%" }}>{recordingSummary?.employeeName} <br /> {`+91 ${recordingSummary?.employeePhoneNumber}`}</th>
                                                                        <th scope="col" style={{ textAlign: 'center', width: "30%" }}>{fromSummaryDate || formattedStartDate} to {toSummaryDate || formattedEndDate} <br />Duration</th>
                                                                        <th scope="col" style={{ textAlign: 'center', width: "20%" }}>{recordingSummary?.totalStorage}<br /> Usage Space</th>
                                                                        <th scope="col" style={{ textAlign: 'center', width: "20%" }}>{recordingSummary?.numberOfRecordings} <br /> No. of File</th>
                                                                        <th scope="col" style={{ textAlign: 'center', width: "20%" }}>
                                                                            <button type="button" className="btn btn-icon text-muted btn-sm fs-18" onClick={createArchive}>
                                                                                <i className="ri-download-2-line">
                                                                                </i>
                                                                            </button>
                                                                        </th>
                                                                        <th scope="col" style={{ width: "20%" }}>
                                                                            <button type="button" className="btn btn-icon text-muted btn-sm fs-18" onClick={deleteAllRecording}>
                                                                                <i className=" ri-delete-bin-5-line"></i>
                                                                            </button>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    </CardBody> : ''}
                                                </Col>
                                            </Row>
                                            <Row className="sn-user-tble-manin-manage">
                                                <div className="salenninja-note-title">
                                                    <h5><span>*Note : </span>You can request to download all recording files from here. Check the Recording Archives to download the requested files.</h5>
                                                </div>
                                            </Row>

                                            <Row className="sn-user-tble-manin-manage">
                                                <Col lg={12} className="sn-team-managment-tble">
                                                    <CommanDataTable
                                                        setCurrentPage={setCurrentPage}
                                                        perPage={perPage}
                                                        handleSearch={handleSearch}
                                                        searchText={searchText}
                                                        column={columns} tableData={filterData}
                                                        handlePerRowsChange={handlePerRowsChange}
                                                        // isLoading={isLoading}
                                                        totalRows={totalRows} currentPage={currentPage}
                                                        handlePageChange={handlePageChange}
                                                        noData='There are no records to display'
                                                    />
                                                </Col>
                                            </Row>
                                        </> : ''}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CallRecordingReport