import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { NEVER_ATTENDED_REPORT } from "../../helpers/url_helper";
import CommanDataTable from "../../Components/Common/DataTable";
import moment from "moment";
import { useDispatch } from "react-redux";
import { DatePicker, DatePickerWithoutTime, DatePickerWithoutTimes } from "../../Components/Common/Flatpicker";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import { formatDate } from "../../Components/Common/FormDateTime";
import { APIClient, exportReport } from "../../helpers/api_helper";
import { getPreviousDate, setCurrentDate } from "../../Components/Common/DefaultDateSet";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import ModalNeverAttended from "../../Components/Common/ModalNeverAttended";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";
import SubExpired from "../../Components/Common/subExpired";
import loaderimgmnew from "../../assets/images/loadersnc.gif";
import { addMonths } from "../../Components/Common/FlatePicker";
import axios from "axios";
import { handleExport } from "../../Components/Common/validationSchema";

const api = new APIClient();

const NeverAttendedCall = () => {
  document.title = "Never Attended";
  const [EmployeeData, setEmployeeData] = useState([]);
  // permission type
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const viewReportPermission = permissionType?.includes("VIEW_REPORT");
  const loginType = JSON.parse(localStorage.getItem("logintype"));
  const BooleanWithSingleEmployeeLogin = ((loginType === "employee") && (permissionType.length === 0))

  const navigate = useNavigate();

  // profile data get in
  const { profileData } = useProfileData();


  // date pass in
  const previewsDate = getPreviousDate();
  const currentDate = setCurrentDate();

  // date picker state
  const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
  const [selectedFromDate_Test_, setSelectedFromDate_Test] = useState(previewsDate);

  const [selectedToDate, setSelectedToDate] = useState(currentDate);
  const [selectedToDate_Test, setSelectedToDate_Test] = useState(currentDate);

  const [selectedFromDateTwo, setSelectedFromDateTwo] = useState(selectedFromDate_Test_);
  const [selectedToDateTwo, setSelectedToDateTwo] = useState(selectedToDate_Test);


  const [EmployeeDefaultWithoutTeam, setEmployeeDefaultWithoutTeam] = useState([]);
  // leader state
  const [isLoading, setIsLoading] = useState(false);




  //expire plan state
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);

  //select team state


  // employee select
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  const [selectedEmployeeOptions2, setSelectedEmployeeOptions2] = useState([]);
  const [prevEmployeesIds_Test, setPrevEmployeesIds_Test] = useState([]);
  // Pagination settings
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // table data state
  const [neverAttendData, setNeverAttendData] = useState([]);
  const [remainingDays, setRemainingDays] = useState(null);

  const [handleSubmits, setHandleSubmit] = useState(false);

  // flatPicker Date
  let formattedStartDate;
  let formattedEndDate;
  if (!handleSubmits) {
    formattedStartDate = formatDate(selectedFromDateTwo);
    formattedEndDate = formatDate(selectedToDateTwo);
  } else {
    formattedStartDate = formatDate(selectedFromDate);
    formattedEndDate = formatDate(selectedToDate);
  }

  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [outgoingCall, setOutgoingCall] = useState([]);

  //search field state
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [isFirstEffectRun, setIsFirstEffectRun] = useState(false);
  const [error, setError] = useState("");

  const [teamIndu, setTeamIndu] = useState([]);

  // From Date

 const isWithinRange = (date) => {
   const today = new Date();
   return date >= selectedFromDate && date <= today;
 };

 const handleFromDateChange = (dates) => {
   const newFromDate = dates[0];
   setSelectedFromDate(newFromDate);
   const today = new Date();
   const threeMonthsAgo = addMonths(today, -3);
   if (newFromDate >= threeMonthsAgo && newFromDate <= today) {
     setSelectedToDate(today);
   } else {
     const newToDate = addMonths(newFromDate, 3);
     setSelectedToDate(newToDate);
   }
 };

 const handleToDateChange = (dates) => {
   const newToDate = dates[0];
   if (isWithinRange(newToDate)) {
     setSelectedToDate(newToDate);
   } else {
     console.warn("Selected date is out of range.");
   }
 };

  useEffect(() => {
    const calculateRemainingDays = () => {
      if (
        profileData?.subscriptionEndDate &&
        profileData?.subscriptionStartDate
      ) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );
        const remainingDays = Math.ceil(
          (endDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil(
            (endDate - newCurrentDate) / (1000 * 60 * 60 * 24)
          );
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);


  const column = [
    {
      name: <span className="font-weight-bold fs-13"> SN.</span>,
      width: "6%",
      selector: (row, index) => index + 1,
      sortable: true, // This sets the width of the "Sr. No" column to 30px
    },

    {
      name: (
        <span
          className="font-weight-bold fs-13 text-center fs-2"
          style={{ textTransform: "capitalize" }}
        >
          Employee
        </span>
      ),
      selector: (row) => row?.fullName,
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.fullName}
          </h5>
          <h6 className="text-center- font-weight-bold fs-13">
            {row?.countryCode + `-` + row?.mobileNumber}
          </h6>
        </div>
      ),
      sortable: true,
    },


    {
      name: (
        <span
          className="font-weight-bold fs-13 text-center fs-2 fs-2"
          style={{ textTransform: "capitalize" }}
        >
          Client Name
        </span>
      ),
      selector: (row) => row?.name,
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.name}
          </h5>
          <h6 className="text-center- font-weight-bold fs-13">
            {row?.phoneNumber}
          </h6>
        </div>
      ),
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13 fs-2">Date</span>,
      selector: (row) => row?.date,
      cell: (row) => (
        <div className="timeSet">
          {row?.date?.map((callDate, index) => (
            <React.Fragment key={index}>
              <span>{moment(callDate.date).format("DD MMM YYYY")}</span>
              <br />
              {index !== row.date.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      ),
      sortable: true,
    },


    {
      name: <span className="font-weight-bold fs-13 fs-2">Time</span>,
      selector: (row) => (
        <div className="timeSet">
          {row?.time?.map((callTime, index) => {
            const momentObj = moment(callTime.date).format("hh:mm A");
            return (
              <React.Fragment key={index}>
                <span className="gap-2">{momentObj}</span>
                <br />
                {index !== row.time.length - 1 && <br />}
              </React.Fragment>
            );
          })}
        </div>
      ),
      // sortable: true
    },

    {
      name: <span className="font-weight-bold fs-13 fs-2">Call Type</span>,
      selector: (row) => (
        <div className="timeSet">
          {row?.dataAndType?.every(
            (callType) =>
              callType?.type !== "MISSED" && callType?.type !== "REJECTED"
          ) ? (
            <span style={{ textAlign: "center" }}>-</span>
          ) : (
            row.type.map((callType, index) => {
              let content;
              switch (callType.type) {
                case "OUTGOING":
                  content = (
                    <>
                      <div className="sn-call-type-newer-call">
                      <i className="mdi mdi-phone-outgoing sn-out-going-text" />
                      <span className="badge badge-soft-info sn-out-going-call">
                          {callType.type}
                        </span>
                      </div>
                    </>
                  );
                  break;
                case "MISSED":
                  content = (
                    <>
                      <div className="sn-call-type-newer-call">
                        <i className="mdi mdi-phone-missed sn-out-missed-text sn-never-attended-icon-mm" />
                        <span className="gap-2 sn-out-missed-call">
                          {callType.type}
                        </span>
                      </div>
                    </>
                  );
                  break;
                case "REJECTED":
                  content = (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "medium",
                        fontWeight: "bold",
                        color: "#e97dfb",
                      }}
                    >
                      <div className="sn-call-type-newer-call">
                        <i className="ri-indeterminate-circle-line rejected-icon-never" />
                        <span
                          className="gap-2 sn-out-missed-call"
                          style={{
                            color: "#e97dfb",
                            background: "rgb(233 125 251 / 10%)",
                          }}
                        >
                          {callType.type}
                        </span>
                      </div>
                    </div>
                  );
                  break;
                default:
                  content = null; // No content for other types
              }

              return (
                <React.Fragment key={index}>
                  {content}
                  {index !== row.type.length - 1 && <br />}
                </React.Fragment>
              );
            })
          )}
        </div>
      ),
    },

    {
      name: (
        <span
          className="font-weight-bold fs-13 text-center fs-2 fs-2"
          style={{ textTransform: "capitalize" }}
        >
          Attempt to Call
        </span>
      ),
      selector: (row) => {
        return (
          <>
            <h5
              className="font-weight-bold fs-13"
              style={{
                textTransform: "capitalize",
                color: "blue",
                fontWeight: "900",
                cursor: "pointer",
              }}
              onClick={() => {
                if (row.attempt > 0) {
                  showModal(row);
                }
              }}
            >
              <span
                style={{
                  color: row.attempt > 0 ? "inherit" : "red",
                  cursor: "inherit",
                }}
              >
                {row.attempt > 0 ? `${row.attempt} Times` : "Not Attempted"}
              </span>
            </h5>
          </>
        );
      },
      sortable: true,
    },
  ];


  const handleSearch = (e) => {
    setQuery(e.target.value);
  };





  useEffect(() => {
    if (teamIndu) {
      if (!isFirstEffectRun && selectedEmployeeOptions?.length > 0) {
        // let updatedIds = selectedEmployeeOptions?.map((userid) => userid?.id);
        let updatedIds = prevEmployeesIds_Test.length > 0 ? prevEmployeesIds_Test : selectedEmployeeOptions2?.map((option) => option?.id) || [];
        if (updatedIds.length > 0) {
          let dateObj = {
            startDate: formatDate(selectedFromDateTwo),
            endDate: formatDate(selectedToDateTwo)
          }
          neverAttendedData(currentPage, 10, "", updatedIds, dateObj);
        }
        setIsFirstEffectRun(true); // Mark as executed
        setPrevEmployeesIds_Test(updatedIds)
      }
    } else {
      let updatedIds = prevEmployeesIds_Test.length > 0 ? prevEmployeesIds_Test : EmployeeDefaultWithoutTeam?.map((option) => option?.id) || [];
      if (updatedIds.length > 0) {
        let dateObj = {
          startDate: formatDate(selectedFromDateTwo),
          endDate: formatDate(selectedToDateTwo)
        }
        neverAttendedData(currentPage, 10, "", updatedIds, dateObj);
      }
      setIsFirstEffectRun(true); // Mark as executed
      setPrevEmployeesIds_Test(updatedIds)
    }
  }, [selectedEmployeeOptions]);


  // table data
  const handleSubmit = async () => {
    if (BooleanWithSingleEmployeeLogin) {
      let dateObj = {
        startDate: formatDate(selectedFromDate),
        endDate: formatDate(selectedToDate)
      }
      neverAttendedData(currentPage, 10, '', profileData._id, dateObj);
      setCurrentPage(1)
      setPerPage(10);
    } else {
      if (selectedEmployeeOptions.length === 0) {
        setError("You must select at least one");
      } else {
        let updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
        setPrevEmployeesIds_Test(updatedIds);
        let dateObj = {
          startDate: formatDate(selectedFromDate),
          endDate: formatDate(selectedToDate)
        }
        neverAttendedData(currentPage, 10, '', updatedIds, dateObj);
        setSelectedEmployeeOptions2(selectedEmployeeOptions);
        setCurrentPage(1)
        setPerPage(10);
        setError("");
        setHandleSubmit(true);
      }
    }

  };

  // Debounce search query

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
      setCurrentPage(1)
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);


  useEffect(() => {
    if (BooleanWithSingleEmployeeLogin) {
      loginEmplolye()
      return;
    } else {
      if (isFirstEffectRun === true) {
        let updatedIds = prevEmployeesIds_Test.length > 0 ? prevEmployeesIds_Test : selectedEmployeeOptions2?.map((option) => option?.id) || [];
        const fetchData = async () => {
          if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
            let dateObj = {
              startDate: formatDate(selectedFromDateTwo),
              endDate: formatDate(selectedToDateTwo)
            }
            await neverAttendedData(currentPage, perPage, debouncedQuery, updatedIds, dateObj);
          }
        };

        fetchData();
      }
    }

    async function loginEmplolye() {
      let dateObj = {
        startDate: formatDate(selectedFromDate),
        endDate: formatDate(selectedToDate)
      }
      await neverAttendedData(currentPage, perPage, debouncedQuery, profileData._id, dateObj);
    }
  }, [debouncedQuery, currentPage, perPage]);



  const showModal = async (row) => {
    setOutgoingCall(row);
    setmodal_animationFlip(true);
  };

  // Pagination handler
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };


  const neverAttendedData = async (page, size = perPage, searchTerm = '', updatedIds, dateObj) => {
    setIsLoading(true);
    try {
      if (!updatedIds) {
        updatedIds = selectedEmployeeOptions2.map((userid) => userid?.id);
      }
      let data = {
        startDate: formatDate(selectedFromDate),
        endDate: formatDate(selectedToDate),
        employeeIds: updatedIds
      };
      const response = await api.create(`${NEVER_ATTENDED_REPORT}?pg=${page}&lm=${size}&query=${searchTerm}`, data);
      if (response && response?.success === true) {
        setIsLoading(false);
        const apiResponse = response?.data[0];
        const tableData = (apiResponse?.paginatedResults || [])?.map(
          (data, index) => ({
            name: data.name || "unknown",
            mobileNumber: data.empDescription[0]?.mobileNumber || "-",
            phoneNumber: data._id.phoneNumber || "-",
            fullName: `${data.empDescription[0]?.fullName || "unknown"}`,
            attempt: data.attempt || '-',
            date: data?.dataAndType || "-",
            time: data.dataAndType || "-",
            type: data.dataAndType || "-",
            countryCode: data?.empDescription[0]?.countryCode
          })
        );
        setNeverAttendData(tableData);
        setTotalRows(apiResponse?.totalCount);
      }
    } catch (error) {
      if (!remainingDays === 0) {
        HandleErrorMessage(error);
      }
    } finally {
      setIsLoading(false);
    }
  };


  // clear field
  const resetInputHistory = () => {
    setSelectedToDate("");
    setSelectedFromDate("");
    setSelectedEmployeeOptions([]);
    setTeamIndu([]);
  };

  // expire plan  function
  const handleClose = () => {
    setIsExpiredModalOpen(false);
    navigate("/planPackage");
  };

  //export in excel 
  const handleExcelExport = async () => { 
    const updatedIds = selectedEmployeeOptions2.map((userid) => userid?.id);
    await handleExport({type: "excel", apiUrl: `${NEVER_ATTENDED_REPORT}`,  startDate: selectedFromDate,endDate: selectedToDate,employeeIds: updatedIds,fileName: "NeverAttended.xlsx"});
  };

  //export in pdf 
  const handlePDFExport = async () => { 
    const updatedIds = selectedEmployeeOptions2.map((userid) => userid?.id);
    await handleExport({type: "pdf", apiUrl: `${NEVER_ATTENDED_REPORT}`,  startDate: selectedFromDate,endDate: selectedToDate,employeeIds: updatedIds,fileName: "NeverAttended.pdf"});
  };
 
  return (
    <React.Fragment>
      {remainingDays === 0 && isExpiredModalOpen && (
        <SubExpired
          onClose={handleClose}
          profileData={profileData?.selectedPlanDetails?.planName}
          remainingDays={remainingDays}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Never Attended" pageTitle="Never Attended" />
          <Row>
            <Col lg={12}>
              <Card className="h-100">
                <CardBody>
                  <Row className="sn-header-select-employess-home sn-top-inputs-heading">
                    <div className="col-md-2 col-sm-12">

                      <DatePicker
                        labelContent="From Date"
                        getDate={selectedFromDate}
                        options={{
                          disable: [
                            {
                              from: new Date(),
                            },
                          ],
                          dateFormat: "d M, Y",
                          maxDate: new Date(
                            new Date().setDate(new Date().getDate())
                          ),
                        }}
                        onChange={handleFromDateChange}
                      />
                    </div>
                    <div className="col-md-2 col-sm-12">
                      <DatePicker
                        labelContent="To Date"
                        getDate={selectedToDate}
                        options={{
                          dateFormat: "d M, Y",
                          minDate: selectedFromDate,
                          // maxDate: 'today',
                          enable: [
                            {
                              from: selectedFromDate,
                              to: Math.min(
                                addMonths(selectedFromDate, 3),
                                new Date()
                              ),
                            },
                          ],
                          onOpen: () => {
                            const currentDate = new Date();
                            const threeMonthsFromStart = addMonths(
                              selectedFromDate,
                              3
                            );
                            if (threeMonthsFromStart > currentDate) {
                              return {
                                enable: [
                                  {
                                    from: selectedFromDate,
                                    to: currentDate,
                                  },
                                ],
                              };
                            }
                          },
                        }}
                        onChange={handleToDateChange}
                      />
                    </div>
                    {((viewReportPermission && loginType === "employee") ||
                      loginType === "organization") && (
                        <div className="col-md-2 col-sm-12">
                          <TeamSelect
                            setSelectedEmployeeOptions={
                              setSelectedEmployeeOptions
                            }
                            teamIndu={teamIndu}
                            setTeamIndu={setTeamIndu}
                            labelContent="Select Team"
                          />
                        </div>
                      )}
                    {((viewReportPermission && loginType === "employee") ||
                      loginType === "organization") && (
                        <div className="col-md-3 col-sm-12">
                          <EmployeeSelect
                            setEmployeeDefaultWithoutTeam={setEmployeeDefaultWithoutTeam}
                            EmployeeDefaultWithoutTeam={EmployeeDefaultWithoutTeam}
                            error={error}
                            setError={setError}
                            EmployeeDefault={teamIndu.length === 0 ? EmployeeDefaultWithoutTeam : EmployeeData}
                            setEmployeeDefault={setEmployeeData}
                            teamIndu={teamIndu}
                            selectedEmployeeOptions={selectedEmployeeOptions}
                            setSelectedEmployeeOptions={
                              setSelectedEmployeeOptions
                            }
                            setSelectedEmployeeOptions2={
                              setSelectedEmployeeOptions2
                            }
                            labelContent="Select Employee"
                          />
                        </div>
                      )}
                    <div className="col-md-3 col-sm-12">
                      <FormGroup className="mt-2">
                        <Label for="exampleSelect"></Label>
                        <div
                          id="exampleSelect"
                          className="sn-call-apply-btn-reset"
                        >
                          <Button
                            id="exampleSelect"
                            type="submit"
                            color="danger"
                            className=""
                            onClick={handleSubmit}
                          // disabled={isLoading}
                          >
                            Apply
                          </Button>
                          <Button
                            className=""
                            style={{ marginLeft: "10px" }}
                            id="exampleSelect"
                            outline
                            color="danger"
                            onClick={resetInputHistory}
                            disabled={isLoading}
                          >
                            Reset
                          </Button>
                        </div>
                      </FormGroup>
                    </div>
                  </Row>

                  <Row className="sn-team-managment-tble sn-user-tble-manin-manage">
                
                    <Col lg={12}>
                    {/* {neverAttendData?.length > 0 ?<div className="sales-ninja-tble-export-btn call-logs-report-export-exel" style={{ position: 'relative' }}>
                        {" "}
                        <Button
                          onClick={handleExcelExport}
                          className="custom-button tble-export1"
                        >
                          <i className="ri-file-excel-line"></i>
                        </Button>{" "}
                        &nbsp;
                        <Button
                          onClick={handlePDFExport}
                          className="custom-button ml-2 tble-export2"
                        >
                          <i className="ri-file-pdf-line"></i>
                        </Button>
                      </div>:''} */}
                      <CommanDataTable
                        setCurrentPage={setCurrentPage}
                        perPage={perPage}
                        handleSearch={handleSearch}
                        column={column}
                        searchText={query}
                        tableData={neverAttendData}
                        handlePerRowsChange={handlePerRowsChange}
                        totalRows={totalRows}
                        currentPage={currentPage}
                        isLoading={isLoading}
                        handlePageChange={handlePageChange}
                        inputClass={
                          (neverAttendData && neverAttendData.length > 0) ||
                            query
                            ? "d-block"
                            : "d-none"
                        }
                      />
                    </Col> 
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ModalNeverAttended
            outgoingCall={outgoingCall}
            modal_animationFlip={modal_animationFlip}
            setmodal_animationFlip={setmodal_animationFlip}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NeverAttendedCall;