import {
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBER_REQUEST,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,
  RESET_SUCCESS_STATE,
  DELETE_TEAM_DATA_SUCCESS,
  DELETE_TEAM_DATA_FAIL,
} from "./actionType";

const initialState = {
  team: [],
  loading: false,
  error: null,
  success: false,
};

const teamReducer = (state = initialState, action) => {

  
  switch (action.type) {
    case ADD_TEAM_MEMBER_REQUEST:
    case UPDATE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        team: [...state.team, action.payload],
        success: true,
      };
    case ADD_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_TEAM_DATA_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
      };
    case DELETE_TEAM_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_TEAM_MEMBER_SUCCESS: {
      const updatedTeam = action.payload;
      return {
        ...state,
        loading: false,
        team: [...state.team, updatedTeam],
        success: true,
        error: null,
      };
    }
    case UPDATE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

    case RESET_SUCCESS_STATE:
      return {
        ...state,
        success: false,
      };
    default:
      return state;
  }
};

export default teamReducer;
