import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import CommanDataTable from "../../Components/Common/DataTable";
import { DELETE_STORAGE_API } from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { formatDate } from "../../Components/Common/FormDateTime";
import DeleteModal from "../../Components/Common/DeleteModal";
import moment from "moment";
 
const api = new APIClient();
 
const StorageManagement = ({
  filterData: initialFilterData,
  isLoading,
  setCurrentPage,
  setPerPage,
  currentPage,
  perPage,
  formattedStartDate,
  formattedEndDate,
}) => {
  const [query, setQuery] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(initialFilterData || []);
  const [deleteModal, setDeleteModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
 
  useEffect(() => {
    setFilteredData(initialFilterData);
  }, [initialFilterData]);
 
  useEffect(() => {
    const handler = setTimeout(() => {
      if (query.length >= 3) {
        setDebouncedSearchText(query);
      } else {
        setDebouncedSearchText("");
      }
    }, 300);
 
    return () => clearTimeout(handler);
  }, [query]);
 
  useEffect(() => {
    const filtered = (initialFilterData || []).filter((item) =>
      Object.values(item).some((value) =>
        value?.toString()?.toLowerCase()?.includes(debouncedSearchText.toLowerCase())
      )
    );
    setFilteredData(filtered);
    if (debouncedSearchText) setCurrentPage(1);
  }, [initialFilterData, debouncedSearchText]);
 
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
 
  const openDeleteModal = (employeeId) => {
    setEmployeeToDelete(employeeId);
    setDeleteModal(true);
  };
 
  const confirmDelete = async () => {
    const data = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      employeeIds: [employeeToDelete],
    };
 
    try {
      const response = await api.delete(DELETE_STORAGE_API, { data });
      if (response.success) {
        setFilteredData((prevData) =>
          prevData.filter((item) => item.id !== employeeToDelete)
        );
        toast.success("Employee deleted successfully!");
      } else {
        toast.error(response.message || "Failed to delete employee.");
      }
    } catch (error) {
      console.error("Delete error:", error);
      toast.error("An error occurred while deleting the employee.");
    } finally {
      setDeleteModal(false);
      setEmployeeToDelete(null);
    }
  };
 
  const columnsStorageData = [
    {
      name: <span className="font-weight-bold fs-13">S No.</span>,
      selector: (row, index) => index + 1 + (currentPage - 1) * perPage,
      sortable: false,
      width: "80px",
    },
    {
      name: <span className="font-weight-bold fs-13">Employee Name</span>,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {row.profileImageUrl ? (
            <img
              src={row.profileImageUrl}
              alt="Profile"
              className="rounded-circle mr-2"
              style={{
                width: "35px",
                height: "35px",
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              className="rounded-circle d-flex justify-content-center align-items-center bg-primary text-white font-weight-bold mr-2"
              style={{
                width: "35px",
                height: "35px",
              }}
            >
              {row.employeeName?.charAt(0).toUpperCase() || "?"}
            </div>
          )}
          <span className="ms-2">{row.employeeName || "Unknown"}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Team Name</span>,
      selector: (row) => row.teamName || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Usage Space</span>,
      selector: (row) => row.usageSpace || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Recording Sync</span>,
      selector: (row) => moment(row?.lastRecordSync).format("DD MMM YYYY hh:mm:ss A") || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      cell: (row) => (
        <i
          className="ri-delete-bin-5-fill text-danger font-weight-bold"
          style={{ cursor: "pointer" }}
          onClick={() => openDeleteModal(row?.id)}
        />
      ),
      sortable: false,
    },
  ];
 
  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card className="shadow-lg p-3 rounded">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="card-title mb-0">Employee Wise Usage</h4>
            </div>
            <div className=".sn-storage-month-tble-first">
            <CommanDataTable
              column={columnsStorageData}
              tableData={filteredData}
              totalRows={filteredData.length}
              handlePerRowsChange={setPerPage}
              handlePageChange={setCurrentPage}
              perPage={perPage}
              currentPage={currentPage}
              handleSearch={handleSearch}
              query={query}
            />
            </div>
          </Card>
        </Col>
      </Row>
 
      <DeleteModal
        show={deleteModal}
        onDeleteClick={confirmDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
    </div>
  );
};
 
export default StorageManagement;