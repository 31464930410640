import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import CreateRoleManagement from "./CreateRoleManagement";
import axios from "axios";
import {
  DELETE_ROLE_MANAGEMENT,
  GET_ROLE_MANAGEMENT,
} from "../../../helpers/url_helper";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import UpdateRoleManagement from "./UpdateRoleManagement";
import moment from "moment";
import DeleteModal from "../../../Components/Common/DeleteModal";
import SimpleBar from "simplebar-react";
import CommanDataTable from "../../../Components/Common/DataTable";
import { APIClient } from "../../../helpers/api_helper";
import Toast from "../../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
import PermissionsTree from "./treeCustom";
import { toCamelCase } from "../../../Components/Common/ToCamelCase";
import SubExpired from "../../../Components/Common/subExpired";
import { useProfileData } from "../../../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";
import { DashboardDataTable } from "../../../Components/Common/DashboardDataTbale";

const api = new APIClient();

const RoleManagement = () => {
  document.title = "Role Management";
  const navigate = useNavigate();
  const { profileData } = useProfileData();

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [getRoleData, setGetRoleData] = useState([]);
  const [createmodal_grid, setCreatemodal_grid] = useState(false);
  const [updateModal_grid, setUpdateModal_grid] = useState(false);
  const [getUpdateRole, setGetUpdateRole] = useState();
  const [isRight, setIsRight] = useState(false);
  const [remainingDays, setRemainingDays] = useState(null);
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);
  const [todo, setTodo] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rolewiseData, setRoleWiseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // Fetch role management data with query
  const getRoleManagementData = async (query = "") => {
    setIsLoading(true);
    try {
      const response = await api.get(`${GET_ROLE_MANAGEMENT}?query=${query}`);
      if (response.success) {
        setGetRoleData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRoleManagementData();
  }, []);

  useEffect(() => {
    const calculateRemainingDays = () => {
      if (
        profileData?.subscriptionEndDate &&
        profileData?.subscriptionStartDate
      ) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );
        const remainingDays = Math.ceil(
          (endDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil(
            (endDate - newCurrentDate) / (1000 * 60 * 60 * 24)
          );
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);

  // Debounce for search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  // Fetch data when debounced query changes
  useEffect(() => {
    if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
      getRoleManagementData(debouncedQuery);
    }
  }, [debouncedQuery]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // ADD ROLE
  const createModal = () => {
    setCreatemodal_grid(true);
  };

  const updateModal = (updateData) => {
    setUpdateModal_grid(true);
    setGetUpdateRole(updateData);
  };

  // Delete role
  const onClickTodoDelete = (todo) => {
    setTodo(todo);
    setDeleteModal(true);
  };

  const handleDeleteTodo = async () => {
    if (todo) {
      try {
        let data = { hierarchyName: todo };
        let config = {
          method: "delete",
          url: `${DELETE_ROLE_MANAGEMENT}`,
          data,
        };
        const response = await axios(config);
        if (response.success) {
          Toast(response?.message, "success");
          setTimeout(() => {
            setDeleteModal(false);
            getRoleManagementData(debouncedQuery);
          }, 2000);
        }
      } catch (error) {
        HandleErrorMessage(error);
      }
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13"> SN.</span>,
      width: "55px",
      selector: (row, index) => index + 1,
    },
    {
      name: <span className="font-weight-bold fs-13">Role Name</span>,
      selector: (row) => toCamelCase(row.name),
      cell: (row) => (
        <span style={{ textTransform: "capitalize" }}>{row.name}</span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Created Date</span>,
      selector: (row) => moment(row.createdAt).format("DD  MMM  YYYY"),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      selector: (row) => (
        <>
          <span className="sn-role-managment-responsive-btn">
            <button
              className="btn btn-sm btn-soft-danger remove-list"
              onClick={() => onClickTodoDelete(row.name)}
            >
              <i className="ri-delete-bin-5-fill align-bottom" />
            </button>
            &nbsp;&nbsp;
            <button
              className="btn btn-sm btn-soft-success edit-list"
              onClick={() => updateModal(row.name)}
            >
              <i className="ri-pencil-fill align-bottom" />
            </button>
            &nbsp;&nbsp;
            <button
              className="btn btn-sm btn-soft-info edit-list"
              onClick={() => toggleRightCanvas(row)}
            >
              <i className="ri-user-follow-line align-bottom" />
            </button>
          </span>
        </>
      ),
    },
  ];

  const toggleRightCanvas = (data) => {
    setRoleWiseData(data);
    setIsRight(!isRight);
  };

  // useEffect(() => {
  //   const filtered = (getRoleData || []).filter(item =>
  //     Object.values(item).some(value =>
  //       value.toString().toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   );
  //   setFilteredData(filtered);
  // }, [getRoleData, searchText]);

  const handleClose = () => {
    setIsExpiredModalOpen(false);
    navigate("/planPackage");
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {remainingDays === 0 && isExpiredModalOpen && (
        <SubExpired
          onClose={handleClose}
          profileData={profileData?.selectedPlanDetails?.planName}
          remainingDays={remainingDays}
        />
      )}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content sn-role-managment-page">
        <Container fluid>
          <BreadCrumb title="Role Management" pageTitle="Tables" />
          <Row>
            <Col lg={12}>
              <Card className="h-100">
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <Button color="danger" onClick={createModal}>
                    <i className="ri-add-fill me-1 align-bottom"></i>Add Role
                  </Button>
                  {/* The search input is now part of the existing DataTable */}
                </CardHeader>
                <CardBody className="sn-team-managment-tble sn-role-managment-tble-sc mt-0 sn-role-managment-tble-scroll-ui">
                  {/* <CommanDataTable
                    handleSearch={handleSearch} // Pass the search handler to DataTable
                    column={columns}
                    tableData={getRoleData}
                    isLoading={isLoading}
                  /> */}
                  <DashboardDataTable
                    currentPage={currentPage}
                    column={columns}
                    fixedHeaderScrollHeights="480px"
                    tableData={getRoleData}
                    className="mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
            id="offcanvasRight"
            className="border-bottom"
            style={{ width: "700px" }}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              id="offcanvasRightLabel"
            >
              Set Permissions
            </OffcanvasHeader>
            <OffcanvasBody className="p-0 pb-4 overflow-auto">
              <SimpleBar>
                <PermissionsTree
                  rolewiseData={rolewiseData}
                  setIsRight={setIsRight}
                  getRoleManagementData={getRoleManagementData}
                />
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>

          <CreateRoleManagement
            getRoleManagementData={getRoleManagementData}
            createmodal_grid={createmodal_grid}
            setCreatemodal_grid={setCreatemodal_grid}
          />
          <UpdateRoleManagement
            getRoleManagementData={getRoleManagementData}
            getUpdateRole={getUpdateRole}
            updateModal_grid={updateModal_grid}
            setUpdateModal_grid={setUpdateModal_grid}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RoleManagement;
