import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { NOT_PICKED_REPORT } from "../../helpers/url_helper";
import CommanDataTable from "../../Components/Common/DataTable";
import moment from "moment";
import { DatePicker, DatePickerWithoutTime, DatePickerWithoutTimes } from "../../Components/Common/Flatpicker";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import { formatDate } from "../../Components/Common/FormDateTime";
import { getTeamEmployeeIncludeApi } from "../../store/dashboardCallCrm/getTeamEmployee/action";
import { useDispatch } from "react-redux";
import { APIClient, exportReport } from "../../helpers/api_helper";
import {
  getPreviousDate,
  setCurrentDate,
} from "../../Components/Common/DefaultDateSet";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";
import SubExpired from "../../Components/Common/subExpired";
import { addMonths } from "../../Components/Common/FlatePicker";
import { update } from "lodash";
import { handleExport } from "../../Components/Common/validationSchema";


const api = new APIClient();

const NeverPickepReport = () => {
  document.title = "Not Picked Up by Client";
  const [EmployeeData, setEmployeeData] = useState([]);
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const viewReportPermission = permissionType?.includes("VIEW_REPORT");
  const loginType = JSON.parse(localStorage.getItem("logintype"));
  const BooleanWithSingleEmployeeLogin = ((loginType === "employee") && (permissionType.length === 0))

  const { profileData } = useProfileData();
  const navigate = useNavigate();


  const [isLoading, setIsLoading] = useState(false);
  const previewsDate = getPreviousDate();
  const currentDate = setCurrentDate();

  //search field state
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  // date picker state
  const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
  const [selectedFromDate_Test, setSelectedFromDate_Test] = useState(previewsDate);
  const [selectedToDate, setSelectedToDate] = useState(currentDate);
  const [selectedToDate_Test, setSelectedToDate_Test] = useState(currentDate);
  const [EmployeeDefaultWithoutTeam, setEmployeeDefaultWithoutTeam] = useState([]);
  const [selectedFromDateTwo, setSelectedFromDateTwo] = useState(selectedFromDate_Test);
  const [selectedToDateTwo, setSelectedToDateTwo] = useState(selectedToDate_Test);
  //select team state
  const [teamIndu, setTeamIndu] = useState([]);

  // employee select
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  const [selectedEmployeeOptions2, setSelectedEmployeeOptions2] = useState([]);
  const [prevEmployeesIds_Test, setPrevEmployeesIds_Test] = useState([]);

  // Pagination settings
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("")
  // table data state
  const [notPickupedData, setNotPickupedData] = useState()

  const [remainingDays, setRemainingDays] = useState(null);

  // flatPicker Date
  const [handleSubmits, setHandleSubmit] = useState(false);
  const [isFirstEffectRun, setIsFirstEffectRun] = useState(false);

 const isWithinRange = (date) => {
   const today = new Date();
   return date >= selectedFromDate && date <= today;
 };

 const handleFromDateChange = (dates) => {
   const newFromDate = dates[0];
   setSelectedFromDate(newFromDate);
   const today = new Date();
   const threeMonthsAgo = addMonths(today, -3);
   if (newFromDate >= threeMonthsAgo && newFromDate <= today) {
     setSelectedToDate(today);
   } else {
     const newToDate = addMonths(newFromDate, 3);
     setSelectedToDate(newToDate);
   }
 };

 const handleToDateChange = (dates) => {
   const newToDate = dates[0];
   if (isWithinRange(newToDate)) {
     setSelectedToDate(newToDate);
   } else {
     console.warn("Selected date is out of range.");
   }
 };

  useEffect(() => {
    const calculateRemainingDays = () => {
      if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
        const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);


  // useEffect(() => {
  //   let teamType = teamIndu?.map(item => item?.value) || [];
  //   setPerPage(10);
  //   if (teamType?.length > 0) {
  //     const newTeam = { teamName: teamType };
  //     dispatch(getTeamEmployeeIncludeApi(newTeam));
  //   }
  // }, [teamIndu, dispatch])


  const column = [
    {
      name: <span className="font-weight-bold fs-13"> SN.</span>,
      width: "5%",
      selector: (row, index) => index + 1,
      sortable: true, // This sets the width of the "Sr. No" column to 30px
    },
    {
      name: (
        <span
          className="font-weight-bold fs-13 text-center fs-2 fs-2"
          style={{ textTransform: "capitalize" }}
        >
          Employee
        </span>
      ),
      selector: (row) => row?.employeeName,
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.employeeName}
          </h5>
          <h6 className="text-center- font-weight-bold fs-13">
            {row?.countryCode + `-` + row?.employeeNumber}
          </h6>
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13 text-center fs-2 fs-2">
          Client Name
        </span>
      ),
      selector: (row) => row?.clientName,
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.clientName}
          </h5>
          <h6 className="text-center- font-weight-bold fs-13">
            {row?.phoneNumber}
          </h6>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13 fs-2">Date</span>,
      selector: (row) => row.dt,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13 fs-2">Time</span>,
      selector: (row) => row?.tm,
      sortable: true
    },
    // {
    //     name: <span className='font-weight-bold fs-13 text-center fs-2'>Duration</span>,
    //     selector: row => row.duration,
    //     sortable: true
    // },
    {
      name: <span className="font-weight-bold fs-13 fs-2">Call Type</span>,
      selector: (row) => (
        <div>
          <i className="mdi mdi-phone-outgoing sn-out-going-text" />{" "}
          <span className="text-warning gap-2 sn-out-going-call">
            {row.typ}
          </span>
        </div>
      ),
    },
    {
      name: (
        <span className="font-weight-bold fs-13 text-center fs-2">Note</span>
      ),
      selector: (row) => row.note,
    },
    // {
    //     name: <span className='font-weight-bold fs-13 text-center fs-2'>Recording Status</span>,
    //     selector: row => <span className="text-info gap-2">{row.recordingStatus}</span>,
    // },
  ];



  // subscription plan expire code  
  useEffect(() => {
    const calculateRemainingDays = () => {
      if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
        const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);

  // search function
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    if (teamIndu) {
      if (!isFirstEffectRun && selectedEmployeeOptions?.length > 0) {
        let updatedIds = prevEmployeesIds_Test.length > 0 ? prevEmployeesIds_Test : selectedEmployeeOptions2?.map((option) => option?.id) || [];
        if (updatedIds.length > 0) {
          let dateObj = {
            startDate: formatDate(selectedFromDateTwo),
            endDate: formatDate(selectedToDateTwo)
          }
          notPickedData(currentPage, 10, "", updatedIds, dateObj);
        }
        setIsFirstEffectRun(true); // Mark as executed
        setPrevEmployeesIds_Test(updatedIds)
      }
    } else {
      let updatedIds = prevEmployeesIds_Test.length > 0 ? prevEmployeesIds_Test : EmployeeDefaultWithoutTeam?.map((option) => option?.id) || [];
      if (updatedIds.length > 0) {
        let dateObj = {
          startDate: formatDate(selectedFromDateTwo),
          endDate: formatDate(selectedToDateTwo)
        }
        notPickedData(currentPage, 10, "", updatedIds, dateObj);
      }
      setIsFirstEffectRun(true); // Mark as executed
      setPrevEmployeesIds_Test(updatedIds)
    }
  }, [selectedEmployeeOptions]);


  // table data
  const handleSubmit = async () => {
    if (BooleanWithSingleEmployeeLogin) {
      let dateObj = {
        startDate: formatDate(selectedFromDate),
        endDate: formatDate(selectedToDate)
      }
      notPickedData(currentPage, 10, '', profileData._id, dateObj);
      setCurrentPage(1)
      setPerPage(10);
    } else {
      if (selectedEmployeeOptions.length === 0) {
        setError("You must select at least one");
      } else {
        let updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
        setPrevEmployeesIds_Test(updatedIds);
        setSelectedFromDateTwo(selectedFromDate);
        setSelectedToDateTwo(selectedToDate);
        let dateObj = {
          startDate: formatDate(selectedFromDate),
          endDate: formatDate(selectedToDate)
        }
        notPickedData(currentPage, 10, '', updatedIds, dateObj);
        setSelectedEmployeeOptions2(selectedEmployeeOptions);
        setCurrentPage(1)
        setPerPage(10);
        setError("");
        setHandleSubmit(true);
      }
    }
  };

  // Debounce search query
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
      setCurrentPage(1)
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);


  useEffect(() => {
    if (BooleanWithSingleEmployeeLogin) {
      loginEmplolye()
      return;
    } else {
      if (isFirstEffectRun === true) {
        let updatedIds = prevEmployeesIds_Test.length > 0 ? prevEmployeesIds_Test : selectedEmployeeOptions2?.map((option) => option?.id) || [];
        const fetchData = async () => {
          if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
            let dateObj = {
              startDate: formatDate(selectedFromDateTwo),
              endDate: formatDate(selectedToDateTwo)
            }
            await notPickedData(currentPage, perPage, debouncedQuery, updatedIds, dateObj);
          }
        };
        fetchData();
      }
    }

    async function loginEmplolye() {
      let dateObj = {
        startDate: formatDate(selectedFromDate),
        endDate: formatDate(selectedToDate)
      }
      await notPickedData(currentPage, perPage, debouncedQuery, profileData._id, dateObj);
    }

  }, [debouncedQuery, currentPage, perPage]);

  // Pagination handler
  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };


  // Row per Changes
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };


  // Api Call in Not Pickkedup
  const notPickedData = async (page, size = perPage, searchTerm = '', updatedIds, dateObj) => {
    setIsLoading(true);
    try {
      if (!updatedIds) {
        updatedIds = selectedEmployeeOptions2.map((userid) => userid?.id);
      }
      let data = {
        startDate: dateObj?.startDate ? dateObj?.startDate : "",
        endDate: dateObj?.endDate ? dateObj?.endDate : "",
        employeeIds: updatedIds
      };
      const response = await api.create(`${NOT_PICKED_REPORT}?pg=${page}&lm=${size}&query=${searchTerm}`, data);
      if (response && response?.success === true) {
        setIsLoading(false);
        const apiResponse = response?.data;
        const tableData = (apiResponse?.paginatedResults || []).map(
          (data, index) => {
            const recordingUserStatus = data?.recordingStatus?.replace(
              /_/g,
              " "
            );
            // console.log("data?.employeeDescription[0]?.countryCode",data?.employeeDescription[0]?.countryCode);

            return {
              serialNumber: index + 1,
              employeeName: Array.isArray(data?.employeeDescription)
                ? data.employeeDescription[0]?.fullName
                : "unknown",
              employeeNumber: Array.isArray(data?.employeeDescription)
                ? data.employeeDescription[0]?.mobileNumber
                : "-",
              clientName: data?.name ?? "unknown",
              phoneNumber: data?.phoneNumber ?? "-",
              countryCode: data?.employeeDescription[0]?.countryCode,
              dt: moment(data?.dateTime).format("DD MMM YYYY") ?? "-",
              tm: moment(data?.dateTime).format("hh:mm A") ?? "-",
              duration: data?.duration ?? "-",
              note: data?.note ?? "-",
              recordingStatus: recordingUserStatus ?? "-",
              typ: data?.type ?? "-",
            };
          }
        );
        setNotPickupedData(tableData);
        setTotalRows(apiResponse?.totalCount);
      }
    } catch (error) {
      if (!remainingDays === 0) {
        HandleErrorMessage(error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  //clear button function
  const resetInputHistory = () => {
    setSelectedToDate('')
    setSelectedFromDate('')
    setSelectedEmployeeOptions([]);
    setTeamIndu([]);
  }

  useEffect(() => {
    if (BooleanWithSingleEmployeeLogin) {
      let dateObj = {
        startDate: formatDate(selectedFromDateTwo),
        endDate: formatDate(selectedToDateTwo)
      }
      notPickedData(currentPage, 10, "", profileData._id, dateObj);
    }
  }, [profileData])

  // expire plan  function
  const handleClose = () => {
    setIsExpiredModalOpen(false);
    navigate("/planPackage");
  };

  //export in excel 
  const handleExcelExport = async () => { 
    const updatedIds = selectedEmployeeOptions2.map((userid) => userid?.id);
    await handleExport({type: "excel", apiUrl: `${NOT_PICKED_REPORT}`, startDate: selectedFromDate,endDate: selectedToDate,employeeIds: updatedIds,fileName: "NotPickupByClients.xlsx"});
  };

//export in pdf 
const handleExportPdf = async () => { 
  const updatedIds = selectedEmployeeOptions2.map((userid) => userid?.id);
  await handleExport({type: "pdf", apiUrl: `${NOT_PICKED_REPORT}`, startDate: selectedFromDate, endDate: selectedToDate, employeeIds: updatedIds, fileName: "NotPickupByClients.pdf"});
};

  return (
    <React.Fragment>
      {remainingDays === 0 && isExpiredModalOpen && (
        <SubExpired
          onClose={handleClose}
          profileData={profileData?.selectedPlanDetails?.planName}
          remainingDays={remainingDays}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Not Picked Up by Client"
            pageTitle="Not Picked Up by Client"
          />
          <Row>
            <Col lg={12}>
              <Card className="h-100">
                <CardBody>
                  <Row className="sn-header-select-employess-home sn-top-inputs-heading">
                    <div className="col-md-2 col-sm-2">
                      <DatePicker
                         labelContent="From Date"
                         getDate={selectedFromDate}
                         options={{
                           disable: [
                             {
                               from: new Date(),
                             },
                           ],
                           dateFormat: "d M, Y",
                           maxDate: new Date(
                             new Date().setDate(new Date().getDate())
                           ),
                         }}
                         onChange={handleFromDateChange}
                      />
                    </div>

                    <div className="col-md-2 col-sm-2">
                      <DatePicker
                        labelContent="To Date"
                        getDate={selectedToDate}
                        options={{
                          dateFormat: "d M, Y",
                          minDate: selectedFromDate,
                          // maxDate: 'today',
                          enable: [
                            {
                              from: selectedFromDate,
                              to: Math.min(
                                addMonths(selectedFromDate, 3),
                                new Date()
                              ),
                            },
                          ],
                          onOpen: () => {
                            const currentDate = new Date();
                            const threeMonthsFromStart = addMonths(
                              selectedFromDate,
                              3
                            );
                            if (threeMonthsFromStart > currentDate) {
                              return {
                                enable: [
                                  {
                                    from: selectedFromDate,
                                    to: currentDate,
                                  },
                                ],
                              };
                            }
                          },
                        }}
                        onChange={handleToDateChange}
                      />
                    </div>

                    {((viewReportPermission && loginType === "employee") ||
                      loginType === "organization") && (
                        <div className="col-md-2 col-sm-12">
                          <TeamSelect
                            setSelectedEmployeeOptions={
                              setSelectedEmployeeOptions
                            }
                            teamIndu={teamIndu}
                            setTeamIndu={setTeamIndu}
                            labelContent="Select Team"
                          />
                        </div>
                      )}

                    {((viewReportPermission && loginType === "employee") ||
                      loginType === "organization") && (
                        <div className="col-md-3 col-sm-12">
                          <EmployeeSelect
                            setEmployeeDefaultWithoutTeam={setEmployeeDefaultWithoutTeam}
                            EmployeeDefaultWithoutTeam={EmployeeDefaultWithoutTeam}
                            error={error}
                            setError={setError}
                            EmployeeDefault={teamIndu.length === 0 ? EmployeeDefaultWithoutTeam : EmployeeData}
                            setEmployeeDefault={setEmployeeData}
                            teamIndu={teamIndu}
                            selectedEmployeeOptions={selectedEmployeeOptions}
                            setSelectedEmployeeOptions={
                              setSelectedEmployeeOptions
                            }
                            setSelectedEmployeeOptions2={
                              setSelectedEmployeeOptions2
                            }
                            labelContent="Select Employee"
                          />
                        </div>
                      )}
                    <div className="col-md-3 col-sm-12 ">
                      <FormGroup className="mt-2">
                        <Label for="exampleSelect"></Label>
                        <div
                          id="exampleSelect"
                          className="sn-call-apply-btn-reset "
                        >
                          <Button
                            id="exampleSelect"
                            type="submit"
                            color="danger"
                            className=""
                            onClick={handleSubmit}
                            disabled={isLoading}
                          >
                            Apply
                          </Button>
                          <Button
                            className=""
                            style={{ marginLeft: "10px" }}
                            id="exampleSelect"
                            outline
                            color="danger"
                            onClick={resetInputHistory}
                            disabled={isLoading}
                          >
                            Reset
                          </Button>

                         
                          
                        </div>
                      </FormGroup>
                    </div>
                  </Row>
                  <Row></Row>
                  <Row className="sn-team-managment-tble sn-user-tble-manin-manage">
                    <Col lg={12}>
                      
                    <div className="text-end">
               
               {notPickupedData?.length > 0 ? <div className="sales-ninja-tble-export-btn call-logs-report-export-exe" style={{}}>
                                       {" "}
                                       <Button style={{marginLeft:'10px'}}
                                         onClick={handleExcelExport}
                                         className="custom-button tble-export1 sn-report-export-btn-new"
                                       title="Export">
                                         <i className="ri-file-excel-line"></i>
                                       </Button>{" "}
                                       &nbsp;
                                        <Button 
                                                             onClick={handleExportPdf}
                                                             className="custom-button ml-2 tble-export1 tble-export2 sn-report-export-btn-new button-file-export-pdf"
                                                             title="Export">
                                                             <i className="las la-file-pdf"></i>
                                                           </Button> 
                                     </div> : ''}
               
                                </div>
                
                        <CommanDataTable
                          setCurrentPage={setCurrentPage}
                          perPage={perPage}
                          handleSearch={handleSearch}
                          searchText={query}
                          column={column}
                          tableData={notPickupedData}
                          handlePerRowsChange={handlePerRowsChange}
                          totalRows={totalRows}
                          currentPage={currentPage}
                          isLoading={isLoading}
                          handlePageChange={handlePageChange}
                          noData="There are no records to display"
                          inputClass={
                            (notPickupedData && notPickupedData?.length > 0) ||
                              query
                              ? "d-block"
                              : "d-none"
                          }
                        />
                    
                    </Col>
                    {/* </div> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NeverPickepReport;