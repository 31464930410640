import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const SelectDatePicker = ({ selectedDates, setSelectedDates }) => {
    const [startDate, setStartDate] = useState(null);

    const disabledDate = (current) => {
        // Always disable future dates
        if (current.isAfter(dayjs(), 'day')) {
            return true;
        }

        if (!startDate) {
            return false;
        }

        const threeMonthsFromStart = dayjs(startDate).add(3, 'month');
        const maxDate = threeMonthsFromStart.isAfter(dayjs()) ? dayjs() : threeMonthsFromStart;

        return current.isAfter(maxDate, 'day');
    };

    const onRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            const start = dayjs(dates[0]);
            const selectedEnd = dayjs(dates[1]);
            const today = dayjs();

            const threeMonthsFromStart = start.add(3, 'month');

            let end;
            if (threeMonthsFromStart.isAfter(today)) {
                end = today;
            } else if (selectedEnd.isAfter(threeMonthsFromStart)) {
                end = threeMonthsFromStart;
            } else if(start.isAfter(threeMonthsFromStart)) {
                end = selectedEnd;
            }else{
                end = threeMonthsFromStart;
            }

            setSelectedDates([start, end]);
            setStartDate(start); 
            
        } else {
            setSelectedDates([]); 
            setStartDate(null); 
        }
    };

    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs(), dayjs()],
        },
        {
            label: 'Yesterday',
            value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
        },
        {
            label: 'Last 7 Days',
            value: [dayjs().subtract(7, 'days'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().subtract(30, 'days'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().subtract(90, 'days'), dayjs()],
        },
    ];

    return (
        <RangePicker
            presets={rangePresets}
            value={selectedDates}
            format="YYYY-MM-DD"
            onChange={onRangeChange} // Use onChange instead of onCalendarChange
            disabledDate={disabledDate}
            style={{ width: '100%' }}
            dropdownClassName="createDateRangePicker"
        />
    );
};

export default SelectDatePicker;
