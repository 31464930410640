import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormFeedback } from 'reactstrap';

const CommonInput = ({ label, name, type, placeholder, formik ,handleFileInput,onInput}) => {
    return (
        <React.Fragment>
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
        {type === "file" ? (
          <Input type={type} onChange={handleFileInput} multiple></Input>
        ) : (
          <>
            {" "}
            <Input
              name={name}
              type={type}
              placeholder={placeholder}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[name]}
              onInput={onInput}
              invalid={formik.touched[name] && formik.errors[name]}
            />
            {formik.touched[name] && formik.errors[name] && (
              <FormFeedback type="invalid">
                <div>{formik.errors[name]}</div>
              </FormFeedback>
            )}
          </>
        )}
      </React.Fragment>
    );
};

CommonInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired,
};

export default CommonInput;
