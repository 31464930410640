import React from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import moment from 'moment';
import { ConvertTimestampToTime } from '../../../Components/Common/convertTimestampToTime';
import { formatDate } from '../../../Components/Common/FormDateTime';


const ViewModal = ({ view_modal_grid, setView_modal_grid, viewData }) => {
    const { lastCallRecordingSync, lastCallSync, fullName, dateOfJoining, jobTitle } = viewData
    const time = lastCallRecordingSync ? ConvertTimestampToTime(lastCallRecordingSync) : '-'
    const CallRecordingDate = lastCallRecordingSync ? formatDate(lastCallRecordingSync) : '-'
    const Lastsyncdate = lastCallSync ? formatDate(lastCallSync) : '-'
    const lastSync = lastCallSync ? ConvertTimestampToTime(lastCallSync) : '-'
    const firstLetter = fullName ? fullName.charAt(0).toUpperCase() : '';

    

    const tog_grid = () => {
        setView_modal_grid(!view_modal_grid)
    }

    return (
        <>
            <Modal isOpen={view_modal_grid} toggle={() => {
                tog_grid();
            }}>
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { setView_modal_grid(); }}>View Employee</ModalHeader>
                <ModalBody>
                    <Row className="g-0">
                        <Col xxl={12}>
                            <div id="contact-view-detail">
                                <div className="text-center sn-profile-main-user-user">
                                    <div className="position-relative d-inline-block">
                                        {viewData?.profileImageUrl ? <img
                                            src={viewData?.profileImageUrl}
                                            alt=''
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                                objectFit: 'cover',
                                                borderRadius: "80%",
                                                marginRight: "20px",
                                            }}
                                        /> : <>
                                            <div className="avatar-lg rounded-circle img-thumbnail">
                                                {firstLetter}
                                            </div>
                                        </>}
                                        {/* <div className="avatar-lg rounded-circle img-thumbnail">
                                            {firstLetter}
                                        </div> */}
                                        <span className="contact-active position-absolute rounded-circle bg-success">
                                            <span className="visually-hidden"></span>
                                        </span>
                                    </div>
                                    <h5 className="mt-3 mb-2" style={{ textTransform: 'capitalize' }}>{viewData?.fullName || ''}</h5>
                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">{viewData?.organizationId}</h6>
                                </div>
                                <div>
                                    <div className="table-responsive table-card">
                                        <Table className="table table-borderless mb-0 sn-modal-tble" style={{ textAlign: 'left', borderBottom: '1px solid #ccc', marginBottom: '2px !important' }}>
                                            <tbody>
                                                <tr>
                                                    <td className="fw-medium">
                                                        Email ID
                                                    </td>
                                                    <td>{viewData?.email || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">
                                                        Phone No
                                                    </td>
                                                    <td>{viewData?.mobileNumber || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">
                                                        Designation
                                                    </td>
                                                    <td style={{ textTransform: 'capitalize' }}>{viewData?.jobTitle || '-'}</td>
                                                </tr>

                                                <tr>
                                                    <td className="fw-medium">
                                                        Team
                                                    </td>
                                                    <td style={{ textTransform: 'capitalize' }}>{viewData?.team?.join(', ') || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium" style={{ textTransform: 'capitalize' }}>
                                                        Role
                                                    </td>
                                                    <td>{viewData?.role || '-'}</td>
                                                </tr>

                                                <tr>
                                                    <td className="fw-medium">
                                                        Device Name
                                                    </td>
                                                    <td>{viewData?.deviceName || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">App version</td>
                                                    <td>{viewData?.appVersion || ''}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">
                                                        Registered Date
                                                    </td>
                                                    <td>{moment(viewData?.createdAt).format('DD MMM YYYY') || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">
                                                        Date Of Joining
                                                    </td>
                                                    <td>
                                                        {moment(dateOfJoining).format('DD MMM YYYY') || '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">
                                                        Call Recording Sync
                                                    </td>
                                                    <td>{CallRecordingDate && moment(CallRecordingDate).isValid()
                                                        ? moment(CallRecordingDate).format('DD MMM YYYY')
                                                        : '-'}<br />{time}</td>
                                                </tr>

                                                <tr>
                                                    <td className="fw-medium">
                                                        Last Call Sync
                                                    </td>
                                                    <td>{Lastsyncdate && moment(Lastsyncdate).isValid() ? moment(Lastsyncdate).format('DD MMM YYYY') : '-'} <br/> {lastSync||'-'}</td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ViewModal