import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
  UncontrolledAlert,
  Button,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMasterApi } from "../../Components/Hooks/UserHooks";
import { ToastContainer, toast } from "react-toastify";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import {
  Get_cities,
  Get_Countries,
  POST_CITIES,
  POST_GET_INDUSTRY_TYPE,
  POST_PINCODE,
  POST_REGISTER,
} from "../../helpers/url_helper";
import { Link, useNavigate } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { APIClient } from "../../helpers/api_helper";
import Toast from "../../Components/Common/Toast";
import logoheader from "../../assets/images/logo-main-white.png";
import Footer from "../../Layouts/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
const api = new APIClient();

const Register = () => {
  const { masterApiData } = useMasterApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const phoneNumberRules = /^[1-9]\d{9}$/;
  const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [industryTypeData, setIndustryTypeData] = useState();
  const [getCity, setGetCity] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumbers, setMobileNumbers] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  // Add this state for search results
  const [searchResults, setSearchResults] = useState(null);
  const [selectedCity, setSelectedCity] = useState(""); // Tracks selected city or "Other"
  const [customCity, setCustomCity] = useState(""); // Tracks custom city if "Other" is selected


  const {
    handleSubmit,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      email: "",
      countryCode: "",
      postalcode: "",
      mobileNumber: "",
      city: "",
      website: "",
      country: "",
      industryType: "",
      address: "",
      stateCode: "",
      terms: false,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("Name is required")
        .max(250, "Name must not be more than 250 characters"),
      email: Yup.string()
        .email("Please enter a valid email address")
        .matches(emailRules, { message: "  Invalid email" })
        .required("Email Address is required"),
        postalcode: Yup.string()
        .min(4, "Postal code should be at least 4 digits long")
        .max(12, "Postal code must have a maximum of 12 digits")
        .required("Postal code is required"),    
      city: Yup.string().required("City Name is required"),
      mobileNumber: Yup.string()
        .required("Mobile number is required")
        .test(
          "is-valid-mobile",
          "Enter a Mobile number",
          (value) => value && value.length > 3 // Minimum length check to ensure country code + number
        ),
      terms: Yup.boolean().isTrue(
        "Please read and accept the Terms of Service & Privacy Policy before continuing."
      ),
      country: Yup.string().required("Country Name is required"),
      // countryCode: Yup.string().required("Country Code is required"),
      address: Yup.string().required("Address Name is required"),
      stateCode: Yup.string().required("State Name is required"),
      industryType: Yup.string().required("Industry is required"),
    }),
    onSubmit: async (value) => {
      const  name = value.stateCode;
      const code = "";
      // const [countryCodes, CounrtyName] = value.country.split(",");
      const cityValue = selectedCity === "Other" ? customCity : value.city; 

      try {
        setLoading(true);
        let data = {
          organizationName: value.username,
          email: value.email,
          mobileNumber: mobileNumbers,
          phoneNumber: mobileNumbers,
          pincode: value.postalcode,
          city:  cityValue,
          state: name,
          stateCode: code,
          countryCode: countryCode,
          country: value.country,
          industryType: value.industryType,
          address: value.address,
          website: value.website,
        };
        const response = await api.create(`${POST_REGISTER}`, data);
        if (response.success === true) {
          Toast(response?.message, "success");
          setTimeout(() => {
            navigate("/auth-success-msg", {
              state: { id: 1, email: value?.email },
            });
          }, 2000);
        }
      } catch (error) {
        toast.error(error.response?.data?.message, { autoClose: 2000 });
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    fetchUserData();
  }, [countries]);

  //fetch countries from api
  const fetchCountries = async () => {
    try {
      const response = await api.create(`${Get_Countries}`);
      if (response?.success && response?.data && response?.data.data) {
        const filteredCountries = response?.data.data.filter(
          (country) => country !== ""
        );
        setCountries(filteredCountries);
      }
    } catch (error) {
      Toast("Failed to fetch countries list", "error");
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleStateClick = (value) => {
    const [code, name] = value.split(",");
    // getCities(name);
  };

  const handleCityClick = (value) => {
    getPostalCode(value);
  };

  const fetchUserData = async () => {
    try {
      const res = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
      if (res.success === true) {
        setIndustryTypeData(res?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  //fetch cities based on country
  const fetchCitiesByCountry = async (country) => {
    try {
      const data = { _id: country };
      const response = await api.create(`${Get_cities}`, data);
      if (response.success === true) {
        setGetCity(response.data.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  // Add this function to handle country selection
  // const handleCountryChange = (event) => {
  //   const country = event.target.value;
  //   setSelectedCountry(country);
  //   setFieldValue("country", country);
  //   // Reset city when country changes
  //   setFieldValue("city", "");
  //   setGetCity([]);
  //   // Fetch cities for selected country
  //   fetchCitiesByCountry(country);
  // };
  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);  // Set the selected country
    setFieldValue("country", country);  // Set the country field
  
    // Reset the city field and the "Other" selection
    setSelectedCity(null);  // Reset selected city (no "Other")
    setCustomCity("");  // Clear custom city if "Other" was selected
    setFieldValue("city", "");  // Clear city field
    setSearchResults([]);  // Clear search results
  
    // Fetch cities for the selected country
    fetchCitiesByCountry(country);
  };
  
  
  

  useEffect(() => {
    setGetCity([]);
  }, []);

  const getPostalCode = async (city) => {
    try {
      const data = { cityName: city };
      const response = await api.create(`${POST_PINCODE}`, data);
      if (response.success === true) {
        setPostalCode(response.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleOnChange = (value, data) => {
    let cleanedNumber = value;
    if (!value.startsWith("+")) {
      cleanedNumber = `+${value}`;
    }
    const dialCode = data.dialCode;
    const dialCountryCode = `+${data.dialCode}`;
    const numberWithoutDialCode = cleanedNumber
      .replace(`+${dialCode}`, "")
      .trim();
    setCountryCode(dialCountryCode);
    setMobileNumbers(numberWithoutDialCode);
    setFieldValue("mobileNumber", value);
    if (errors.mobileNumber) {
      setFieldValue("mobileNumber", value);
    }
  };

//search function
const handleSearch = (searchTerm) => {
  if (!searchTerm.trim()) {
    setSearchResults(null);
    return;
  }

  // Filter cities based on the search term (case insensitive)
  const filteredCities = getCity.filter(city =>
    city.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Ensure "Other" is always available as an option in the search results
  if (!filteredCities.includes("Other")) {
    filteredCities.push("Other");
  }

  setSearchResults(filteredCities);
};



  return (
    <React.Fragment>
      <ToastContainer />
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <div className="sn-login-header-logo">
                <div className="sn-admin-login-logo">
                  <img src={logoheader} alt="Logo" />
                </div>
              </div>
            </Row>

            <Row className="justify-content-center">
              <Col md={8}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-dark">Create New Account</h5>
                    </div>

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={handleSubmit}
                        className="needs-validation custom-register-form-sec"
                      >
                        <div className="mb-3">
                          <Row>
                            <Col sm={6}>
                              <Label htmlFor="username" className="form-label">
                                Name<span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="username"
                                autoComplete="new-password"
                                type="text"
                                placeholder="Enter name"
                                {...getFieldProps("username", {
                                  autoComplete: "off",
                                })}
                                className={`form-control ${
                                  touched.username && errors.username
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {touched.username && errors.username && (
                                <div className="invalid-feedback">
                                  {errors.username}
                                </div>
                              )}
                            </Col>

                            <Col sm={6}>
                              <Label htmlFor="useremail" className="form-label">
                                Email Address
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="email"
                                autoComplete="new-password"
                                type="email"
                                placeholder="Enter email address"
                                {...getFieldProps("email", {
                                  autoComplete: "off",
                                })}
                                className={`form-control ${
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {touched.email && errors.email && (
                                <div className="invalid-feedback">
                                  {errors.email}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <div className="row">
                            <Col sm={6} className="">
                              <Label
                                htmlFor="mobileNumber"
                                className="form-label"
                              >
                                Contact Number
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="sn-country-code-flag-de">
                                <PhoneInput
                                  country={"in"}
                                  enableSearch={true}
                                  value={getFieldProps("mobileNumber").value}
                                  onChange={handleOnChange}
                                  onBlur={() =>
                                    setFieldTouched("mobileNumber", true)
                                  }
                                  className={`${
                                    touched.mobileNumber && errors.mobileNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                {touched.mobileNumber &&
                                  errors.mobileNumber && (
                                    <div className="invalid-feedback">
                                      {errors.mobileNumber}
                                    </div>
                                  )}
                              </div>
                            </Col>

                            <Col sm={6}>
                              {/* Address Input */}
                              <Label htmlFor="address" className="form-label">
                                Address<span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="address"
                                autoComplete="new-password"
                                type="text"
                                placeholder="Enter Address"
                                {...getFieldProps("address")}
                                className={`form-control ${
                                  touched.address && errors.address
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {/* Validation Message */}
                              {touched.address && errors.address && (
                                <div className="invalid-feedback">
                                  {errors.address}
                                </div>
                              )}
                            </Col>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="s_col_no_resize s_col_no_bgcolor">
                            <Row>
                              <Col sm={6}>
                                <Label htmlFor="country" className="form-label">
                                  Select Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  data-tooltip-id="my-tooltip-5"
                                  className={`form-select ${
                                    touched.country && errors.country
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="country"
                                  value={getFieldProps("country").value}
                                  onChange={handleCountryChange}
                                  onBlur={getFieldProps("country").onBlur}
                                >
                                  <option value="" disabled>
                                    Select Country
                                  </option>
                                  {countries.map((country, index) => (
                                    <option key={index} value={country}>
                                      {country}
                                    </option>
                                  ))}
                                </select>
                                {touched.country && errors.country && (
                                  <div className="invalid-feedback">
                                    {errors.country}
                                  </div>
                                )}
                              </Col>
                      
                              <Col sm={6}>
                                <Label htmlFor="state" className="form-label">
                                  Select State
                                  <span className="text-danger">*</span>
                                </Label>

                                <input
                                  data-tooltip-id="my-tooltip-6"
                                  type="text"
                                  className={`form-control ${
                                    touched.stateCode && errors.stateCode
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="stateCode"
                                  {...getFieldProps("stateCode")}
                                  onClick={(event) =>
                                    handleStateClick(event.target.value)
                                  }
                                  placeholder="Enter State"
                                />
                                {touched.stateCode && errors.stateCode && (
                                  <div className="invalid-feedback">
                                    {errors.stateCode}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className="mb-3">
                          <Row>
                          <Col sm={6}>
                              <Label htmlFor="City" className="form-label">
                                {selectedCity === "Other" ? "Other City" : "City"}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  data-tooltip-id="my-tooltip-8"
                                  className={`form-control ${
                                    touched.city && errors.city ? "is-invalid" : ""
                                  }`}
                                  name="city"
                                  value={
                                    selectedCity === "Other" ? customCity : getFieldProps("city").value
                                  }
                                  onChange={(e) => {
                                    if (selectedCity === "Other") {
                                      setCustomCity(e.target.value); // Update custom city when "Other" is selected
                                    } else {
                                      setFieldValue("city", e.target.value); // Normal city input
                                      handleSearch(e.target.value); // Trigger search when typing
                                    }
                                  }}
                                  onBlur={getFieldProps("city").onBlur}
                                  placeholder={
                                    selectedCity === "Other" || !selectedCity ? "Search city..." : "Enter Other City..."
                                  }
                                />
                                {searchResults && searchResults.length > 0 && (
                                  <div
                                    className="position-absolute w-100 bg-white border rounded mt-1"
                                    style={{ zIndex: 1000, maxHeight: "200px", overflowY: "auto" }}
                                  >
                                    {searchResults.map((city, index) => (
                                      <div
                                        key={index}
                                        className="p-2 cursor-pointer hover:bg-light"
                                        onClick={() => {
                                          setSelectedCity(city); // Select city from search results
                                          setFieldValue("city", city); // Store the city in the form
                                          if (city === "Other") {
                                            setCustomCity(""); // Clear custom city if "Other" is selected
                                          } else {
                                            handleCityClick(city); // Perform action when a normal city is selected
                                          }
                                          setSearchResults(null); // Hide search results after selection
                                        }}
                                      >
                                        {city}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              {touched.city && errors.city && (
                                <div className="invalid-feedback">{errors.city}</div>
                              )}
                            </Col>

                            <Col sm={6}>
                              <Label
                                htmlFor="postalcode"
                                className="form-label"
                              >
                                Postal-Code
                                <span className="text-danger">*</span>
                              </Label>
                              <input
                                data-tooltip-id="my-tooltip-4"
                                type="tel"
                                className={`form-control ${
                                  touched.postalcode && errors.postalcode
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="postalcode"
                                onInput={(e) => {
            
                                  e.target.value = e.target.value.slice(0, 12);
                                }}
                                {...getFieldProps("postalcode")}
                                placeholder="Enter Postal Code"
                              />
                              {touched.postalcode && errors.postalcode && (
                                <div className="invalid-feedback">
                                  {errors.postalcode}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Row>
                            <Col sm={6}>
                              <Label
                                htmlFor="stateIndustry"
                                className="form-label"
                              >
                                Select Industry
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                data-tooltip-id="my-tooltip-9"
                                name="stateIndustry"
                                id="industryType"
                                {...getFieldProps("industryType")}
                                className={`form-select ${
                                  touched.industryType && errors.industryType
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value="" disabled>
                                  Select Industry
                                </option>
                                {(industryTypeData?.industryType || [])?.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        <option key={ele} value={ele}>
                                          {ele}
                                        </option>
                                      </>
                                    );
                                  }
                                )}
                              </select>
                              {touched.industryType && errors.industryType && (
                                <div className="invalid-feedback">
                                  {errors.industryType}
                                </div>
                              )}
                            </Col>
                            <Col sm={6}>
                              <Label
                                htmlFor="websiteurl"
                                className="form-label"
                              >
                                Website Url
                                <span className="text-danger d-none">*</span>
                              </Label>
                              <Input
                                name="website"
                                type="text"
                                placeholder="Enter Website Url."
                                {...getFieldProps("website")}
                                className="form-control"
                              />
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <div className="col-sm">
                            <Input
                              id="leadTermCheck"
                              type="checkbox"
                              {...getFieldProps("terms")}
                            />
                            &nbsp;
                            <label
                              htmlFor="Business"
                              className="custom-control-label dt-cursor"
                            >
                              <Label for="leadTermCheck">
                                Yes, I understand and agree to the{" "}
                                <Link
                                  to="https://salesninjacrm.com/terms-conditions"
                                  className="text-danger"
                                  target="_blank"
                                >
                                  Terms & Conditions
                                </Link>{" "}
                                and{" "}
                                <Link
                                  to="https://salesninjacrm.com/privacy-policy"
                                  className="text-danger"
                                  target="_blank"
                                >
                                  Privacy Policy
                                </Link>
                              </Label>
                            </label>
                          </div>
                        </div>

                        <div className="mt-4">
                          {touched.terms && errors.terms && (
                            <>
                              <UncontrolledAlert
                                color="danger"
                                className="mb-xl-0"
                                toggle={undefined}
                              >
                                <strong>{errors.terms}</strong>
                              </UncontrolledAlert>
                            </>
                          )}
                        </div>

                        <div className="mt-3">
                          <Button
                            color="primary"
                            className="btn btn-danger w-100"
                            type="submit"
                          >
                            {loading === true ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Sign Up
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Already have an account ?{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-danger text-decoration-underline"
                    >
                      {" "}
                      Signin{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
      <div className="sn-footer-botom-page">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Register;
