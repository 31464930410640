import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

// Utility function to get Excel column letter from an index
export const getExcelColumnLetter = (colIndex) => {
  let letter = "";
  while (colIndex >= 0) {
    letter = String.fromCharCode((colIndex % 26) + 65) + letter;
    colIndex = Math.floor(colIndex / 26) - 1;
  }
  return letter;
};



// Function to export data to Excel
export const exportToExcel = async (employeeData, exactHeaders, reportTitle, dataStructure = "",fileName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data Report");
  // Add title "NEVER ATTEND" at top
  const titleRow = worksheet.addRow([reportTitle]);

  // Add empty row after title
  worksheet.addRow([]);
  titleRow.height = 35;
  const titleCell = worksheet.getCell('A1');


  titleCell.font = {
    bold: true,
    size: 14,
    color: { argb: '000000' }
  };

  titleCell.alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };

  if (dataStructure === "employeeReport") {
    worksheet.mergeCells('A1:P1');
    // Merge cells for group headers (starting from row 3 due to title and empty row)
    worksheet.mergeCells("A3:A4"); // SN
    worksheet.mergeCells("B3:B4"); // Date
    worksheet.mergeCells("C3:C4"); // Total Calls
    worksheet.mergeCells("D3:D4"); // Total Duration
    worksheet.mergeCells("E3:E4"); // Unique Calls
    worksheet.mergeCells("F3:F4"); // Connected Calls
    worksheet.mergeCells("G3:I3"); // Incoming Group
    worksheet.mergeCells("J3:L3"); // Outgoing Group

    // First header row with group titles (row 3)
    worksheet.getRow(3).values = [
      "SN",
      "Date",
      "Total Calls",
      "Total Duration",
      "Unique Calls",
      "Connected Calls",
      "",
      "Incoming",
      "",
      "",
      "Outgoing",
      "",
      "Missed",
      "Rejected",
      "Never Attended",
      "Not Picked Up By Client",
    ];

    // Style the main header row
    worksheet.getRow(3).height = 30;
    worksheet.getRow(3).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f17171" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Second header row for sub-columns (row 4)
    worksheet.getRow(4).values = [
      "", "", "", "", "", "",
      "Total Call",
      "Connected Call",
      "Total Duration",
      "Total Call",
      "Connected Call",
      "Total Duration",
      "", "", "", ""
    ];

    // Style the incoming sub-columns
    ["G4", "H4", "I4"].forEach((cellRef) => {
      const cell = worksheet.getCell(cellRef);
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "68b274" },
      };
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
    });

    // Style the outgoing sub-columns
    ["J4", "K4", "L4"].forEach((cellRef) => {
      const cell = worksheet.getCell(cellRef);
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "3abfdd" },
      };
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
    });

    // Set column widths
    worksheet.columns = [
      { key: "sn", width: 25 },
      { key: "date", width: 25 },
      { key: "totalCalls", width: 25 },
      { key: "totalDuration", width: 25 },
      { key: "uniqueCalls", width: 25 },
      { key: "connectedCalls", width: 25 },
      { key: "incomingTotalCall", width: 25 },
      { key: "incomingConnectedCall", width: 25 },
      { key: "incomingTotalDuration", width: 25 },
      { key: "outgoingTotalCall", width: 25 },
      { key: "outgoingConnectedCall", width: 25 },
      { key: "outgoingTotalDuration", width: 25 },
      { key: "missed", width: 25 },
      { key: "rejected", width: 25 },
      { key: "naverAttend", width: 25 },
      { key: "notPickup", width: 25 },
    ];

    // Add data rows
    employeeData?.forEach((item, index) => {
      const row = worksheet.addRow({
        sn: item.srNo,
        date: item.date,
        totalCalls: item.tCalls,
        totalDuration: item.tDuration,
        uniqueCalls: item.uniqueClient,
        connectedCalls: item.connectedCalls,
        incomingTotalCall: item.inTotalCall,
        incomingConnectedCall: item.inConnectedCall,
        incomingTotalDuration: item.indurationCall,
        outgoingTotalCall: item.outTotalCall,
        outgoingConnectedCall: item.outTotalConnected,
        outgoingTotalDuration: item.outTotalduration,
        missed: item?.missed,
        rejected: item?.rejected,
        naverAttend: item?.naverAttend,
        notPickup: item?.notPickup,
      });

      // Style data row
      row.height = 25;
      row.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
        if (index % 2 !== 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "fff2f2" },
          };
        }
      });
    });

  }
  else {
    worksheet.mergeCells(`A1:${worksheet.getColumn(exactHeaders?.length).letter}1`);
    const headerRowData = exactHeaders.map(header => header?.label);
    const headerRow = worksheet.addRow(headerRowData);

    // Style header row
    headerRow.height = 30;

    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f17171" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Set column widths
    worksheet.columns = exactHeaders.map(header => ({
      key: header.key,
      width: header.label.length + 25,
    }));

    // Add data rows
    employeeData.forEach((item, index) => {
      const teams = item.team || [];
      const roles = item.role || [];
      if (dataStructure === "AgentPerformance") {
        teams.forEach((team) => {
          worksheet.addRow({
            agentName: item?.fullName,
            team: team,
            uniqueClient: item.uniqueClient,
            totalDuration: item.totalDuration,
            totalCall: item.totalCall,
            totalconnectedCalls: item.connectedCalls,
            totallIncomingCall: item.totallIncomingCall,
            totallOutgoingCall: item.totallOutgoingCall,
            totallMissedCall: item.totallMissedCall,
            totalRejectedCall: item.totalRejectedCall,
            totalNotPickedUpByClient: item.totalNotPickedUpByClient,
            totalNeverAttended: item.totalNeverAttended,
            avrerageCallDuration: item.avrerageCallDuration,
            connectedCalls: item.connectedCalls,
          });
        });
      } else {
        if (teams.length === 0 && roles.length === 0) {
          // If no team and no role, add a single row with "No Team" and "No Role"
          worksheet.addRow({
            serialNumber: item.serialNumber,
            name: item.name,
            fullName: item.fullName,
            mobileNumber: item.mobileNumber || "-",
            email: item.email || "-",
            designations: item.designations || "-",
            role: "-", // Placeholder for no role
            team: "-", // Placeholder for no team
            date: item.date || "-",
            deviceName: item.deviceName || "-",
            status: item.status || "-"
          });
        } else if (teams.length === 0) {
          // If no team but there are roles, add a row for each role with "No Team"
          roles.forEach((role) => {
            worksheet.addRow({
              serialNumber: item.serialNumber,
              name: item.name,
              fullName: item.fullName,
              mobileNumber: item.mobileNumber || "-",
              email: item.email || "-",
              designations: item.designations || "-",
              role: role, // Show actual role name
              team: "-", // Placeholder for no team
              date: item.date || "-",
              deviceName: item.deviceName || "-",
              status: item.status || "-"
            });
          });
        } else if (roles.length === 0) {
          teams.forEach((team) => {
            worksheet.addRow({
              serialNumber: item.serialNumber,
              name: item.name,
              fullName: item.fullName,
              mobileNumber: item.mobileNumber || "-",
              email: item.email || "-",
              designations: item.designations || "-",
              role: "-", // Placeholder for no role
              team: team, // Show actual team name
              date: item.date || "-",
              deviceName: item.deviceName || "-",
              status: item.status || "-"
            });
          });
        } else {
          teams.forEach((team) => {
            roles.forEach((role) => {
              worksheet.addRow({
                serialNumber: item.serialNumber,
                name: item.name,
                fullName: item.fullName,
                mobileNumber: item.mobileNumber || "-",
                email: item.email || "-",
                designations: item.designations || "-",
                role: role, // Show actual role name
                team: team, // Show actual team name
                date: item.date || "-",
                deviceName: item.deviceName || "-",
                status: item.status || "-"
              });
            });
          });
        }
      }
    });


    // Style data rows
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 3) { // Skip title, empty row, and header
        if ((rowNumber - 3) % 2 !== 0) {
          row.eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "fff2f2" },
            };
          });
        }
        row.height = 25;
        row.alignment = { vertical: "middle", horizontal: "center" };
      }
    });

    // Add autoFilter
    const lastColumnLetter = worksheet.getColumn(exactHeaders?.length).letter;
    worksheet.autoFilter = {
      from: `A3`,
      to: `${lastColumnLetter}3`,
    };

    // Freeze panes
    worksheet.views = [
      { state: 'frozen', xSplit: 0, ySplit: 3, topLeftCell: 'A4', activeCell: 'A4' }
    ];
  }
  // Export the Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${fileName}.xlsx`);
}


// Function to export data to PDF
export const exportToPDF = (data, headers, filename = "ExportedData.pdf", title, dataStructure = "") => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getTextWidth(title);
  const textX = (pageWidth - textWidth) / 2;

  doc.text(title, textX, 10);

  // Map headers dynamically for PDF export
  const headerLabels = headers.map((header) => header.label);
  const tableData = [];

  // Build the table data for "NeverAttended" structure
  data.forEach((item) => {
    if (dataStructure === "neverAttended" && item.time) {
      // Process each time entry as a separate row
      item.time.forEach((callTime) => {
        const row = headers.map((header) => {
          if (header.key === 'time') {
            return moment(callTime.date).format("hh:mm A"); // Format time as "hh:mm A"
          } else if (header.key === 'date') {
            return moment(callTime.date).format("DD MMM YYYY"); // Format date as "DD MMM YYYY"
          } else if (header.key === 'type') {
            return callTime.type; // Call type
          } else {
            return item[header.key]; // Other fields from the item
          }
        });
        tableData.push(row);
      });
    } else {
      // Standard row processing for other data structures
      const row = headers.map((header) => item[header.key]);
      tableData.push(row);
    }
  });
  doc.autoTable({
    head: [headerLabels],
    body: tableData,
    startY: 20,
    styles: { fontSize: 8 },
    headStyles: { fillColor: [255, 71, 71] },
    alternateRowStyles: { fillColor: [245, 245, 245] },
  });

  doc.save(filename);
};

