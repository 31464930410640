import React, { useState, useEffect } from "react";
// Formik Validation
import { Buffer } from "buffer";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_PROFILE,
  EMPLOYEE_DELETE_PROFILE,
  EMPLOYEE_PUT_UPLOAD_PROFILE,
  FRESHDESK_API,
  Get_cities,
  Get_Countries,
  POST_GET_INDUSTRY_TYPE,
  PUT_UPLOAD_PROFILE,
  TEST_CON_FRESHDESK_API,
  UPDATE_PROFILE,
  UPDATE_PROFILE_EMPLOYEE,
} from "../../helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
//import images
import progileBg from "../../assets/images/profile-bg.jpg";
import ChangePassword from "./Change-password";
import { APIClient } from "../../helpers/api_helper";
import Toast from "../../Components/Common/Toast";
import axios from "axios";
import moment from "moment";
import { ConvertTimestampToTime } from "../../Components/Common/convertTimestampToTime";
import salesninjaicon from "../../assets/images/sales-ninja-icon.png";
import { getProfileRequest } from "../../store/auth/profile/actions";
import freshdeskimg from "../../assets/images/freshworks.png";
import { InputGroup, InputGroupText } from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
const api = new APIClient();

const UserProfile = () => {
  const navigate = useNavigate();
  const { update_type } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state?.Profile?.profileData,
  }));

  const [profileImg, setProfileImg] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  // ACCESS TOKEN

  const accessToken = JSON.parse(localStorage.getItem("authUser"));
  const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneNumberRules = /^[1-9]\d{9}$/;

  const [showChnage, setShowChange] = useState(false);
  const [profileData, setProfileData] = useState();

  const [industryTypeData, setIndustryTypeData] = useState();
  //field state
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [postal, setPostal] = useState("");
  const [code, setCode] = useState("");
  const [country, setCountry] = useState("");
  const [stateData, setStateData] = useState("");
  const [city, setCity] = useState("");
  const [industryType, setIndustryType] = useState();
  const [websites, setWebsite] = useState();
  const [activeTab, setActiveTab] = useState("1");

  const [employeeName, setEmployeeName] = useState([]);
  // const postalCode = postal?.toString();
  const FresdeskStatus = data?.FresdeskStatus;
  const storedValue = JSON.parse(localStorage.getItem("logintype"));
  const [isChecked, setIsChecked] = useState(false);

  const [url, setUrl] = useState(FresdeskStatus === true ? data.url : "");
  const [apiKey, setApiKey] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [mobileNumbers, setMobileNumbers] = useState("");

  // State initialization
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  // Add state for search results
  const [searchResults, setSearchResults] = useState(null);

  //state for reset the pin code in original value while back button press 
  const [values, setValues] = useState("");
  const [selectedCity, setSelectedCity] = useState(""); // Tracks selected city or "Other"
  const [customCity, setCustomCity] = useState(""); // Tracks custom city if "Other" is selected
  const [displayOrganizationName, setDisplayOrganizationName] = useState(data?.organizationName || "");
  useEffect(() => {
    if (FresdeskStatus === true) {
      setIsChecked(true);
    }
  }, [FresdeskStatus]);

  const handleToggle = () => {
    if (FresdeskStatus === true) {
      setIsChecked(true);
    } else {
      setIsChecked(!isChecked);
    }
  };
  
  const formik = useFormik({
    
    initialValues: {
      organizationName:
        storedValue === "organization" ? organizationName || "" : "",
      fullName: storedValue === "employee" ? employeeName || "" : "",
      email: email || "",
      phone: phone || "",
      pincode: postal?.toString() || "",
      countryCode: countryCode ||"",
      country: country || "",
      mobileNumber: mobileNumber || "",
      stateData: stateData || "",
      city: city || "",
      websites: websites || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      ...(storedValue === "organization" && {
        organizationName: Yup.string().required(
          "Please Enter Your organizationName"
        ),
      }),

      // Validation for Employee login
      ...(storedValue === "employee" && {
        fullName: Yup.string().required("Please Enter Your full Name"),
      }),

      email: Yup.string()
        .email("Please Enter a valid email address")
        .matches(emailRules, { message: "  Invalid email" })
        .required("Please Enter your email"),

      ...(storedValue === "organization" && {
        phone: Yup.string().required("Please enter your Phone no"),
      }),

      mobileNumber: Yup.string().required("Please Enter Your Mobile Number"),

      ...(storedValue === "organization" && {
        pincode: Yup.string()

        .min(4, "Pin code should be at least 4 digits long")
        .max(12, "Pin code cannot exceed 12 digits") 
        .required("Pin code is required"),
      }),
      ...(storedValue === "organization" && {
        stateData: Yup.string().required("State Name is required"),
      }),
      ...(storedValue === "organization" && {
        city: Yup.string().required("City Name is required"),
      }),
    }),
    onSubmit: async (values) => {
      let response;
      let data;
      const cityValue = city === "Other" ? customCity : city;
      if (storedValue === "employee") {
        data = {
          fullName: values.fullName,
        };
        response = await api.patch(`${UPDATE_PROFILE_EMPLOYEE}`, data);
      } else if (storedValue === "organization") {
        data = {
          orgName: values.organizationName,
          pincode: values.pincode,
          city: cityValue,
          state: values.stateData,
          countryCode: countryCode,
          country: country,
          phoneNo: values?.mobileNumber,
          mobileNo: values?.mobileNumber,
          website: websites,
        };
        response = await api.patch(`${UPDATE_PROFILE}`, data);
      }
      toast.success(response?.message, { autoClose: 1000 });

      if (response?.success === true) {
        setTimeout(() => {
          setDisplayOrganizationName(values.organizationName);
          navigate("/profile");
          // GetProfileData();
          dispatch(getProfileRequest(storedValue));
        }, 2000);
      }
    },
  });
  useEffect(() => {
    setProfileImg(data?.profileImageUrl);
    const time = ConvertTimestampToTime(data?.lastCallRecordingSync);
    const lastcallsync = ConvertTimestampToTime(data?.lastCallSync);
    setProfileData({ data, timestap: time, lastcallsync: lastcallsync });
    setEmployeeName(data?.fullName || "");
    setDisplayOrganizationName(data?.organizationName || "");
    setOrganizationName(data?.organizationName || "");
    setEmail(data?.email || "");
    setPhone(data?.phoneNumber || "");
    setCountryCode(data?.countryCode || "");
    setMobileNumber(data?.mobileNumber || "");
    setPostal(data?.pincode || "");
    setCountry(data?.country || "");
    setStateData(data?.state || "");
    setCity(data?.city || "");
    setIndustryType(data?.industryType || "");
    setWebsite(data?.website || "");
  }, [data]);

  useEffect(() => {
    if (storedValue === "organization") {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    dispatch(getProfileRequest(storedValue));
  }, [dispatch, storedValue]);

  const fetchUserData = async () => {
    try {
      if (storedValue === "organization") {
        const res = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
        if (res.success === true) {
          setIndustryTypeData(res?.data);
        }
      }
    } catch (error) {
      console.error("Error fetching industry types:", error);
      // Handle error as needed
    }
  };

  const handleWebInputChange = (e) => {
    const { value } = e.target;
    setWebsite(value);
  };

  const handleCountryCode = (e) => {
    const { value } = e.target;
    setCountryCode(value);
  };
  // Fetch countries from the API
  const fetchCountries = async () => {
    try {
      const response = await api.create(`${Get_Countries}`);
      if (response?.success && response?.data && response?.data.data) {
        const filteredCountries = response.data.data.filter(
          (country) => country !== ""
        );
        setCountries(filteredCountries);
      }
    } catch (error) {
      Toast("Failed to fetch countries list", "error");
    }
  };

  //fetch cities based on country
  const fetchCitiesByCountry = async (selectedCountry) => {
    try {
      const data = { _id: selectedCountry };
      const response = await api.create(`${Get_cities}`, data);
      if (response.success) {
        setCities(response?.data.data);
      }
    } catch (error) {
      console.log(error, "error fetching cities");
    }
  };

  useEffect(() => {
    fetchCountries();
    if (country) {
      fetchCitiesByCountry(country);  
    }
  }, [country]);  
 
  useEffect(() => {
    setCity(profileData?.data?.city || "");  
  }, [profileData]);
  

  const handleCountry = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
    setCity("");
    fetchCitiesByCountry(selectedCountry);
  };

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    const fileSizeInMB = file.size / (1024 * 1024);

    if (fileSizeInMB > 5) {
      Toast("Profile picture size should not exceed 5MB.", "error");
      event.target.value = null;
      return;
    }

    // Validate file type
    const validImageTypes = ["image/jpeg", "image/png"];
    if (!validImageTypes.includes(file.type)) {
      Toast(
        "Only image files (e.g., JPG, PNG) are supported for profile pictures.",
        "error"
      );
      return;
    }

    // Preview the image before upload
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
    // Set up the form data and headers
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    var formdata = new FormData();
    formdata.append("profileImage", file);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    let response;

    // Make the API call based on the user type
    if (storedValue === "employee") {
      response = fetch(
        `${process.env.REACT_APP_API_URL}${EMPLOYEE_PUT_UPLOAD_PROFILE}`,
        requestOptions
      );
    } else if (storedValue === "organization") {
      response = fetch(
        `${process.env.REACT_APP_API_URL}${PUT_UPLOAD_PROFILE}`,
        requestOptions
      );
    }

    // Handle the response
    response
      .then((response) => response.json())
      .then((data) => {
        toast.success(data?.message, { autoClose: 1000 });
        dispatch(getProfileRequest(storedValue)); // Refresh profile data
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleTestConnection = async () => {
    try {
      const authToken = Buffer.from(`${apiKey}:x`).toString("base64");
      const freshdeskAxios = axios.create({
        baseURL: TEST_CON_FRESHDESK_API,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authToken}`,
          Cookie: "_x_w=7_1",
        },
      });

      const response = await freshdeskAxios.get("/account");
      if (response.status === 200) {
        setShowChange(true);
        toast.success("Connection Authenticated Successfully", {
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error fetching Freshdesk account:", error);
    }
  };

  const saveChange = async () => {
    try {
      const response = await api.create(`${FRESHDESK_API}`, {
        url,
        apiKey,
      });
      if (response.success === true) {
        toast.success(response.message, { autoClose: 1000 });
      }
    } catch (error) {
      alert("Failed to connect. Please check your credentials.");
      console.error(error);
    }
  };

  const deleteImages = async () => {
    try {
      let config = {
        method: "delete",
        url: "",
      };

      if (storedValue === "employee") {
        config.url = `${process.env.REACT_APP_API_URL}${EMPLOYEE_DELETE_PROFILE}`;
      } else if (storedValue === "organization") {
        config.url = `${process.env.REACT_APP_API_URL}${DELETE_PROFILE}`;
      }
      const response = await axios(config);
      if (response?.success === true) {
        toast.success(response.message, { autoClose: 1000 });
        // GetProfileData();
        dispatch(getProfileRequest(storedValue));
      } else if (response?.data?.success === false) {
        toast.error(response.data.message, { autoClose: 1000 });
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An error occurred while deleting the profile image", {
        autoClose: 1000,
      });
    }
  };

  const handlePhoneChange = (value, data) => {
    let cleanedNumber = value;
    if (!value.startsWith("+")) {
      cleanedNumber = `+${value}`;
    }
    const dialCode = data.dialCode;
    const dialCountryCode = `+${data.dialCode}`;
    const numberWithoutDialCode = cleanedNumber
      .replace(`+${dialCode}`, "")
      .trim();
    setCountryCode(dialCountryCode);
    setMobileNumber(numberWithoutDialCode);

    // Update Formik values
    formik.setFieldValue("countryCode", dialCountryCode);
    formik.setFieldValue("mobileNumber", numberWithoutDialCode);

    // Optionally clear errors on valid input
    if (formik.errors.mobileNumber) {
      formik.setFieldError("mobileNumber", "");
    }
  };

// Add search handler function
const handleCitySearch = (searchTerm) => {
  if (!searchTerm.trim()) {
    setSearchResults(null);
    return;
  }
  
  const filteredCities = cities.filter(city => 
    city.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
   // Ensure "Other" is always available as an option in the search results
   if (!filteredCities.includes("Other")) {
    filteredCities.push("Other");
  }
  
  setSearchResults(filteredCities);
};
 

  // //function for reset the value while back button press 
  // const handlePin = () => {
  //   if(profileData?.data.pincode !== values)
  //    {
  //        formik.setFieldValue(
  //            "pincode",
  //            profileData?.data.pincode,
  //          );
  //       //  setPostal(profileData?.data.pincode);
  //    }
  //    if(profileData?.data.organizationName !== values){
  //      formik.setFieldValue(
  //        "organizationName",
  //        profileData?.data.organizationName
  //      )
  //     //  setOrganizationName(profileData?.data.organizationName )
  //    }  
  //    if(profileData?.data.mobileNo !== values){
  //      formik.setFieldValue(
  //        "mobileNumber",
  //        profileData?.data.mobileNumber
  //      )
  //     //  setMobileNumbers(profileData?.data.mobileNumber );
 
  //    }  
  //    if(profileData?.data.city !== values){
  //     formik.setFieldValue(
  //       "city",
  //       profileData?.data.city
  //     )
  //    //  setMobileNumbers(profileData?.data.mobileNumber );

  //   }  
  //   if(profileData?.data.state !== values){
  //     formik.setFieldValue(
  //       "stateData",
  //       profileData?.data.state
  //     )
  //    //  setMobileNumbers(profileData?.data.mobileNumber );

  //   }
  //   if(profileData?.data.website !== values){
  //     formik.setFieldValue(
  //       "website",
  //       profileData?.data.website
  //     )
     //  setMobileNumbers(profileData?.data.mobileNumber );

  //   }
    
  //  };
  
  return (
    <React.Fragment>
      {/* <ToastContainer /> */}
      <div className="page-content sn-profile-page-main">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="row">
                    <div className="col-ms-12 co-sm-12">
                      <div className="text-center sn-profile-image-updated">
                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                          <div>
                            <img
                              src={profileImg || salesninjaicon}
                              className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                              alt="Profile"
                            />
                            <button
                              className="sn-profile-avatar-pic"
                              onClick={deleteImages}
                            >
                              <i className="ri-close-fill"></i>
                            </button>
                          </div>

                          <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <Input
                              id="profile-img-file-input"
                              className="profile-img-file-input"
                              type="file"
                              accept="image/*"
                              onChange={handleFileInputChange}
                            />

                            <Label
                              htmlFor="profile-img-file-input"
                              className="profile-photo-edit avatar-xs"
                            >
                              <span className="avatar-title rounded-circle bg-light text-body">
                                <i className="ri-camera-fill"></i>
                              </span>
                            </Label>
                          </div>
                        </div>
                        <h5 className="mb-1 color-white">{displayOrganizationName}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col xxl={9} className="m-auto">
              <Card className="sn-user-profile-update">
                <CardHeader className="profile-header-sn-tb">
                  <div>
                    <Nav
                      className="nav-tabs-custom rounded card-header-tabs border-bottom-0 sn-profile-user-top-icon"
                      role="tablist"
                    >
                      {update_type === "change-password" ? (
                        ""
                      ) : (
                        <NavItem className="sn-profile-header-back-btn">
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              tabChange("1");
                            }}
                          >
                            <i className="far fa-user"></i>
                            Personal
                          </NavLink>
                        </NavItem>
                      )}
                      {update_type === "personal" ? (
                        ""
                      ) : (
                        <NavItem>
                          <NavLink
                            to="#"
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              tabChange("2");
                            }}
                            type="button"
                          >
                            <i className="ri-lock-password-line"></i>
                            Change Password
                          </NavLink>
                        </NavItem>
                      )}
                      {update_type === "personal"
                        ? ""
                        : storedValue === "organization" && (
                            <NavItem>
                              <NavLink
                                to="#"
                                className={classnames({
                                  active: activeTab === "3",
                                })}
                                onClick={() => {
                                  tabChange("3");
                                }}
                                type="button"
                              >
                                <i
                                  className="ri-list-settings-line"
                                  style={{
                                    position: "relative",
                                    top: "3px",
                                    fontSize: "16px",
                                  }}
                                ></i>
                                Integration
                              </NavLink>
                            </NavItem>
                          )}
                    </Nav>
                  </div>
                  <div>
                    <div className="sn-backbtn">
                      <button
                        className="btn btn-sm border border-rounded shadow backbtn-main"
                        onClick={() => {
                          //  handlePin(); // Call handlePin function
                          navigate(-1); // Navigate back
                        }}
                      >
                        <i className="ri-arrow-left-line m-1"></i> Back
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form
                        onSubmit={formik.handleSubmit}
                        className="needs-validation"
                        action="#"
                      >
                        {update_type === "personal" ? null : (
                          <div className="row justify-content-between">
                            <div className="col-auto">
                              <h4 className="mb-0">Details</h4>
                            </div>
                            <div className="col-auto">
                              <Button
                                color="danger"
                                className="rounded-pill"
                                onClick={() => {
                                  navigate("/profile/update/personal");
                                }}
                              >
                                Update{" "}
                              </Button>
                            </div>
                          </div>
                        )}
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="organizationname"
                                className="form-label"
                              >
                                {storedValue === "employee"
                                  ? "Employee Name"
                                  : "Organization Name"}
                              </Label>

                              {update_type === "personal" ? (
                                <>
                                  {storedValue === "employee" && (
                                    <>
                                      <Input
                                        name="fullName"
                                        type="text"
                                        placeholder="Enter Employee Name"
                                        onChange={(e) => {
                                          const alphabeticValue =
                                            e.target.value.replace(
                                              /[^A-Za-z\s]/g,
                                              ""
                                            ); // Allow only alphabetic characters and spaces
                                          formik.setFieldValue(
                                            "fullName",
                                            alphabeticValue
                                          ); // Update the Formik field value
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fullName}
                                        invalid={
                                          formik.touched.fullName &&
                                          formik.errors.fullName
                                            ? true
                                            : false
                                        }
                                      />
                                      {formik.touched.fullName &&
                                      formik.errors.fullName ? (
                                        <FormFeedback type="invalid">
                                          <div>{formik.errors.fullName}</div>
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}

                                  {storedValue !== "employee" && (
                                    <>
                                      <Input
                                        name="organizationName"
                                        type="text"
                                        placeholder="Enter Organization Name"
                                        onChange={(e) => {
                                          const alphabeticValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                          // formik.setFieldValue("organizationName", alphabeticValue);
                                          setOrganizationName(alphabeticValue)
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.organizationName}
                                        invalid={
                                          formik.touched.organizationName &&
                                          formik.errors.organizationName
                                            ? true
                                            : false
                                        }
                                      />
                                      {formik.touched.organizationName &&
                                      formik.errors.organizationName ? (
                                        <FormFeedback type="invalid">
                                          <div>{formik.errors.organizationName}</div>
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              ) : (
                                <div
                                  type="text"
                                  className="form-control fw-bold border-0 bg-light"
                                  id="organizationname"
                                >
                                  {storedValue === "employee"
                                    ? profileData?.data?.fullName
                                    : profileData?.data?.organizationName}
                                </div>
                              )}
                            </div>
                          </Col>

                          {update_type === "personal" ? (
                            <></>
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="phonenumberInput"
                                  className="form-label"
                                >
                                  Organization ID
                                </Label>
                                <div
                                  type="text"
                                  className="form-control fw-bold border-0 bg-light"
                                  id="phonenumberInput"
                                >
                                  {profileData?.data?.organizationId || "-"}
                                </div>
                              </div>
                            </Col>
                          )}

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Email
                              </Label>
                              {update_type === "personal" ? (
                                <>
                                  <Input
                                    disabled
                                    type="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    invalid={
                                      formik.touched.email &&
                                      formik.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  {formik.touched.email &&
                                  formik.errors.email ? (
                                    <FormFeedback type="invalid">
                                      <div>{formik.errors.email}</div>
                                    </FormFeedback>
                                  ) : null}
                                </>
                              ) : (
                                <div
                                  type="email"
                                  className="form-control fw-bold border-0 bg-light"
                                  id="emailInput"
                                >
                                  {profileData?.data?.email || "-"}
                                </div>
                              )}
                            </div>
                          </Col>

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <>
                              <div className="col-md-6 col-sm-6 col-sm-12">
                                <div className="row">
                                  {update_type === "personal" ? (
                                    <Label
                                      htmlFor="zipcodeInput"
                                      className="form-label"
                                    >
                                      Select Country Code
                                    </Label>
                                  ) : (
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <Label
                                        htmlFor="zipcodeInput"
                                        className="form-label"
                                      >
                                        Country Code
                                      </Label>
                                    </div>
                                  )}
                                  {update_type === "personal" ? (
                                    <>
                                      <div className="sn-country-code-flag-dess mb-3">
                                        <PhoneInput
                                          name="mobileNumber"
                                          country="in"
                                          enableSearch={true}
                                        //   value={formik.values.mobileNumber}
                                          value={`${formik.values.countryCode}${formik.values.mobileNumber}`} // Use Formik values for countryCode and mobileNumber
                                          onChange={(value, data) =>
                                            handlePhoneChange(value, data)
                                          }
                                          onBlur={formik.handleBlur}
                                          className={
                                            formik.touched.mobileNumber &&
                                            formik.errors.mobileNumber
                                              ? "is-invalid"
                                              : ""
                                          }
                                        />
                                        {formik.touched.mobileNumber &&
                                          formik.errors.mobileNumber && (
                                            <div className="invalid-feedback">
                                              {formik.errors.mobileNumber}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  ) : (
                                    <div
                                      type="text"
                                      className="form-control fw-bold border-0 bg-light"
                                      minLength="5"
                                      maxLength="6"
                                      id="zipcodeInput"
                                    >
                                      {profileData?.data?.countryCode}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {update_type === "personal" ? (
                                ""
                              ) : (
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                  <div className="mb-3">
                                    <div className="mb-3">
                                      <Label
                                        htmlFor="mobilenumberInput"
                                        className="form-label"
                                      >
                                        Mobile Number
                                      </Label>

                                      <div
                                        type="text"
                                        className="form-control fw-bold border-0 bg-light"
                                        id="mobilenumberInput"
                                      >
                                        {profileData?.data?.mobileNumber || "-"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  ""
                                ) : (
                                  <Label
                                    htmlFor="roleInput"
                                    className="form-label"
                                  >
                                    Role
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  ""
                                ) : profileData?.data?.role.length > 0 ? (
                                  profileData?.data?.role.map((roles) => {
                                    return (
                                      <>
                                        <div
                                          type="text"
                                          className="form-control fw-bold border-0 bg-light"
                                          id="roleInput"
                                        >
                                          {roles || "-"}
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <>
                                    <div
                                      type="text"
                                      className="form-control fw-bold border-0 bg-light"
                                      id="roleInput"
                                    >
                                      -
                                    </div>
                                  </>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  ""
                                ) : (
                                  <Label
                                    htmlFor="teamInput"
                                    className="form-label"
                                  >
                                    Team
                                  </Label>
                                )}
                                {update_type === "personal"
                                  ? ""
                                  : profileData?.data?.team.map((temas) => {
                                      return (
                                        <>
                                          <div
                                            type="text"
                                            className="form-control fw-bold border-0 bg-light"
                                            id="teamInput"
                                          >
                                            {temas || "-"}
                                          </div>
                                        </>
                                      );
                                    })}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  ""
                                ) : (
                                  <Label
                                    htmlFor="registeredNameInput"
                                    className="form-label"
                                  >
                                    Registered Date
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="registeredInput"
                                  >
                                    {moment(
                                      profileData?.data?.createdAt
                                    ).format("DD  MMM  YYYY") || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  ""
                                ) : (
                                  <Label
                                    htmlFor="deviceNameInput"
                                    className="form-label"
                                  >
                                    Device Name
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="deviceNameInput"
                                  >
                                    {profileData?.data?.deviceName || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  ""
                                ) : (
                                  <Label
                                    htmlFor="deviceNameInput"
                                    className="form-label"
                                  >
                                    App Version
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="versionInput"
                                  >
                                    {profileData?.data?.appVersion || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  ""
                                ) : (
                                  <Label
                                    htmlFor="callrecordingInput"
                                    className="form-label"
                                  >
                                    Call Recording Sync
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="callrecordingInput"
                                  >
                                    {profileData?.timestap || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  ""
                                ) : (
                                  <Label
                                    htmlFor="lastcallrecordingInput"
                                    className="form-label"
                                  >
                                    Last Call Sync
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="lastcallrecordingInput"
                                  >
                                    {profileData?.lastcallsync || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <>
                              {/* <Col lg={6} > */}
                              {/* <div className="mb-3"> */}

                              {update_type === "personal" ? (
                                <>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label
                                        htmlFor="pincode"
                                        className="form-label"
                                      >
                                        Pin Code
                                      </Label>
                                      <Input
                                        type="tel"
                                        name="pincode"  
                                        placeholder="Enter postal No..."

                                           onChange={(e) => {
                                            const value = e.target.value; 
                                            formik.handleChange(e);
                                            if (value.length <= 12) {
                                            
                                            setPostal(value);
                                        }
                                       }}
                                          
                                          
                                        
                                        onBlur={formik.handleBlur}
                                        value={formik.values.pincode}
                                        maxLength="10" 
                                        invalid={
                                          formik.touched.pincode &&
                                          formik.errors.pincode
                                            ? true
                                            : false
                                        }
                                      />
                                      {formik.touched.pincode &&
                                      formik.errors.pincode ? (
                                        <FormFeedback type="invalid">
                                          <div>{formik.errors.pincode}</div>
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </>
                              ) : (
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="pincode"
                                      className="form-label"
                                    >
                                      Pin Code
                                    </Label>

                                    <div
                                      type="text"
                                      className="form-control fw-bold border-0 bg-light"
                                      id="pincodeInput"
                                    >
                                      {profileData?.data?.pincode || "-"}
                                    </div>
                                  </div>
                                </Col>
                              )}
                              {/* </div> */}
                              {/* </Col> */}
                            </>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  <Label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Select Country
                                  </Label>
                                ) : (
                                  <Label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Country{" "}
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  <>
                                    <select
                                      className="form-select"
                                      onChange={handleCountry}
                                      name="country"
                                    >
                                      <option selected value={country}>
                                        {country}
                                      </option>
                                      {countries.map((ele, key) => {
                                        // if (country === ele?.name) {
                                        //     return null;
                                        // }
                                        return (
                                          <option key={key} value={ele}>
                                            {ele}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="countryInput"
                                  >
                                    {profileData?.data?.country}
                                  </div>
                                )}
                              </div>
                            </Col>

                            // <Col lg={6}>
                            //     <div className="mb-3">
                            //         <Label htmlFor="stateinput" className="form-label">
                            //             State
                            //         </Label>
                            //         {update_type === "personal" ? (
                            //             <>
                            //                 <Input
                            //                     type="text"
                            //                     name="stateData"
                            //                     placeholder="Enter State Name..."
                            //                     onChange={formik.handleChange}
                            //                     onBlur={formik.handleBlur}
                            //                     value={formik.values.stateData}
                            //                     invalid={
                            //                         formik.touched.stateData &&
                            //                             formik.errors.stateData
                            //                             ? true
                            //                             : false
                            //                     }
                            //                 />
                            //                 {formik.touched.stateData &&
                            //                     formik.errors.stateData ? (
                            //                     <FormFeedback type="invalid">
                            //                         <div>{formik.errors.stateData}</div>
                            //                     </FormFeedback>
                            //                 ) : null}
                            //             </>
                            //         ) : (
                            //             <div
                            //                 type="text"
                            //                 className="form-control fw-bold border-0 bg-light"
                            //                 id="state"
                            //             >
                            //                 {profileData?.data?.state}
                            //             </div>
                            //         )}
                            //     </div>
                            // </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="city" className="form-label">
                                {city === "Other" ? "Other City" : "Select City"}
                              </Label>
                              {update_type === "personal" ? (
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      if (city === "Other") {
                                        // If "Other" is selected, set the custom city value
                                        setCustomCity(e.target.value);
                                        formik.setFieldValue("city", e.target.value);
                                      } else {
                                        // Otherwise, set the city value
                                        setCity(e.target.value);
                                        formik.setFieldValue("city", e.target.value);
                                        handleCitySearch(e.target.value); // Handle search results
                                      }
                                    }}
                                    name="city"
                                    value={city === "Other" ? customCity : city} // Use customCity if "Other" is selected
                                    placeholder={city === "Other" ? "Enter other city..." : "Search city..."}
                                  />
                                  {searchResults && searchResults.length > 0 && (
                                    <div
                                      className="position-absolute w-100 bg-white border rounded mt-1"
                                      style={{ zIndex: 1000, maxHeight: '200px', overflowY: 'auto' }}
                                    >
                                      {searchResults.map((cityName, index) => (
                                        <div
                                          key={index}
                                          className="p-2 cursor-pointer hover:bg-light"
                                          onClick={() => {
                                            if (cityName === "Other") {
                                              setCity("Other"); // If "Other" is clicked, switch to custom input
                                              setCustomCity(""); // Clear the custom city input (optional)
                                              formik.setFieldValue("city", "");
                                            } else {
                                              setCity(cityName); // Otherwise, set the selected city
                                              formik.setFieldValue("city", cityName);
                                            }
                                            setSearchResults(null); // Hide search results
                                          }}
                                        >
                                          {cityName}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="form-control fw-bold border-0 bg-light" id="cityInput">
                                  {profileData?.data?.city}
                                </div>
                              )}
                            </div>
                          </Col>
                          
                          
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="stateinput"
                                  className="form-label"
                                >
                                  State
                                </Label>
                                {update_type === "personal" ? (
                                  <>
                                    <Input
                                      type="text"
                                      name="stateData"
                                      placeholder="Enter State Name..."
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        setStateData(e.target.value);
                                       }}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.stateData}
                                      invalid={
                                        formik.touched.stateData &&
                                        formik.errors.stateData
                                          ? true
                                          : false
                                      }
                                    />
                                    {formik.touched.stateData &&
                                    formik.errors.stateData ? (
                                      <FormFeedback type="invalid">
                                        <div>{formik.errors.stateData}</div>
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="state"
                                  >
                                    {profileData?.data?.state}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="IndustryInput"
                                  className="form-label"
                                >
                                  Industry Type
                                </Label>
                                {update_type === "personal" ? (
                                  <>
                                    <select
                                      id="industryType"
                                      className="form-select"
                                    >
                                      <i className="bi bi-chevron-down"></i>
                                      <option selected value={industryType}>
                                        {industryType}
                                      </option>

                                      {(
                                        industryTypeData?.industryType || []
                                      )?.map((ele, index) => {
                                        if (industryType === ele) {
                                          return null;
                                        }
                                        return (
                                          <>
                                            <option key={ele} value={ele}>
                                              {ele}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="Industryinput"
                                  >
                                    {profileData?.data?.industryType}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="cityInput"
                                  className="form-label"
                                >
                                  Website Url
                                </Label>
                                {update_type === "personal" ? (
                                  <>
                                    <Input
                                      type="text"
                                      className="form-label"
                                      name="website"
                                      value={websites}
                                      onChange={handleWebInputChange}
                                    />
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="webInput"
                                  >
                                    {profileData?.data?.website
                                      ? profileData?.data?.website
                                      : "www.salesNinja.com"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {update_type === "personal" ? (
                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <Button
                                  type="submit"
                                  className="btn btn-danger"
                                >
                                  Update
                                </Button>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      </Form>
                    </TabPane>

                    <ChangePassword />

                    <TabPane tabId="3">
                      <form>
                        <div id="newlink">
                          <div id="1">
                            <Row className="mb-4">
                              <Col lg={1}>
                                <div className="mb-3 text-center">
                                  <img
                                    src={freshdeskimg}
                                    style={{ width: "50px" }}
                                    alt=""
                                  />
                                </div>
                              </Col>

                              <Col lg={8}>
                                <div className="mb-3">
                                  {/* <h5>Description</h5> px*/}
                                  <p style={{ fontSize: "16px" }}>
                                    Integrate your Freshdesk support system with
                                    Sales Ninja to automatically log call
                                    details and recordings into open Freshdesk
                                    tickets for streamlined tracking.
                                  </p>
                                </div>
                              </Col>

                              <Col lg={3}>
                                <div className="flex-shrink-0">
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Enable Freshdesk Integration
                                  </h6>
                                  <div className="form-check form-switch sn-profile-intrigrate-toggle">
                                    <div>
                                      <Input
                                        className="form-check-input large-checkbox"
                                        type="checkbox"
                                        role="switch"
                                        id="directMessage"
                                        checked={isChecked}
                                        onChange={handleToggle}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            {isChecked && (
                              <Row className="mt-4">
                                <div className="col-md-6 col-sm-6 mt-4">
                                  <label className="form-label">
                                    Freshdesk Domain URL
                                  </label>
                                  <div>
                                    <Input
                                      type="url"
                                      className="form-control"
                                      placeholder="URL"
                                      value={url}
                                      onChange={(e) => setUrl(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6 mt-4">
                                  <label className="form-label">
                                    Freshdesk API Key
                                  </label>
                                  <div className="mb-4">
                                    <InputGroup>
                                      <Input
                                        type={
                                          isPasswordVisible
                                            ? "text"
                                            : "password"
                                        }
                                        className="form-control"
                                        placeholder={
                                          FresdeskStatus === true
                                            ? "*******"
                                            : "API Key"
                                        }
                                        value={apiKey}
                                        onChange={(e) =>
                                          setApiKey(e.target.value)
                                        }
                                      />
                                      <InputGroupText
                                        onClick={togglePasswordVisibility}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {isPasswordVisible ? (
                                          <FaEyeSlash />
                                        ) : (
                                          <FaEye />
                                        )}
                                      </InputGroupText>
                                    </InputGroup>
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6 mt-2">
                                  <Col lg={12}>
                                    <div className="hstack gap-2">
                                      <button
                                        type="button"
                                        className="btn"
                                        style={{ background: "#ccc" }}
                                        onClick={handleTestConnection}
                                      >
                                        Test Connection
                                      </button>
                                      {showChnage === true && (
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={saveChange}
                                        >
                                          Save Changes
                                        </button>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </Row>
                              // <Row className="mt-4">
                              //     <div className="col-md-6 col-sm-6 mt-4">
                              //         <label className="form-label form-label">
                              //             Freshdesk Domain URL
                              //         </label>
                              //         <div>
                              //             <Input
                              //                 type="url"
                              //                 className="form-control"
                              //                 id="passwordInput"
                              //                 placeholder="Url"
                              //             />
                              //         </div>
                              //     </div>
                              //     <div className="col-md-6 col-sm-6 mt-4">
                              //         <label className="form-label form-label">
                              //             Freshdesk API Key
                              //         </label>
                              //         <div className="mb-4">
                              //             <InputGroup>
                              //                 <Input
                              //                     type={
                              //                         isPasswordVisible
                              //                             ? "text"
                              //                             : "password"
                              //                     } // Toggle between "text" and "password"
                              //                     className="form-control"
                              //                     id="passwordInput"
                              //                     placeholder="API Key"
                              //                 />
                              //                 <InputGroupText
                              //                     onClick={togglePasswordVisibility}
                              //                     style={{cursor: "pointer"}}
                              //                 >
                              //                     {isPasswordVisible ? (
                              //                         <FaEyeSlash />
                              //                     ) : (
                              //                         <FaEye />
                              //                     )}{" "}
                              //                     {/* Show icon based on state */}
                              //                 </InputGroupText>
                              //             </InputGroup>
                              //         </div>
                              //     </div>

                              //     <div className="col-md-6 col-sm-6 mt-2">
                              //         <Col lg={12}>
                              //             <div className="hstack gap-2">
                              //                 <button
                              //                     type="submit"
                              //                     className="btn"
                              //                  style={{background:'#ccc'}}>
                              //                     Test Connection
                              //                 </button>
                              //                 <Link to="#" className="btn btn-danger">
                              //                     Save Change
                              //                 </Link>
                              //             </div>
                              //         </Col>
                              //     </div>
                              // </Row>
                            )}
                          </div>
                        </div>
                        <div id="newForm" style={{ display: "none" }}></div>
                      </form>
                    </TabPane>

                    <TabPane tabId="4">
                      <div className="mb-4 pb-2">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Security:
                        </h5>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                          <div className="flex-grow-1">
                            <h6 className="fs-15 mb-1">
                              Two-factor Authentication
                            </h6>
                            <p className="text-muted">
                              Two-factor authentication is an enhanced security
                              meansur. Once enabled, you'll be required to give
                              two types of identification when you log into
                              Google Authentication and SMS are Supported.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Enable Two-facor Authentication
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                          <div className="flex-grow-1">
                            <h6 className="fs-15 mb-1">
                              Secondary Verification
                            </h6>
                            <p className="text-muted">
                              The first factor is a password and the second
                              commonly includes a text with a code sent to your
                              smartphone, or biometrics using your fingerprint,
                              face, or retina.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Set up secondary method
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                          <div className="flex-grow-1">
                            <h6 className="fs-15 mb-1">Backup Codes</h6>
                            <p className="text-muted mb-sm-0">
                              A backup code is automatically generated for you
                              when you turn on two-factor authentication through
                              your iOS or Android Twitter app. You can also
                              generate a backup code on twitter.com.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Generate backup codes
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Application Notifications:
                        </h5>
                        <ul className="list-unstyled mb-0">
                          <li className="d-flex">
                            <div className="flex-grow-1">
                              <label
                                htmlFor="directMessage"
                                className="form-check-label fs-15"
                              >
                                Direct messages
                              </label>
                              <p className="text-muted">
                                Messages from people you follow
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="directMessage"
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-15"
                                htmlFor="desktopNotification"
                              >
                                Show desktop notifications
                              </Label>
                              <p className="text-muted">
                                Choose the option you want as your default
                                setting. Block a site: Next to "Not allowed to
                                send notifications," click Add.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="desktopNotification"
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-15"
                                htmlFor="emailNotification"
                              >
                                Show email notifications
                              </Label>
                              <p className="text-muted">
                                {" "}
                                Under Settings, choose Notifications. Under
                                Select an account, choose the account to enable
                                notifications for.{" "}
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="emailNotification"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-15"
                                htmlFor="chatNotification"
                              >
                                Show chat notifications
                              </Label>
                              <p className="text-muted">
                                To prevent duplicate mobile notifications from
                                the Gmail and Chat apps, in settings, turn off
                                Chat notifications.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="chatNotification"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-15"
                                htmlFor="purchaesNotification"
                              >
                                Show purchase notifications
                              </Label>
                              <p className="text-muted">
                                Get real-time purchase alerts to protect
                                yourself from fraudulent charges.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="purchaesNotification"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h5 className="card-title text-decoration-underline mb-3">
                          Delete This Account:
                        </h5>
                        <p className="text-muted">
                          Go to the Data & Privacy section of your profile
                          Account. Scroll to "Your data & privacy options."
                          Delete your Profile Account. Follow the instructions
                          to delete your account :
                        </p>
                        <div>
                          <Input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder="Enter your password"
                            defaultValue="make@321654987"
                            style={{ maxWidth: "265px" }}
                          />
                        </div>
                        <div className="hstack gap-2 mt-3">
                          <Link to="#" className="btn btn-soft-danger">
                            Close & Delete This Account
                          </Link>
                          <Link to="#" className="btn btn-light">
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
