/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { ACTIVE_INACTIVE_EMPLOYEE, EMPLOYEE_ACTIVE_INACTIVE_EMPLOYEE, ENPLOYEE_GET_EMPLOYEE_LIST, GET_EMPLOYEES_LIST } from '../../../helpers/url_helper';
import RoleAssign from './RoleAssign';
import moment from 'moment';
// import CommanDataTable from '../../../Components/Common/DataTable';
import { APIClient } from '../../../helpers/api_helper';
import Toast from '../../../Components/Common/Toast';
import { ToastContainer } from 'react-toastify';
import { HandleErrorMessage } from '../../../Components/Common/HandleErrorMessage';
import ViewModal from './ViewModal';
import { toCamelCase } from '../../../Components/Common/ToCamelCase';
import { useNavigate } from 'react-router-dom';
import { useProfileData } from '../../../Components/Hooks/UserHooks';
import SubExpired from '../../../Components/Common/subExpired';
import { CommanDataTables } from '../../../Components/Common/DashboardDataTbale';
import { exportToExcel, exportToPDF } from '../../../Components/Common/exportUtils';

const api = new APIClient();


const UserManagement = () => {
    const reportTitle = "EMPLOYEE MANAGEMENT";
    const navigate = useNavigate();
    const { profileData } = useProfileData();

    const [isLoading, setIsLoading] = useState(false);
    const [remainingDays, setRemainingDays] = useState(null);
    // Pagination settings
    const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);

    const permissionType = JSON.parse(localStorage.getItem("permission"));
    const loginType = JSON.parse(localStorage.getItem("logintype"));
    const permission = permissionType?.includes("GET_EMPLOYEES");
    const updatedPermission = permissionType?.includes("UPDATE_EMPLOYEE");
    const createPermission = permissionType?.includes("CREATE_EMPLOYEE");
    const activePermission = permissionType?.includes("UPDATE_EMPLOYEE_STATUS");

    //search filed use in
    const [searchText, setSearchText] = useState("");

    // filter data
    const [filterData, setFilteredData] = useState([]);

    const [countryCode, setCountryCode] = useState("")
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [employeeId, setEmployeeId] = useState();
    const [mobileNumber, setMobileNumber] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [address, setAddress] = useState("");
    const [role, setRole] = useState("");
    const [team, setTeam] = useState("");
    const [dateOfJoining, setDateOfJoining] = useState("");
    const [modal_grid, setmodal_grid] = useState(false);

    // view data
    const [view_modal_grid, setView_modal_grid] = useState(false);
    const [viewData, setViewData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [createmodal_grid, setCreatemodal_grid] = useState(false);
    const [createAssignmodal_grid, setCreateAssignmodal_grid] = useState(false);
    const [assignRole, setAssignRole] = useState({});


    const handleCheckboxChange = async (row) => {
        const newStatus = row.status === "Active" ? "Inactive" : "Active";
        try {
            let response;
            if (loginType === "employee" && activePermission) {
                response = await api.patch(`${EMPLOYEE_ACTIVE_INACTIVE_EMPLOYEE}`, {
                    status: newStatus,
                    employeeId: row._id,
                });
            } else if (loginType === "organization") {
                response = await api.patch(`${ACTIVE_INACTIVE_EMPLOYEE}`, { status: newStatus, employeeId: row._id });
            }
            if (response.success && response.success === true) {
                Toast(response?.message, "success");
                setEmployeeData((prevRows) =>
                    prevRows.map((item) => (item._id === row._id ? { ...item, status: newStatus } : item))
                );
            }
        } catch (error) {
            HandleErrorMessage(error);
        }
    };


    const column = [
        {
            name: <span className='font-weight-bold fs-13'> SN.</span>,
            selector: (row, index) => index + 1,
            width: "55px",
        },

        {
            name: <span className="font-weight-bold fs-13">Name</span>,
            selector: (row) => toCamelCase(row.fullName),
            cell: (row) => (
                <span style={{ textTransform: "capitalize" }}>
                    {`${row.fullName}`}
                    <br />
                    ({row?.countryCode}) {row.mobileNumber}
                </span>
            ),
            sortable: true,
        },

        {
            name: <span className="font-weight-bold fs-13">Email</span>,
            selector: (row) => row.email,
            cell: (row) => <span className="sn-managment-team-scroll-tble">{row.email}</span> || "-",
            sortable: true,
        },

        {
            name: <span className="font-weight-bold fs-13">Designation</span>,
            selector: (row) => row.jobTitle,
            cell: (row) => <span className="sn-managment-team-scroll-tble">{row.jobTitle}</span> || "-",
            sortable: true,
        },

        {
            name: <span className="font-weight-bold fs-13">Role Name</span>,
            selector: (row) => row.role,
            cell: (row) =>
                (
                    <div style={{ whiteSpace: "pre-line", textAlign: "left" }}>
                        {row?.role?.map((roleItem, index) => (
                            <React.Fragment key={index}>
                                <span>
                                    {roleItem}&nbsp;
                                    {index !== row.role.length - 1 && <>, </>}
                                </span>
                            </React.Fragment>
                        ))}
                    </div>
                ) || "-",
            sortable: true,
        },

        {
            name: <span className="font-weight-bold fs-13">Team</span>,
            selector: (row) => row.team,
            cell: (row) => (
                <div className="mt-2 mb-2">
                    {row?.team && row.team.length > 0 ? (
                        row.team.map((callTeam, index) => (
                            <React.Fragment key={index}>
                                <span style={{ textTransform: "capitalize", textAlign: "left", float: "left" }}>
                                    {callTeam}
                                </span>
                                <br />
                                {index !== row.team.length - 1 && <br />}
                            </React.Fragment>
                        ))
                    ) : (
                        <span>-</span>
                    )}
                </div>
            ),
            sortable: true,
        },


        {
            name: <span className="font-weight-bold fs-13">Registered Date</span>,
            selector: (row) => moment(row?.createdAt).format("DD  MMM  YYYY"),
            sortable: true,
        },


        {
            name: <span className="font-weight-bold fs-13">Device Name</span>,
            selector: (row) => row.deviceName || "-",
            // sortable: true,
        },

        ...(((activePermission && loginType === 'employee') || (loginType === 'organization')) ? [
            {
                name: <span className='font-weight-bold fs-13'>Status</span>,
                selector: row => row.status,
                cell: row => <div className="form-check form-switch form-switch-success mb-3 mt-3">
                    <Input checked={row.status === 'Active' ? 'Active' : ''} className="form-check-input" type="checkbox" role="switch" id={`SwitchCheck_${row._id}`} onChange={() => handleCheckboxChange(row)} />
                    <Label className="form-check-label" for={`SwitchCheck_${row._id}`}>
                        {row.status === 'Active' ? 'Active' : 'Inactive'}
                    </Label>
                </div>,
                sortable: true,
            }] : []),

        {
            name: <span className="font-weight-bold fs-13">Action</span>,
            cell: (row) => (
                <>
                    <div className="hstack gap-2 sn-button-eye-edit-user">
                        <button className="btn btn-sm btn-soft-primary remove-list" onClick={() => viewModal(row)}>
                            <i className="mdi mdi-eye-outline align-bottom"></i>
                        </button>
                        {((loginType === "employee" && updatedPermission) || loginType === "organization") && (
                            <button className="btn btn-sm btn-soft-success edit-list" onClick={() => showModal(row)}>
                                <i className="ri-pencil-fill align-bottom" />
                            </button>
                        )}
                        {loginType === "organization" && (
                            <button
                                className="btn btn-sm btn-soft-info edit-item-btn"
                                onClick={() => showAssignModal(row)}
                            >
                                <i className="ri-user-follow-line align-bottom"></i>
                            </button>
                        )}
                    </div>
                </>
            ),
        },
    ];

    const showAssignModal = (value) => {
        setAssignRole({
            id: value?._id,
            assignRoleName: value,
        });
        setCreateAssignmodal_grid(true);
    };

    // UPDATE EMPLOYEE
    const showModal = async (row) => {
        setCountryCode(row?.countryCode)
        setEmail(row?.email);
        setUsername(row?.fullName);
        setEmployeeId(row?._id);
        setMobileNumber(row?.mobileNumber);
        setJobTitle(row?.jobTitle);
        setAddress(row?.address);
        setRole(row?.role);
        setTeam(row?.team);
        setDateOfJoining(row?.dateOfJoining);
        setmodal_grid(true);
        // try {
        //     let data = { _id: row };
        //     let response
        //     if (loginType === 'employee' && updatedPermission) {
        //         response = await api.create(`${EMPLOYEE_GET_EMPLOYEE_ID}`, data);
        //     } else if (loginType === 'organization') {
        //         response = await api.create(`${GET_EMPLOYEE_ID}`, data);
        //     }
        //     if (response && response?.success === true) {
        //         setEmail(response?.data?.email)
        //         setUsername(response?.data?.fullName);
        //         setEmployeeId(response?.data?._id);
        //         setMobileNumber(response?.data?.mobileNumber);
        //         setJobTitle(response?.data?.jobTitle);
        //         setAddress(response?.data?.address);
        //         setRole(response?.data?.role);
        //         setTeam(response?.data?.team);
        //         setDateOfJoining(response?.data?.dateOfJoining);
        //     }
        // } catch (error) {
        //     HandleErrorMessage(error);
        // }
    };

    const viewModal = async (row) => {
        setViewData(row);
        setView_modal_grid(true);
    };

    // ADD EMPLOYEE
    const createModal = () => {
        setCreatemodal_grid(true);
    };

    useEffect(() => {
        const calculateRemainingDays = () => {
            if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
                const endDate = new Date(profileData?.subscriptionEndDate);
                const startDate = new Date(profileData?.subscriptionStartDate);
                const currentDate = new Date();

                const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
                const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
                setRemainingDays(remainingDays > 0 ? remainingDays : 0);
                const interval = setInterval(() => {
                    const newCurrentDate = new Date();
                    const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
                    setRemainingDays(newRemainingDays);
                }, 1000 * 60 * 60 * 24);

                return () => clearInterval(interval);
            } else {
                setRemainingDays(null);
            }
        };

        calculateRemainingDays();
    }, [profileData]);


    useEffect(() => {
        GetCreateData()
    }, [])



    // GET EMPLOYEE LIST API CALL
    const GetCreateData = async () => {
        setIsLoading(true);
        try {
            let response;
            if (loginType === "employee" && permission) {
                response = await api.get(`${ENPLOYEE_GET_EMPLOYEE_LIST}`);
            }
            if (loginType === "organization") {
                response = await api.get(`${GET_EMPLOYEES_LIST}`);
            }
            if (response.success && response.success === true) {
                setIsLoading(false)
                const apiResponse = response.data.data
                const transformedData = apiResponse?.map((item, index) => ({
                    serialNumber: index + 1,
                    designations: item?.jobTitle || '-',
                    roles: item?.role || '-',
                    role: item?.role || '-',
                    countryCode: item?.countryCode || '-',
                    date: moment(item?.createdAt).format('DD  MMM  YYYY'),
                    ...item,
                }));
                setEmployeeData(transformedData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const filtered = (employeeData || []).filter((item) =>
            Object.values(item).some((value) =>
                value?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [employeeData, searchText]);




    const handleClose = () => {
        setIsExpiredModalOpen(false);
        navigate("/planPackage");
    };

    const handleNavigation = () => {
        navigate("/app-version");
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };


    // headers in excel and pdf
    const headers = [
        { label: "SN.", key: "serialNumber" },
        { label: "Employee Name", key: "fullName" },
        { label: "Employee Number", key: "mobileNumber" },
        { label: "Email", key: "email" },
        { label: "Designation", key: "designations" },
        { label: "Role Name", key: "role" },
        { label: "Team", key: "team" },
        { label: "Registered Date", key: "date" },
        { label: "Device Name", key: "deviceName" },
        { label: "Status", key: "status" },
    ];


    const handleExcelExport = async () => {
        await exportToExcel(employeeData, headers, reportTitle,"","Employee Management")
    }

    // Pdf Export 
    const handlePDFExport = () => {
        exportToPDF(employeeData, headers, "UserManagement.pdf", "User Management", "UserManagement");
    };


    return (
        <React.Fragment>
            <ToastContainer />
            {remainingDays === 0 && isExpiredModalOpen && (
                <SubExpired
                    onClose={handleClose}
                    profileData={profileData?.selectedPlanDetails?.planName}
                    remainingDays={remainingDays}
                />
            )}
            <div className="page-content sn-employees-managment">
                <Container fluid>
                    <BreadCrumb title="EMPLOYEE MANAGEMENT" pageTitle="" />
                    <Row>
                        <Col lg={12}>
                            <Card className='h-100' style={{ marginBottom: '20px' }}>
                                {((loginType === "employee" && createPermission) || (loginType === "organization")) && <CardHeader className='d-flex justify-content-between'>
                                    <Button className="btn btn-danger" onClick={createModal}>
                                        <i className="ri-add-fill me-1 align-bottom"></i> Add Employee
                                    </Button>
                                </CardHeader>}
                                <a style={{ marginRight: "20px !important", marginTop: "20px !important", fontSize: "16px" }} className=" ms-auto me-4 mt-2 link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" onClick={handleNavigation}>
                                    <i className=" me-1 align-bottom text-danger stretched-link  "></i> App Version
                                </a>
                                <CardBody className='tbl-user-manage-ui-sn1 sn-team-managment-tble employee-managment-table-responsive pt-0'>

                                    <div className="sales-ninja-tble-export-btn call-logs-report-export-exel" style={{ position: 'relative', top: '40px' }}>
                                        {" "} <Button
                                            onClick={handleExcelExport}
                                            className="custom-button tble-export1"
                                        >
                                            <i className="ri-file-excel-line"></i>
                                        </Button>{" "}
                                        &nbsp;
                                        <Button
                                            onClick={handlePDFExport}
                                            className="custom-button ml-2 tble-export2"
                                        >
                                            <i className="ri-file-pdf-line"></i>
                                        </Button>
                                    </div>

                                    <CommanDataTables
                                        isLoading={isLoading}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        column={column}
                                        tableData={filterData}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col></Col>
                    </Row>
                    <ViewModal
                        viewData={viewData}
                        view_modal_grid={view_modal_grid}
                        setView_modal_grid={setView_modal_grid}
                    />
                    <RoleAssign
                        GetCreateData={GetCreateData}
                        assignRole={assignRole}
                        createAssignmodal_grid={createAssignmodal_grid}
                        setCreateAssignmodal_grid={setCreateAssignmodal_grid}
                    />

                    <CreateUser
                        loginType={loginType}
                        createPermission={createPermission}
                        GetCreateData={GetCreateData}
                        createmodal_grid={createmodal_grid}
                        setCreatemodal_grid={setCreatemodal_grid}
                    />
                    <UpdateUser
                        countryCodes={countryCode}
                        email={email}
                        updatedPermission={updatedPermission}
                        loginType={loginType}
                        employeeId={employeeId}
                        team={team}
                        address={address}
                        role={role}
                        designationRole={jobTitle}
                        mobileNumber={mobileNumber}
                        dateOfJoining={dateOfJoining}
                        username={username}
                        modal_grid={modal_grid}
                        setmodal_grid={setmodal_grid}
                        GetCreateData={GetCreateData}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserManagement;
