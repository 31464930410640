import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { DashboardFillter } from "./DashboardSection";
import { APIClient } from "../../helpers/api_helper";
import { DashboardEmployeeSummary } from "../../Components/Common/Summary";
import tableimg from "../../../src/assets/images/perfarmanceTable.png";
import { ToastContainer } from "react-toastify";
import {
  DASHBOARD_GRAPH_DATA,
  DASHBOARD_PIE_CHART,
  EMPLOYEE_CALL_HOURS_GRAPH,
  EMPLOYEE_DATATABLE,
} from "../../helpers/url_helper";
import { ApexChart, MostActiveHourByCalls, OverallCallPieChart, OverallCallsVolume } from "../Charts/ChartsJs/ChartsJs";
import { formatDuration } from "../../Components/Common/convertTimestampToTime";
import { getInitialDates } from "../../Components/Common/DefaultDateSet";
import Loader from "../../Components/Common/Loader";
import { DashboardDataTable } from "../../Components/Common/DashboardDataTbale";
import { Tooltip } from "reactstrap";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import SubExpired from "../../Components/Common/subExpired";
import { useNavigate } from "react-router-dom";
import warningimg from "../../assets/images/remove.png";
import useractive from "../../assets/images/accept.png";
import { getRelativeTime, letterColors } from "../../Components/Common/ToCamelCase";
import { Button } from "antd";
import TopPerformanceEmployee from "./TopPerformanceEmployee";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { exportToExcel } from "../../Components/Common/exportUtils";


const api = new APIClient();


const DashboardCallCrm = () => {
  const navigate = useNavigate();
  const storedValue = JSON.parse(localStorage.getItem("logintype"));
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const loginType = JSON.parse(localStorage.getItem("logintype"));
  const BooleanWithSingleEmployeeLogin = ((loginType === "employee") && (permissionType.length === 0))

  const { profileData } = useProfileData();

  // flatePicker Date
  const [selectedDates, setSelectedDates] = useState(getInitialDates());

  //state
  const [piechartData, setPieChartData] = useState([]);
  const [piechartsummaryData, setPieChartSummaryData] = useState([]);

  // Pagination settings
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeData, setEmployeeTableData] = useState([]);
  const [teamCount, setTeamCount] = useState(0);

  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  const [teamIndu, setTeamIndu] = useState([]);
  const [EmployeeDefaultWithoutTeam, setEmployeeDefaultWithoutTeam] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphCallData, setGraphCallData] = useState([]);
  const [EmployeeDefault, setEmployeeDefault] = useState([]);

  const [tooltipOpenAvg, setTooltipAvg] = useState(false);
  const [remainingDays, setRemainingDays] = useState(null);

  const [datePicker, setDatePicker] = useState();
  //loading
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);

  const [tooltipOpenUnique, setTooltipOpen] = useState(false);
  const toggleUnique = () => setTooltipOpen(!tooltipOpenUnique);

  const [tooltipOpenDuration, setTooltipDuration] = useState(false);
  const toggleDuration = () => setTooltipDuration(!tooltipOpenDuration);

  const [tooltipOpenAverage, setTooltipAverage] = useState(false);
  const toggleAverage = () => setTooltipAverage(!tooltipOpenAverage);

  const [tooltipOpenIncoming, setTooltipIncoming] = useState(false);
  const toggleIncoming = () => setTooltipIncoming(!tooltipOpenIncoming);

  const [tooltipOpenOutgoing, setTooltipOutgoing] = useState(false);
  const toggleOutgoing = () => setTooltipOutgoing(!tooltipOpenOutgoing);

  const [tooltipOpenMissed, setTooltipMissed] = useState(false);
  const toggleMissed = () => setTooltipMissed(!tooltipOpenMissed);

  const [tooltipOpenRejected, setTooltipRejected] = useState(false);
  const toggleRejected = () => setTooltipRejected(!tooltipOpenRejected);

  const [tooltipOpenNotClient, setTooltipNotClient] = useState(false);
  const toggleNotClient = () => setTooltipNotClient(!tooltipOpenNotClient);

  const [tooltipOpenConnected, setTooltipConnected] = useState(false);
  const toggleConnected = () => setTooltipConnected(!tooltipOpenConnected);

  const [tooltipOpenAgent, setTooltipAgent] = useState(false);

  const toggleAgent = () => setTooltipAgent(!tooltipOpenAgent);
  const toggleAvg = () => setTooltipAvg(!tooltipOpenAvg);

  const [tooltipOpenTotalCall, setTooltipOpenTotalCall] = useState(false);
  const [isFirstEffectRun, setIsFirstEffectRun] = useState(false);
  const [IsShowEffectRun, setIsShowEffectRun] = useState(false);


  // Toggle function to change the tooltip state
  const toggleTotalCall = () => {
    setTooltipOpenTotalCall(!tooltipOpenTotalCall);
  };

  // Step 1: Declare state for the tooltip
  const [tooltipOpenActiveee, setTooltipOpenActiveee] = useState(false);

  // Step 2: Define the toggle function
  const toggleActiveee = () => setTooltipOpenActiveee(!tooltipOpenActiveee);
  const [tooltipOpenLogiTech, setTooltipOpenLogiTech] = useState(false);
  const toggleLogiTech = () => setTooltipOpenLogiTech(!tooltipOpenLogiTech);


  const columns = [
    {
      name: <span> SN.</span>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "55px",
    },

    {
      name: <span>Agent Name</span>,
      sortable: true,
      selector: (row) => row?.fullName,
      width: "220px",
      cell: (row) => (
        <div
          className="d-flex justify-content-between align-items-center mt-2 mb-2"
          style={{ width: "100%" }}
        >
          <div className="d-flex align-items-center">
            {row?.img ? (
              <>
                <img
                  src={row.img}
                  alt="Profile"
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    marginRight: "10px",
                    // border:'2px solid #00ba00'
                  }}
                />{" "}
                {row?.todayActiveStatus === 0 ? (
                  <img
                    className="userstatus-onoff"
                    src={warningimg}
                    alt="Warning"
                    style={{ left: "40px", bottom: "8px" }}
                  />
                ) : (
                  <img
                    className="userstatus-onoff"
                    src={useractive}
                    alt="Warning"
                    style={{ left: "40px", bottom: "8px" }}
                  />
                )}
              </>
            ) : (
              <div className="position-relative d-inline-block sn-tble-profile-dp">
                <div
                  className="rounded-circle img-thumbnail"
                  style={{ background: letterColors[row?.firstLetter] }}
                >
                  <span style={{ color: "#fff" }}>{row?.firstLetter}</span>
                </div>
                <div>
                  {row?.todayActiveStatus === 0 ? (
                    <img
                      className="userstatus-onoff"
                      src={warningimg}
                      alt="Warning"
                    />
                  ) : (
                    <img
                      src={useractive}
                      alt="User Active"
                      className="userstatus-onoff"
                    />
                  )}
                </div>
              </div>
            )}
            <div>
              <h5
                style={{
                  textTransform: "capitalize",
                  margin: 0,
                  fontSize: "small",
                  fontWeight: "900",
                  color: "#3d78e3",
                  marginLeft: "0px",
                }}
              >
                {row?.fullName}
              </h5>
              <span className="profile-lastseen">{row?.appVersion}</span>
            </div>
          </div>
        </div>
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">Team</span>,
      selector: (row) => row.team,
      cell: (row) => (
        <div className="mt-2 mb-2">
          {row?.team && row.team.length > 0 ? (
            row.team.map((callTeam, index) => (
              <React.Fragment key={index}>
                <span
                  style={{
                    textTransform: "capitalize",
                    textAlign: "left",
                    float: "left",
                  }}
                >
                  {callTeam}
                </span>
                <br />
                {index !== row.team.length - 1 && <br />}
              </React.Fragment>
            ))
          ) : (
            <span>-</span>
          )}
        </div>
      ),
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideUnique"
        >
          Unique Call{" "}
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenUnique}
              autohide={false}
              target="DisabledAutoHideUnique"
              toggle={toggleUnique}
            >
              Shows unique numbers contacted, tracking the exact number of
              clients reached by your employees.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) => (row.uniqueClient === "-" ? 0 : row.uniqueClient),
      cell: (row) => (
        <div style={{ color: "#333", fontWeight: "600" }}>
          {row.uniqueClient}
        </div>
      ),
      sortable: true,
      width: "120px",
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideDuration"
        >
          Total Duration
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenDuration}
              autohide={false}
              target="DisabledAutoHideDuration"
              toggle={toggleDuration}
            >
              Total Call Duration shows the total time spent on all incoming and
              outgoing calls
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) => row.totalDuration,
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideAverage"
        >
          Avg Call Duration
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenAverage}
              autohide={false}
              target="DisabledAutoHideAverage"
              toggle={toggleAverage}
            >
              Average Call Duration represents the average time spent on each
              connected incoming and outgoing call.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) => row.avrerageCallDuration,
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideTotalCall"
        >
          Total Call
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenTotalCall}
              autohide={false}
              target="DisabledAutoHideTotalCall"
              toggle={toggleTotalCall}
            >
              Total Call represents the total number of incoming and outgoing
              calls made.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) => (row.totalCall === "-" ? 0 : row.totalCall),
      cell: (row) => <span className="sn-total-callbg">{row.totalCall}</span>,
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideConnected"
        >
          Connected Call
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenConnected}
              autohide={false}
              target="DisabledAutoHideConnected"
              toggle={toggleConnected}
            >
              Shows unique numbers contacted, tracking the exact number of
              clients reached by your employees
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) => (row.connectedCalls === "-" ? 0 : row.connectedCalls),
      cell: (row) => (
        <span className="sn-total-total-conected">{row.connectedCalls}</span>
      ),
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideIncoming"
        >
          Incoming Call
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenIncoming}
              autohide={false}
              target="DisabledAutoHideIncoming"
              toggle={toggleIncoming}
            >
              Displays the total number of calls received by your team during
              the selected time period.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) =>
        row.totallIncomingCall === "-" ? 0 : row.totallIncomingCall,
      cell: (row) => (
        <span className="sn-total-incoming-callbg">
          {row.totallIncomingCall}
        </span>
      ),
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideOutgoing"
        >
          Outgoing Call
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenOutgoing}
              autohide={false}
              target="DisabledAutoHideOutgoing"
              toggle={toggleOutgoing}
            >
              Shows the total number of calls made by your team within the
              selected time period.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) =>
        row.totallOutgoingCall === "-" ? 0 : row.totallOutgoingCall,
      cell: (row) => (
        <span className="sn-total-outgoing-callbg">
          {row.totallOutgoingCall}
        </span>
      ),
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideMissed"
        >
          Missed Call
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenMissed}
              autohide={false}
              target="DisabledAutoHideMissed"
              toggle={toggleMissed}
            >
              Represents the number of incoming calls that were not answered by
              your team during the specified period.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) =>
        row.totallMissedCall === "-" ? 0 : row.totallMissedCall,
      cell: (row) => (
        <span className="sn-total-missed-callbg">{row.totallMissedCall}</span>
      ),
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideAvg"
        >
          Not Attempted Call
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenAvg}
              autohide={false}
              target="DisabledAutoHideAvg"
              toggle={toggleAvg}
            >
              This shows missed calls you haven’t tried to call back yet. They
              will stay as 'Not Attempted' until you make a call.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) =>
        row.totalNeverAttended === "-" ? 0 : row.totalNeverAttended,
      cell: (row) => (
        <span className="sn-total-notattemped-callbg">
          {row?.totalNeverAttended}
        </span>
      ),
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideRejected"
        >
          Rejected Call
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenRejected}
              autohide={false}
              target="DisabledAutoHideRejected"
              toggle={toggleRejected}
            >
              Indicates the number of incoming calls that were actively declined
              by your team.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) =>
        row.totalRejectedCall === "-" ? 0 : row.totalRejectedCall,
      cell: (row) => (
        <span className="sn-total-rejected-callbg">
          {row.totalRejectedCall}
        </span>
      ),
      sortable: true,
    },

    {
      name: (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="DisabledAutoHideNotClient"
        >
          Not Picked Up By Client
          <span className="ri-information-line sn-dashboard-tble-toll"></span>
          <div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenNotClient}
              autohide={false}
              target="DisabledAutoHideNotClient"
              toggle={toggleNotClient}
            >
              Not just outgoing calls. Shows dialed but unconnected calls.
              Numbers stay until you connect, ensuring no missed contacts.
            </Tooltip>
          </div>
        </span>
      ),
      selector: (row) =>
        row.totalNotPickedUpByClient === "-" ? 0 : row.totalNotPickedUpByClient,
      cell: (row) => (
        <span className="sn-total-not-picked-upby-clintng">
          {row.totalNotPickedUpByClient}
        </span>
      ),
      sortable: true,
    },
  ];

  const formattedDates = useMemo(
    () => ({
      startDate: selectedDates[0].format("YYYY-MM-DD"),
      endDate: selectedDates[1].format("YYYY-MM-DD"),
    }),
    [selectedDates]
  );

  // expire plan show in modal
  useEffect(() => {
    const calculateRemainingDays = () => {
      if (
        profileData?.subscriptionEndDate &&
        profileData?.subscriptionStartDate
      ) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );
        const remainingDays = Math.ceil(
          (endDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil(
            (endDate - newCurrentDate) / (1000 * 60 * 60 * 24)
          );
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);


  useEffect(() => {
    setTeamCount(teamIndu.length);
    if (teamIndu.length === teamCount) {
      if (EmployeeDefault.length > 1 || permissionType.length === 1) {
        const defaultId = EmployeeDefault.map((option) => option?.id);
        fetchAllData(currentPage, perPage, defaultId, "");
      }
    }
    if (Object.keys(profileData)?.length > 0) {
      fetchAllData(currentPage, perPage, "", "");
    }
  }, [currentPage, perPage]);



  useEffect(() => {
    let updatedIds;
    if (IsShowEffectRun && EmployeeDefault?.length > 0) {
      if (teamIndu.length === 0) {
        // console.log("EmployeeDefaultWithoutTeam", EmployeeDefaultWithoutTeam);
        updatedIds = EmployeeDefaultWithoutTeam?.map((userid) => userid?.id);
      } else {
        // console.log("EmployeeDefault", EmployeeDefault);
        updatedIds = EmployeeDefault?.map((userid) => userid?.id);
      }

      if (updatedIds.length > 0) {
        fetchAllData(currentPage, 10, updatedIds, "");
      }
      setIsShowEffectRun(false);
    }
  }, [EmployeeDefault]);

  // this use Effect is only run in the condition where the (loginType === "employee") && (permissionType.length === 0)
  useEffect(() => {
    if (BooleanWithSingleEmployeeLogin) {
      fetchAllData(currentPage, 10, profileData._id, "");
    }
  }, [profileData])


  useEffect(() => {
    let updatedIds;
    if (!isFirstEffectRun && EmployeeDefault?.length > 0) {
      if (teamIndu.length === 0) {
        // console.log("EmployeeDefaultWithoutTeam", EmployeeDefaultWithoutTeam);
        updatedIds = EmployeeDefaultWithoutTeam?.map((userid) => userid?.id);
      } else {
        // console.log("EmployeeDefault", EmployeeDefault);
        updatedIds = EmployeeDefault?.map((userid) => userid?.id);
      }
      if (updatedIds.length > 0) {
        fetchAllData(currentPage, 10, updatedIds, "");
      }
      setIsFirstEffectRun(true);
    }
  }, [EmployeeDefault]);


  const onShowData = () => {
    if (
      selectedEmployeeOptions.length > 0 ||
      (permissionType.length === 0 && storedValue === "employee")
    ) {
      const updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
      fetchAllData(currentPage, perPage, "", updatedIds);
      setCurrentPage(1);
      setPerPage(10);
      setIsShowEffectRun(true); // Mark as executed
    }
  };


  // fetch all data
  const fetchAllData = useCallback(
    async (page = 1, size = 100, defaultId = [], updatedIds = []) => {
      const employeeProfileDataLength = profileData ? Object?.keys(profileData)?.length : 0;

      setLoading(true);
      try {
        const startDate = formattedDates?.startDate;
        const endDate = formattedDates?.endDate;
        let data = {
          startDate,
          endDate,
          employeeIds: updatedIds?.length > 0 ? updatedIds : defaultId || [],
        };
        const empLength =
          data?.employeeIds.length || employeeProfileDataLength;
        // Combine all API calls in a Promise.all block
        const [
          graphResponse,
          chartsResponse,
          pieChartResponse,
          employeeResponse,
        ] = await Promise.all([
          api.create(`${EMPLOYEE_CALL_HOURS_GRAPH}`, data),
          api.create(`${DASHBOARD_GRAPH_DATA}`, data),
          api.create(`${DASHBOARD_PIE_CHART}`, data),
          api.create(`${EMPLOYEE_DATATABLE}?pg=1&lm=${empLength}`, data),
        ]);

        // Handle responses for each API call
        if (graphResponse?.success) {
          setDatePicker(formattedDates);
          setGraphData(graphResponse.data);
        }

        if (chartsResponse?.success) {
          setDatePicker(formattedDates);
          setGraphCallData(chartsResponse.data);
        }

        if (pieChartResponse?.success) {
          const result = pieChartResponse?.data?.data;
          setPieChartSummaryData(result);
          setPieChartData(pieChartResponse?.data);
        }

        if (employeeResponse?.success) {
          const apiResponse = employeeResponse.data;
          const tableData = (apiResponse || []).map((data, index) => {
            const fname = data?.fullName;
            const firstLetter = fname.charAt(0).toUpperCase();
            const timestamp = data?.lastCallSync;
            const relativeTime = getRelativeTime(timestamp);
            return {
              serialNumber: index + 1,
              img: data?.imageUrl,
              firstLetter: fname ? firstLetter : "",
              fullName: data?.fullName,
              timestamps: timestamp,
              appVersion: relativeTime,
              todayActiveStatus: data?.todayActiveStatus,
              team: data?.team,
              totalCall: data.totalCall || "-",
              totalDuration: data.totalDuration
                ? new Date(data.totalDuration * 1000)
                  .toISOString()
                  .slice(11, 19)
                : "-",
              totallIncomingCall: data.totalIncomingCall || "-",
              totallIncomingConnectedCall:
                data.totalIncomingConnectedCall || "-",
              totallOutgoingCall: data.totalOutgoingCall || "-",
              totallOutgoingConnectedCall:
                data.totalOutgoingConnectedCall || "",
              totallMissedCall: data.totalMissedCall || "-",
              totalRejectedCall: data.totalRejectedCall || "-",
              totalNotPickedUpByClient: data.totalNotPickedUpByClient || "-",
              totalNeverAttended: data.neverAttended || "-",
              avrerageCallDuration:
                formatDuration(data.avrerageCallDuration) || "-",
              uniqueClient: data.uniqueClient || "-",
              connectedCalls: data.connectedCalls || "-",
              totallIncomingCallDuration: data.totalIncomingCallDuration
                ? new Date(data.totalIncomingCallDuration * 1000)
                  .toISOString()
                  .slice(11, 19)
                : "-",
              totallOutgoingCallDuration: data.totalOutgoingCallDuration
                ? new Date(data.totalOutgoingCallDuration * 1000)
                  .toISOString()
                  .slice(11, 19)
                : "",
            };
          });
          setEmployeeTableData(tableData);
          // setTotalRows(data?.employeeIds.length || 0);
        }
      } catch (error) {
        if (!remainingDays === 0) {
          HandleErrorMessage(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [formattedDates, currentPage, perPage]
  );


  // resete select field in clear
  const handleClickClear = () => {
    setSelectedEmployeeOptions([]);
    setTeamIndu([]);
  };

  const handleClose = () => {
    setIsExpiredModalOpen(false);
    navigate("/planPackage");
  };


  const exactHeaders = [
    {
      label: "Agent Name",
      key: "agentName",
    },
    { label: "Teams", key: "team" },
    {
      label: "Unique Client",
      key: "uniqueClient",
    },
    {
      label: "Total Duration",
      key: "totalDuration",
    },
    {
      label: "Average Call Duration",
      key: "avrerageCallDuration",
    },
    {
      label: "Total Call",
      key: "totalCall",
    },
    {
      label: "Connected Calls",
      key: "totalconnectedCalls",
    },
    {
      label: "Incoming Call",
      key: "totallIncomingCall",
    },
    {
      label: "Outgoing Call",
      key: "totallOutgoingCall",
    },
    {
      label: "Missed Call",
      key: "totallMissedCall",
    },
    {
      label: "Not Attempted Call",
      key: "totalNeverAttended",

    },
    {
      label: "Rejected Call",
      key: "totalRejectedCall",
    },
    {
      label: "Not Picked Up By Client",
      key: "totalNotPickedUpByClient",

    },
  ];


  const exportToExcels = async () => {
    const reportTitle = `Agent Performance Report  (${formattedDates?.startDate} To ${formattedDates?.endDate})`;
    await exportToExcel(employeeData, exactHeaders, reportTitle, "AgentPerformance","Agent Performance")
  }


  const exportToPDF = () => {
    const doc = new jsPDF();

    // Add a title to the PDF and center it
    const title = "Agent Performance Data";
    const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width
    const textWidth = doc.getTextWidth(title); // Get the width of the text
    const textX = (pageWidth - textWidth) / 2; // Calculate X position to center the text

    doc.text(title, textX, 10); // Use the calculated X position to center the text

    // Define static headers for the columns
    const headers = [
      "SN",
      "Agent Name",
      "Teams",
      "Unique Client",
      "Total Duration",
      "Average Call Duration",
      "Total Call",
      "Connected Calls",
      "Incoming Call",
      "Outgoing Call",
      "Missed Call",
      "Not Attempted Call",
      "Rejected Call",
      "Not Picked Up By Client",
    ];

    // Map table data based on the static header structure
    const data = employeeData.map((item) => [
      item.serialNumber,
      item.fullName,
      item.team.join(", "), // Assuming 'team' is an array
      item.uniqueClient,
      item.totalDuration,
      item.avrerageCallDuration,
      item.totalCall,
      item.connectedCalls,
      item.totallIncomingCall,
      item.totallOutgoingCall,
      item.totallMissedCall,
      item.totalNeverAttended,
      item.totalRejectedCall,
      item.totalNotPickedUpByClient,
    ]);


    doc.autoTable({
      head: [headers], // Use static headers here
      body: data, // Use mapped data
      startY: 20, // Adjust where the table starts
      styles: { fontSize: 8 },
      headStyles: { fillColor: [255, 71, 71] }, // Styling the header
      alternateRowStyles: { fillColor: [245, 245, 245] }, // Alternating row colors
    });

    // Save the PDF
    doc.save("Agent_Performance.pdf");
  };



  return (
    <React.Fragment>
      <ToastContainer />
      {remainingDays === 0 && isExpiredModalOpen && (
        <SubExpired
          onClose={handleClose}
          profileData={profileData?.selectedPlanDetails?.planName}
          remainingDays={remainingDays}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Col>
            <div className="h-100">
              <DashboardFillter
                setError={setError}
                error={error}
                EmployeeDefault={EmployeeDefault}
                setEmployeeDefault={setEmployeeDefault}
                handleClickClear={handleClickClear}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                onShowData={onShowData}
                setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                teamIndu={teamIndu}
                setTeamIndu={setTeamIndu}
                selectedEmployeeOptions={selectedEmployeeOptions}
                EmployeeDefaultWithoutTeam={EmployeeDefaultWithoutTeam}
                setEmployeeDefaultWithoutTeam={setEmployeeDefaultWithoutTeam}
                BooleanWithSingleEmployeeLogin={BooleanWithSingleEmployeeLogin}
                fetchAllData={fetchAllData}
                profileData={profileData}
                perPage={perPage}
                currentPage={currentPage}
              />

              {loading && (
                <div className="loader-overlay">
                  <Loader />
                </div>
              )}
              <div className={`content ${loading ? "blurred" : ""}`}>
                <Row>
                  <OverallCallPieChart
                    piechartsummaryData={piechartsummaryData}
                    piechartData={piechartData}
                    dataColors='["#80adfd","#67b173","#f17171","#eca8f7"]'
                  />

                  <Col className="col-md-9 col-sm-9">
                    <DashboardEmployeeSummary
                      piechartData={piechartData}
                      EmployeeDefault={EmployeeDefault}
                    />

                    {/* OverAllCall Volume */}
                    <OverallCallsVolume graphData={graphCallData} apiData={graphData} formattedDates={datePicker} selectedEmployeeOptions={selectedEmployeeOptions} />


                    {/* <ApexChart
                      formattedDates={datePicker}
                      graphData={graphCallData}
                      callGraphData={graphData}
                    /> */}

                    {/* <DemoApexChart
                      formattedDates={datePicker}
                      graphData={graphCallData}
                      callGraphData={graphData}
                      selectedEmployeeOptions={selectedEmployeeOptions} /> */}

                  </Col>
                </Row>
                <TopPerformanceEmployee formattedDates={formattedDates} selectedEmployeeOptions={selectedEmployeeOptions} employeeData={employeeData} teamIndu={teamIndu} />
                {/* employee perfomance */}
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="salenninja-dashboard-agent-tblemani"
                  >
                    <div>
                      <Card className="h-100">
                        <CardHeader className="align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Agent Performance
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              id="DisabledAutoHideAgent"
                              className="ri-information-line sn-dashboard-tble-toll"
                            ></span>
                            <div>
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpenAgent}
                                autohide={false}
                                target="DisabledAutoHideAgent"
                                toggle={toggleAgent}
                                style={{ maxWidth: "300px" }}
                              >
                                View each agent's call activity, including total
                                duration, unique clients, and breakdowns of
                                incoming, outgoing, missed, and rejected calls.
                              </Tooltip>
                            </div>
                          </h4>

                          <h3 className="tble-dashboard-export-div">
                            <div className="sales-ninja-tble-export-btn">
                              {" "} <Button
                                onClick={exportToExcels}
                                className="custom-button tble-export1"
                              >
                                <i className="ri-file-excel-line"></i>
                              </Button>{" "}
                              &nbsp;
                              <Button
                                onClick={exportToPDF}
                                className="custom-button ml-2 tble-export2"
                              >
                                <i className="ri-file-pdf-line"></i>
                              </Button>

                            </div>
                            <div
                              className="agent-user-tooltip-main-box---"
                              style={{
                                marginLeft: "10px",
                                display: "flex",
                                width: "50px",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                id="DisabledAutoLogiTech"
                              >
                                <span className="sales-ninja-tooltip-agent">
                                  {" "}
                                  <img
                                    className="agent-tooltip-tble-i"
                                    src={warningimg}
                                    alt="Warning"
                                  />
                                </span>
                                <div>
                                  <Tooltip
                                    placement="top"
                                    isOpen={tooltipOpenLogiTech}
                                    autohide={false}
                                    target="DisabledAutoLogiTech"
                                    toggle={toggleLogiTech}
                                  >
                                    Inactive since due to no calls attempted or
                                    app not opened. Please ask the employee to
                                    open the app to sync calls and recordings.
                                  </Tooltip>
                                </div>
                              </span>
                              <span className="activeeeeeeeeeeeee">
                                <div
                                  style={{
                                    fontSize: "15px",
                                    marginLeft: "13px",
                                    cursor: "pointer",
                                  }}
                                />
                                <img
                                  src={useractive}
                                  alt="User Active"
                                  className="agent-tooltip-tble-i"
                                  id="DisabledAutoHideActiveee"
                                />
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpenActiveee}
                                  autohide={false}
                                  target="DisabledAutoHideActiveee"
                                  toggle={toggleActiveee}
                                >
                                  Employee is currently active and making calls.
                                </Tooltip>
                              </span>
                            </div>
                          </h3>
                        </CardHeader>

                        {employeeData?.length > 0 ? (
                          <CardBody className="agent">
                            <>
                              <DashboardDataTable
                                currentPage={currentPage}
                                column={columns}
                                fixedHeaderScrollHeights="480px"
                                tableData={employeeData}
                              />
                            </>
                          </CardBody>
                        ) : (
                          <>
                            <div className="sn-dashboard-blur-img">
                              <img
                                src={tableimg}
                                alt=""
                                style={{ filter: "blur(2px)", width: "100%" }}
                              />
                            </div>
                          </>
                        )}
                      </Card>
                    </div>
                  </Col>
                </Row>
              </div>
              <MostActiveHourByCalls graphData={graphData} />
            </div>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardCallCrm;
