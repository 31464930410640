import React, { useEffect, useRef, useState } from 'react'
import { Col, FormFeedback, Label, Modal, ModalBody, ModalHeader, Row, Form, Input, ModalFooter, Button, Spinner } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { ADMIN_UPDATE_ORGANIZATION, POST_CITIES, POST_GET_INDUSTRY_TYPE, POST_PINCODE } from '../../helpers/url_helper';
import { APIClient } from '../../helpers/api_helper';
import Toast from '../../Components/Common/Toast';
import { HandleErrorMessage } from '../../Components/Common/HandleErrorMessage';
import { useMasterApi } from '../../Components/Hooks/UserHooks';
const api = new APIClient();


const UpdateAdmin = ({ orgDetail, modal_grid, setmodal_grid, EmployeeDataApi }) => {
    const { masterApiData } = useMasterApi()
    const isEmailDisabled = true;
    const phoneNumberRules = /^[1-9]\d{9}$/;
    const { orgid, orgname, email, city, country, address, pincode, industryType, mobileNumber, state } = orgDetail
    const [loading, setLoading] = useState(false);
    const [industryTypeData, setIndustryTypeData] = useState()
    const [getCity, setGetCity] = useState([])
    const [postalCode, setPostalCode] = useState([])

    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        enableReinitialize: true,
        initialValues: {
            organizationName: orgname || "",
            email: email || "",
            mobileNumber: mobileNumber || "",
            city: city || "",
            state: state || "",
            country: country || "",
            address: address || "",
            industryType: industryType || "",
            pincode: pincode || ""
        },
        validationSchema: Yup.object({
            organizationName: Yup.string().required("Please Enter Employee Name"),
            mobileNumber: Yup.string().matches(phoneNumberRules, { message: "Please Enter 10 Digit Mobile Number" }).required("Please Enter Your Mobile Number"),
        }),
        onSubmit: async (values) => {
            setLoading(true);
          

            try {
                let data = {
                    organizationId: orgid
                };
                if (values.email !== email) {
                    data.email = values.email;
                }
                if (values.organizationName !== orgname) {
                    data.organizationName = values.organizationName;
                }
                if (values.mobileNumber !== mobileNumber) {
                    data.mobileNumber = values.mobileNumber;
                }
                if (values.city !== city) {
                    data.city = values.city;
                }
                if (values.state !== state) {
                    data.state = values.state;
                }
                if (values.country !== country) {
                    data.country = values.country;
                }
                if (values.address !== address) {
                    data.address = values.address;
                }
                if (values.industryType !== industryType) {
                    data.industryType = values.industryType;
                }
                if (values.pincode !== pincode) {
                    data.pincode = values.pincode;
                }

                const response = await api.patch(`${ADMIN_UPDATE_ORGANIZATION}`, data);
                if (response?.success === true) {
                    Toast(response?.message, "success");
                    setTimeout(() => {
                        setmodal_grid(false);
                        EmployeeDataApi()
                    }, 2000);
                }
            } catch (error) {
                HandleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        },
    });

    const toggle = () => {
        // if (modal_grid) {
        //     formik.resetForm();
        // }
        setmodal_grid(!modal_grid);
    };

    const handleStateClick = (value) => {
        // const [code, name] = value.split(',');
        getCities(value)
    }

    const handleCityClick = (value) => {
        getPostalCode(value)
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    const fetchUserData = async () => {
        try {
            const res = await api.get(`${POST_GET_INDUSTRY_TYPE}`)
            if (res.success === true) {
                setIndustryTypeData(res?.data)
            }
        }
        catch (error) {
            console.log("error", error)
        }

    }



    const getCities = async (state) => {
        try {
            const data = { statName: state };
            const response = await api.create(`${POST_CITIES}`, data)
            if (response.success === true) {
                setGetCity(response.data)
            }
        } catch (error) {
            console.log(error, "error");
        }
    }


    const getPostalCode = async (city) => {
        try {
            const data = { cityName: city }
            const response = await api.create(`${POST_PINCODE}`, data)
            if (response.success === true) {
                setPostalCode(response.data)
            }

        } catch (error) {
            console.log(error, "error");
        }
    }


    return (
        <Row>
            <ToastContainer />
            <Modal
                isOpen={modal_grid}
                toggle={() => {
                    toggle();
                }}>
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={toggle}>Update Employee</ModalHeader>
                <Form
                    onSubmit={handleSubmit}
                    className="needs-validation">
                    <ModalBody>
                        <div className="mb-3">
                            <Row>
                                <Col sm={6}>
                                    <Label htmlFor="organizationName" className="form-label">Name</Label>
                                    <Input
                                        data-tooltip-id="my-tooltip-1"
                                        name="organizationName"
                                        autoComplete="new-password"
                                        type="text"
                                        placeholder="Enter Organization Name"
                                        {...getFieldProps('organizationName', { autoComplete: 'off' })}
                                        className="form-control"
                                        invalid={touched.organizationName && errors.organizationName}
                                    />
                                    {touched.organizationName && errors.organizationName && (
                                        <FormFeedback type="invalid"></FormFeedback>
                                    )}
                                </Col>
                                <Col sm={6}>
                                    <Label htmlFor="useremail" className="form-label">Email Address</Label>
                                    <Input
                                        name="email"
                                        autoComplete="new-password"
                                        data-tooltip-id="my-tooltip-2"
                                        type="email"
                                        placeholder="Enter email address"
                                        {...getFieldProps('email', { autoComplete: 'off' })}
                                        className="form-control"
                                        invalid={touched.email && errors.email}
                                        disabled={isEmailDisabled}
                                    />
                                    {touched.email && errors.email && (
                                        <FormFeedback type="invalid"></FormFeedback>
                                    )}

                                </Col>
                            </Row>
                        </div>

                        <div className="mb-3">
                            <Row>
                                <Col sm={6}>
                                    <Label htmlFor="ContactNumber" className="form-label">Contact Number</Label>
                                    <Input
                                        name="mobileNumber"
                                        autoComplete="new-password"
                                        data-tooltip-id="my-tooltip-3"
                                        type="tel"
                                        placeholder="Enter Contact No."
                                        {...getFieldProps('mobileNumber')}
                                        className="form-control"
                                        invalid={touched.mobileNumber && errors.mobileNumber}
                                    />
                                    {(touched.mobileNumber && errors.mobileNumber) && (
                                        <FormFeedback type="invalid"></FormFeedback>
                                    )}
                                </Col>
                                <Col sm={6}>
                                    <Label htmlFor="address" className="form-label">Address</Label>
                                    <Input
                                        data-tooltip-id="my-tooltip-7"
                                        name="address"
                                        autoComplete="new-password"
                                        type="text"
                                        placeholder="Enter Address"
                                        {...getFieldProps('address')}
                                        className="form-control"
                                        invalid={touched.address && errors.address}
                                    />
                                    {(touched.address && errors.address) && <FormFeedback type="invalid"></FormFeedback>}

                                </Col>
                            </Row>
                        </div>

                        <div className="mb-3">
                            <div className="s_col_no_resize s_col_no_bgcolor">
                                <Row>
                                    <Col sm={6}>
                                        <Label htmlFor="country" className="form-label">Select Country</Label>
                                        <select data-tooltip-id="my-tooltip-5"
                                            className={`form-select ${touched.countrycode && errors.countrycode ? 'is-invalid' : ''}`}
                                            name="countrycode" {...getFieldProps('countrycode')}
                                            value={getFieldProps('countrycode').value || `${industryTypeData?.countryList[0]?.code},${industryTypeData?.countryList[0]?.name}`}>
                                            <option value="" disabled>Select Country</option>
                                            <option value={`${industryTypeData?.countryList[0]?.code},${industryTypeData?.countryList[0]?.name}`}>{industryTypeData?.countryList[0]?.name}</option>
                                            {/* {industryTypeData?.countryList.map((ele, key) => {
                                                                        return (
                                                                            <>
                                                                                <option key={key} value={`${ele?.code},${ele?.name}`}>{ele?.name}</option>
                                                                            </>
                                                                        )
                                                                    })} */}
                                        </select>
                                        {(touched.countrycode && errors.countrycode) && <FormFeedback type="invalid"></FormFeedback>}

                                    </Col>

                                    <Col sm={6}>
                                        <Label htmlFor="state" className="form-label">Select State</Label>
                                        <select
                                            data-tooltip-id="my-tooltip-6"
                                            value={state}
                                            className={`form-select ${touched.state && errors.state ? 'is-invalid' : ''}`}
                                            name="state"
                                            {...getFieldProps('state')}
                                            onClick={(event) => handleStateClick(event.target.value)}>
                                            <option value="" disabled>Select State</option>
                                            {masterApiData?.stateCode?.map((ele, key) => {
                                                return (
                                                    <>
                                                        <option key={key} value={`${ele?.name}`}>{ele?.name}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                        {(touched.state && errors.state) && <FormFeedback type="invalid"></FormFeedback>}

                                    </Col>

                                </Row>
                            </div>
                        </div>

                        <div className="mb-3">
                            <Row>
                                <Col sm={6}>
                                    <Label htmlFor="City" className="form-label">City</Label>
                                    <select data-tooltip-id="my-tooltip-8"
                                        value={city}
                                        className={`form-select ${touched.city && errors.city ? 'is-invalid' : ''}`}
                                        name="city"
                                        type="text"
                                        {...getFieldProps('city')}
                                        onClick={(event) => handleCityClick(event.target.value)}
                                    >
                                        <option value="" disabled>Select City</option>
                                        {getCity?.map((ele, key) => {
                                            return (
                                                <>
                                                    <option key={key} value={`${ele}`}>{ele}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                    {(touched.city && errors.city) && <FormFeedback type="invalid"></FormFeedback>}

                                </Col>
                                <Col sm={6}>
                                    <Label htmlFor="postalncode" className="form-label">Postal-Code</Label>
                                    <select
                                        data-tooltip-id="my-tooltip-4"
                                        value={pincode}
                                        className={`form-select ${touched.pincode && errors.pincode ? 'is-invalid' : ''}`}
                                        name="pincode"
                                        {...getFieldProps('pincode')}>
                                        <option value="" disabled>Select Postal Code</option>
                                        {postalCode?.map((ele, key) => {
                                            return (
                                                <>
                                                    <option key={key} value={`${ele}`}>{ele}</option>
                                                </>
                                            )
                                        })}
                                    </select>

                                    {(touched.pincode && errors.pincode) && <FormFeedback type="invalid"></FormFeedback>}

                                </Col>
                            </Row>
                        </div>

                        <div className="mb-3">
                            <Row>
                                <Col sm={6}>
                                    <Label htmlFor="stateIndustry" className="form-label">Select Industry</Label>
                                    <select data-tooltip-id="my-tooltip-9" name="stateIndustry" id="industryType" {...getFieldProps('industryType')} className={`form-select ${touched.industryType && errors.industryType ? 'is-invalid' : ''}`} >
                                        <option value="" disabled>Select Industry</option>
                                        {(industryTypeData?.industryType || [])?.map((ele, index) => {
                                            return (
                                                <>
                                                    <option key={ele} value={ele}>{ele}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                    {(touched.industryType && errors.industryType) && <FormFeedback type="invalid"></FormFeedback>}

                                </Col>

                            </Row>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <Button d="add-btn" disabled={loading ? true : false} className="btn btn-danger w-100" type="submit">
                                {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                Update
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </Row>
    )
}

export default UpdateAdmin