import * as Yup from 'yup';
import { exportReport } from '../../helpers/api_helper';
import { formatDate } from './FormDateTime';
import { Toast } from 'reactstrap';

export const ValidationSchema = Yup.object().shape({
    teamName: Yup.string()
        .matches(/^[A-Za-z\s]*$/, 'Only letters and spaces are allowed')
        .required('Please Enter Your Team Name')
        .max(30, 'Name must not be more than 16 characters'),
});

export const filterItemsBySearchText = (items, searchText) => {
    return items.filter(
      (item) =>
        item.fullName && item.fullName.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  export const handleDownload = (data,filename) => {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
  
    const a = document.createElement("a");
    a.href = url;
    a.download = filename; 
    document.body.appendChild(a);
    a.click();
    a.remove();
  
    window.URL.revokeObjectURL(url);
  };
  
  //common function for excel and pdf exports
   export const handleExport = async ({ type, apiUrl, startDate, endDate, employeeIds, fileName , callType, clientPhoneNumberOrName }) => {
    try {
      // dynamic data object 
      const data = {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        reportType: type,
        callType:callType,
        employeeIds, 
        clientPhoneNumberOrName
      };
      // dynamic api call 
      const response = await exportReport(apiUrl, data);  
      // Trigger the download with the dynamic file name 
      handleDownload(response, fileName);  // Use the fileName passed in the parameters
      
    } catch (error) {
      console.log(`Error exporting ${type}:`, error);
      Toast(error.response, "error");
    }
  };
  
  

  